import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Authorization from '../../components/Authorization';

import LeadList from './LeadList';
import LeadDetails from './LeadDetails';
import SessionContextProvider from './context';
import InternalQuotation from './InternalQuotation';
import InternalComparator from './InternalComparator';

const Leads = () => {
  return (
    <SessionContextProvider>
      <Routes>
        <Route element={<Authorization access="leads" white />}>
          <Route path="/" element={<LeadList />} />
          <Route path="/:uuid" element={<LeadDetails />} />
          <Route path="/:uuid/quotation" element={<InternalQuotation />} />
          <Route path="/:uuid/comparator" element={<InternalComparator />} />
        </Route>
      </Routes>
    </SessionContextProvider>
  );
};

export default React.memo(Leads);
