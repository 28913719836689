import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Auth, Storage } from 'aws-amplify';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { postStory } from './request';
import { validation } from './validations';
import { handleTypes } from '../constants';
import { branch } from '../../../constants';
import CardContainer from '../CardContainer';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';

import styles from './CreateCategory.module.scss';

const CreateCategory = ({ style, onRefresh }) => {
  const [miniatureKey, setMiniatureKey] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [arrayKey, setArrayKey] = useState([]);
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState(null);

  const form = useFormik({
    initialValues: {
      category: '',
    },
    validate: validation,
    onSubmit: values => handleSubmit(values),
  });

  const handleSubmit = values => {
    if (!miniatureKey) {
      alert('Se requiere una miniatura para crear nueva colección');
      return;
    }
    setMiniatureKey(null);
    setActiveInput(null);
    setKey(null);
    handleRequest(values, { media: arrayKey, miniatureKey });
  };

  const handleRequest = async (values, payload) => {
    try {
      const res = await postStory(values, payload);
      if (!res.notifications.requestMessage) {
        form.resetForm();
        setProgress(0);
        onRefresh();
      }
    } catch (error) {}
  };

  const handleUpload = async event => {
    setLoading(true);
    const file = event.target.files[0];
    const genericName = (
      event.target.files[0].name + new Date().valueOf()
    ).replace(' ', '-');
    const mimetype = file.type;
    let ext = mimetype.split('/')[1];
    if (ext === 'quicktime') {
      ext = 'mov';
    }
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async () => {
        const result = await Storage.put(
          `${branch}/${genericName}.${ext}`,
          file,
          { progressCallback },
        );
        if (event.target.name === 'miniature') {
          setMiniatureKey(result.key);
          return null;
        }
        setKey(result.key);
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValidations = async event => {
    setActiveInput(event.target.name);
    const validate = await form.validateForm();
    if (Object.keys(validate).length === 0) {
      handleUpload(event);
    } else {
      alert('Se requere un título para subir un nuevo recurso');
    }
  };

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleCancelEdit = () => {
    setMiniatureKey(null);
    setActiveInput(null);
    setLoading(false);
    setProgress(0);
    setArrayKey([]);
    setKey(null);
    form.resetForm();
    onRefresh(true);
  };

  useEffect(() => {
    if (miniatureKey) {
      setLoading(false);
      setProgress(0);
    }
    if (key) {
      setArrayKey([...arrayKey, { key }]);
      setLoading(false);
      setProgress(0);
      setKey(null);
    }
  }, [key, miniatureKey]);

  return (
    <CardContainer style={style} title="Agregar nueva categoría.">
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="category"
          name="category"
          label="Título"
          value={form.values.category}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          error={form.touched.category && form.errors.category}
          helperText={form.touched.category && form.errors.category}
        />
        {miniatureKey && (
          <Typography
            variant="subtitle1"
            color="success.main"
            component="div"
            className={styles.CheckText}
          >
            Miniatura lista.
            <CheckCircleIcon fontSize="24" color="#2e7d32" fontWeight="2" />
          </Typography>
        )}
        {!miniatureKey && (
          <>
            {progress > 0 && activeInput === 'miniature' ? (
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ marginTop: '25px', marginBottom: '10px' }}
              />
            ) : (
              <LoadingButton
                fullWidth
                variant="outlined"
                component="label"
                sx={{ marginTop: '15px' }}
                loading={loading}
              >
                <input
                  type="file"
                  name="miniature"
                  hidden
                  onChange={e => handleValidations(e)}
                  accept={handleTypes('img')}
                />
                Seleccionar miniatura
              </LoadingButton>
            )}
          </>
        )}
        {progress > 0 && activeInput === 'file' ? (
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ marginTop: '25px', marginBottom: '10px' }}
          />
        ) : (
          <LoadingButton
            fullWidth
            variant="outlined"
            component="label"
            sx={{ marginTop: '15px' }}
            loading={loading}
          >
            <input
              type="file"
              name="file"
              hidden
              onChange={e => handleValidations(e)}
              accept={handleTypes('video')}
            />
            Agregar video
          </LoadingButton>
        )}
        {arrayKey.length > 0 &&
          arrayKey.map(element => {
            return (
              <Typography
                key={element.key}
                variant="subtitle1"
                color="success.main"
                component="div"
                className={styles.CheckText}
              >
                Listo
                <CheckCircleIcon fontSize="24" color="#2e7d32" fontWeight="2" />
              </Typography>
            );
          })}
        <div className={styles.ActionButtons}>
          <LoadingButton variant="contained" color="primary" type="submit">
            Guardar cambios
          </LoadingButton>
          <Button variant="outlined" color="primary" onClick={handleCancelEdit}>
            Cancelar
          </Button>
        </div>
      </form>
    </CardContainer>
  );
};

CreateCategory.propTypes = {
  style: PropTypes.any,
  onRefresh: PropTypes.func,
};

export default React.memo(CreateCategory);
