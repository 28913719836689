import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Select from '../../../components/Inputs/Select';
import { Context } from '../../../context/SessionContext';

import { contactResult, contactType } from './constants';
import { postLeadActivationTracking } from './request';
import { validations } from './validations';

import styles from './FormTracking.module.scss';
import classnames from 'classnames';

const FormTracking = ({ uuid, onOpen, onRefreshList, style }) => {
  const [loading, setLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);
  const [contactTypeValue, setContactTypeValue] = useState(null);
  const { userData } = useContext(Context);

  const form = useFormik({
    initialValues: {
      createdDate: Date.now(),
      userId: userData.username,
      contactType: 1,
      contactResult: '',
      isInterested: false,
      isSuccessfulContact: false,
      comments: '',
    },
    validate: validations,
    onSubmit: values => handleFormSubmit(values),
  });

  const handleFormSubmit = values => {
    setLoading(true);
    postLeadActivationTracking(values, uuid).then(() => {
      setLoading(false);
      onRefreshList();
      onOpen();
    });
  };

  const handleSetContact = value => {
    console.info('value', value);
    const contactPiv = contactResult.filter(
      element => element.type === parseInt(value, 10),
    );
    console.info('contactPiv', contactPiv);
    setContactOptions(contactPiv);
  };

  useEffect(() => {
    if (contactTypeValue) {
      handleSetContact(contactTypeValue);
    }
  }, [contactTypeValue]);

  useEffect(() => {
    setContactTypeValue(form.values.contactType);
  }, []);

  return (
    <div className={classnames(styles.FormTrakingContainer, style)}>
      <form onSubmit={form.handleSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo de contacto:</FormLabel>
          <RadioGroup
            row
            aria-label="Tipo de contacto:"
            value={form.values.contactType}
            onChange={e => {
              form.handleChange(e);
              setContactTypeValue(e.target.value);
            }}
            name="contactType"
          >
            {Object.entries(contactType).map(value => (
              <FormControlLabel
                control={<Radio />}
                key={value[0]}
                value={value[0]}
                label={value[1]}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Select
          id="contactResult-select"
          value={form.values.contactResult}
          name="contactResult"
          label="Resultado:"
          margin="normal"
          onChange={form.handleChange}
          error={form.touched.contactResult && form.errors.contactResult}
          helperText={form.touched.contactResult && form.errors.contactResult}
        >
          {Object.entries(contactOptions).map(value => (
            <MenuItem key={value[1].code} value={value[1].code}>
              {value[1].label}
            </MenuItem>
          ))}
        </Select>
        <FormControl component="fieldset">
          <FormLabel component="legend">Está interesado:</FormLabel>
          <RadioGroup
            row
            aria-label="Está interesado:"
            onChange={form.handleChange}
            name="isInterested"
            value={form.values.isInterested}
          >
            <FormControlLabel control={<Radio />} value="true" label={'Si'} />
            <FormControlLabel control={<Radio />} value="false" label={'No'} />
          </RadioGroup>
        </FormControl>
        <TextField
          margin="normal"
          id="comments"
          label="Comentarios"
          name="comments"
          value={form.values.comments}
          onChange={form.handleChange}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          Guardar
        </LoadingButton>
      </form>
    </div>
  );
};

FormTracking.propTypes = {
  onOpen: PropTypes.func,
  onRefreshList: PropTypes.func,
  style: PropTypes.any,
  uuid: PropTypes.string,
};

export default FormTracking;
