import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Autocomplete, LoadingButton } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Select from '../../../components/Inputs/Select';
import DatePicker from '../../../components/Inputs/DatePicker';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';

import ResetPasswordForm from './ResetPasswordForm';

import { validations } from './validations';
import { occupations, maritalStatus } from '../KinsurerDetails/constants';
import { getAddressByPostalCode, putKinsurerDetails } from './request';

import styles from './index.module.scss';

const UpdateKinsurer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState(
    state?.neighborhood ? [{ name: state?.neighborhood }] : [],
  );

  const form = useFormik({
    initialValues: {
      curp: state?.curp || '',
      clabe: state?.clabe || '',
      dateOfBirth: state?.dateOfBirth || '',
      occupation: state?.occupation || '',
      maritalStatus: state?.maritalStatus || '',
      postalCode: state?.postalCode || '',
      neighborhood: state?.neighborhood || '',
    },
    validate: validations,
    onSubmit: values => handleSubmit(values),
  });

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleOpenReset = () => {
    setOpenReset(!openReset);
  };

  const handleSubmit = values => {
    setLoading(true);
    putKinsurerDetails(values, uuid).then(() => {
      setLoading(false);
      handleOpenBackDrop();
    });
  };

  const handleLocation = async () => {
    const validate = await form.validateForm();
    if (!validate.postalCode && form.values.postalCode !== '') {
      getAddressByPostalCode(form.values.postalCode).then(res => {
        setNeighborhoods(res.data.neighborhoods);
      });
    }
  };

  const HandlePreventDefault = event => {
    if (event.key === 'Enter') {
      event.defaultMuiPrevented = true;
      event.preventDefault();
      form.handleBlur('neighborhood');
    }
  };

  const handleAddNewOption = event => {
    if (
      event.target.value !== undefined &&
      event.target.value !== 'undefined' &&
      event.target.value !== '' &&
      event.target.value !== null
    ) {
      setNeighborhoods([...neighborhoods, { name: event.target.value }]);
      form.setFieldValue('neighborhood', event.target.value);
    }
  };

  useEffect(() => {
    if (openBackDrop) {
      setTimeout(() => {
        handleOpenBackDrop();
      }, 3000);
    }
  }, [openBackDrop]);

  return (
    <div className={styles.UpdateKinsurerContainer}>
      <Typography variant="h4" color="primary">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowLeftIcon fontSize="28" color="#4575ba" fontWeight="2" />
        </IconButton>
        Actualizar información
      </Typography>
      <form className={styles.UpdateForm} onSubmit={form.handleSubmit}>
        <div className={styles.FormInRow}>
          <TextField
            name="curp"
            label="CURP"
            value={form.values.curp}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.curp && form.errors.curp}
            helperText={form.touched.curp && form.errors.curp}
            margin="normal"
            size="small"
            inputProps={{ maxLength: 18 }}
          />
          <InputMask
            mask="999 999 99999999999 9"
            maskChar=""
            name="clabe"
            label="Cuenta CLABE"
            value={form.values.clabe}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.clabe && form.errors.clabe}
            helperText={form.touched.clabe && form.errors.clabe}
            margin="normal"
            size="small"
          >
            {inputProps => <TextField {...inputProps} />}
          </InputMask>
        </div>
        <div className={styles.FormInRow}>
          <DatePicker
            name="dateOfBirth"
            label="Fecha de nacimiento"
            value={form.values.dateOfBirth}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={form.touched.dateOfBirth && Boolean(form.errors.dateOfBirth)}
            helperText={form.touched.dateOfBirth && form.errors.dateOfBirth}
            margin="normal"
            size="small"
            inputProps={{ maxLength: 18 }}
          />
          <Select
            name="occupation"
            label="Ocupación"
            onChange={form.handleChange}
            value={form.values.occupation}
            error={form.errors.occupation && Boolean(form.touched.occupation)}
            helperText={form.touched.occupation && form.errors.occupation}
            margin="normal"
            size="small"
            fullWidth
            inputProps={{ maxLength: 18 }}
          >
            {occupations.map(occupationItem => (
              <MenuItem key={occupationItem.id} value={occupationItem.id}>
                {occupationItem.description}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="maritalStatus"
            label="Estado Civil"
            onChange={form.handleChange}
            value={form.values.maritalStatus}
            error={form.errors.maritalStatus && Boolean(form.touched.maritalStatus)}
            helperText={form.touched.maritalStatus && form.errors.maritalStatus}
            margin="normal"
            size="small"
            fullWidth
            inputProps={{ maxLength: 30 }}
          >
            {maritalStatus.map(maritalStatusItem => (
              <MenuItem key={maritalStatusItem.id} value={maritalStatusItem.id}>
                {maritalStatusItem.description}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.FormInRow}>
          <InputMask
            mask="99999"
            maskChar=""
            label="Código postal"
            onChange={form.handleChange}
            onBlur={handleLocation}
            name="postalCode"
            value={form.values.postalCode}
            error={form.touched.postalCode && Boolean(form.errors.postalCode)}
            helperText={form.touched.postalCode && form.errors.postalCode}
            margin="normal"
            size="small"
          >
            {inputProps => <TextField {...inputProps} />}
          </InputMask>
          <Autocomplete
            freeSolo
            disablePortal
            name="neighborhood"
            options={neighborhoods}
            getOptionLabel={option => option.name}
            onChange={(e, value) => {
              form.setFieldValue('neighborhood', value?.name);
            }}
            onBlur={handleAddNewOption}
            onKeyDown={HandlePreventDefault}
            defaultValue={neighborhoods.length > 0 ? neighborhoods[0] : null}
            disabled={form.values.postalCode === ''}
            renderInput={params => (
              <TextField
                {...params}
                value={form.values.neighborhood}
                label="Colonia"
                error={
                  form.touched.neighborhood && Boolean(form.errors.neighborhood)
                }
                helperText={
                  form.touched.neighborhood && form.errors.neighborhood
                }
                margin="normal"
              />
            )}
            size="small"
            fullWidth
          />
        </div>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          Guardar
        </LoadingButton>
      </form>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        open={openBackDrop}
      >
        <div className={styles.BackdropContent}>
          <CheckCircleIcon fontSize="48" color="white" />
          <Typography variant="h4" component="p" gutterBottom>
            Información actualizada.
          </Typography>
        </div>
      </Backdrop>
      <div className={styles.OtherOptions}>
        <Typography variant="h6" color="primary">
          Otras opciones del Kinsurer
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleOpenReset}>
          Restablecer contraseña.
        </Button>
        <ResetPasswordForm
          open={openReset}
          handleClose={handleOpenReset}
          handleAlert={handleOpenBackDrop}
          uuid={uuid}
        />
      </div>
    </div>
  );
};

export default UpdateKinsurer;
