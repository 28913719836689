import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getFormula = () => {
  return request(`${urlBase}/jarvis/formula`);
};

export const putFormula = (uuid, category, values) => {
  let obj = values;
  switch (category) {
    case 'appInfo':
      obj = {
        ...values,
        action: {
          text: 'DESCARGAR AHORA',
          url: '',
        },
      };
      break;
    default:
      break;
  }
  return request(`${urlBase}/jarvis/formula/${category}/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: {
        ...obj,
      },
    }),
  });
};

export const putFormulaArray = (uuid, category, values) => {
  return request(`${urlBase}/jarvis/formula/${category}/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: values,
    }),
  });
};
