import { urlBase } from '../../../../constants';
import request from '../../../../utils/request';

export function AddContentIncentives(values, HTML) {
  return request(`${urlBase}/jarvis/content`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      category: 'incentives',
      content: HTML,
      description: values.description,
      max: values.max,
      effectiveDate: values.effectiveDate,
      title: values.title,
      shortTitle: values.shortTitle,
      status: values.status,
      subCategory: values.subCategory,
      groups: values.groups,
    }),
  });
}

export function updateContentIncentives(values, HTML, uuid) {
  return request(`${urlBase}/jarvis/content/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: HTML,
      description: values.description,
      max: values.max,
      effectiveDate: values.effectiveDate,
      title: values.title,
      shortTitle: values.shortTitle,
      status: values.status,
      subCategory: values.subCategory,
      groups: values.groups,
    }),
  });
}

export const GetCategoryGroups = (category = 'status') => {
  return request(`${urlBase}/notifications/segmentation/${category}`);
};
