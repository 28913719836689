/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { Auth, Storage } from 'aws-amplify';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import appComponent from '../../../assets/images/app-component.png';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import { openInNewTab } from '../../../utils/openInNewTab';
import { Context } from '../../../context/GeneralContext';
import img from '../../../assets/images/img.png';
import CardContainer from '../CardContainer';
import { branch } from '../../../constants';
import { putFormula } from './request';

import styles from './index.module.scss';
import { appInfoValidation } from './validations';

const AppDescriptionForm = ({ style }) => {
  const { generalData, setCategoryFormulaWeb } = useContext(Context);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { formula } = generalData;
  const { appInfo } = formula;

  const form = useFormik({
    initialValues: {
      summary: appInfo.content.summary,
      title: appInfo.content.title,
      source: appInfo.content.source,
    },
    validate: appInfoValidation,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = (values, key) => {
    setLoading(true);
    const body = {
      ...values,
    };
    if (key) {
      body.key = key;
      delete body.source;
    }
    putFormula(appInfo.uuid, appInfo.category, body).then(res => {
      if (!res.notifications.requestMessage) {
        setCategoryFormulaWeb(appInfo.category, res.data.uuid.Attributes);
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setShow(false);
      setDisabled(true);
    });
  };

  const handleUpload = async event => {
    const validate = await form.validateForm();
    if (Object.keys(validate).length === 0) {
      setLoading(true);
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(event.target.files[0]);
      const ext = event.target.files[0].type;
      fileReader.onload = async () => {
        Auth.currentAuthenticatedUser({
          bypassCache: true,
        }).then(async () => {
          const res = await Storage.put(
            `${branch}/${appInfo.uuid}/kinsuappimage.${ext.split('/')[1]}`,
            fileReader.result,
          );
          handleSubmit(form.values, res.key);
          setLoading(false);
        });
      };
    } else {
      alert('Se requere un título y descripción para subir un nuevo recurso');
      event.target.value = null;
    }
  };

  const showEditButtons = () => {
    setDisabled(false);
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
    setDisabled(true);
  };

  return (
    <CardContainer
      style={style}
      title={appInfo.description}
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
      modalChild={<img src={appComponent} width="100%" />}
    >
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="title"
          name="title"
          label="Titulo"
          value={form.values.title}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.title && form.errors.title}
          helperText={form.touched.title && form.errors.title}
        />
        <TextField
          id="summary"
          name="summary"
          label="Descripción"
          value={form.values.summary}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.summary && form.errors.summary}
          helperText={form.touched.summary && form.errors.summary}
        />
        {disabled ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openInNewTab(form.values.source)}
            fullWidth
            sx={{ marginTop: '15px' }}
          >
            Ver imagen actual
          </Button>
        ) : (
          <LoadingButton
            fullWidth
            variant="outlined"
            component="label"
            sx={{ marginTop: '15px' }}
            loading={loading}
          >
            <input
              type="file"
              name="file"
              hidden
              onChange={e => handleUpload(e)}
              accept="image/jpeg,image/gif,image/png,image/x-eps,image/heic,image/heif"
            />
            <img src={img} className={styles.InputImg} />
            Selectionar archivo
          </LoadingButton>
        )}

        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

AppDescriptionForm.propTypes = {
  style: PropTypes.any,
};

export default AppDescriptionForm;
