import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import CaretLeftIcon from '../../components/Icons/CaretLeftIcon';
import NavBar from '../../components/Navbar';
import ListItems from './listItems';

import styles from './index.module.scss';

const drawerWidth = 200;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent({ children }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.DashboardContainer}>
      <NavBar open={open} toggleDrawer={toggleDrawer} />
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            backgroundColor: 'primary.main',
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <CaretLeftIcon color="white" fontSize="24" fontWeight="1.9" />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" className={styles.ListButtons}>
          <ListItems disabled={open} />
        </List>
      </Drawer>
      <div className={styles.ChildrenContainer}>{children}</div>
    </div>
  );
}

DashboardContent.propTypes = {
  children: PropTypes.element,
};

export default React.memo(DashboardContent);
