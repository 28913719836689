export const validations = values => {
  const errors = {};
  if (!values.sengridId || values.sengridId === '') {
    errors.sengridId = 'Este campo es requerido.';
  }
  if (values.groups.length === 0) {
    errors.groups = 'Selecciona al menos uno';
  }
  return errors;
};
