import React, { useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Context, initialUserState } from '../../context/SessionContext';
import logo from '../../assets/images/logo-white.png';
import { sessionKey } from '../../constants';
import styles from './index.module.scss';

function Logout() {
  const navigate = useNavigate();
  const { setUserData } = useContext(Context);

  useEffect(() => {
    setTimeout(async () => {
      try {
        sessionStorage.removeItem(sessionKey);
        setUserData(initialUserState);
        await Auth.signOut();
        navigate('/');
      } catch (error) {
        console.info('error signing out: ', error);
      }
    }, 2000);
  });

  return (
    <div className={styles.Logout}>
      <Typography variant="subtitle1" component="p">
        ¡Adiós!
      </Typography>
      <img src={logo} alt="kinsu logout" />
      <Typography variant="subtitle1" component="p">
        ¡Cuidarnos nos acerca!
      </Typography>
    </div>
  );
}

export default Logout;
