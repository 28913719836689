import React from 'react';
import PropTypes from 'prop-types';

function ArrowClockwiseIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.0209 12.4645H28.0209V6.46448"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.2218 8.22183C9.24325 7.20038 10.4559 6.39013 11.7905 5.83733C13.125 5.28452 14.5554 5 16 5C17.4445 5 18.8749 5.28452 20.2095 5.83733C21.5441 6.39013 22.7567 7.20038 23.7782 8.22183L28.0208 12.4645"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.97913 19.5355H3.97913V25.5355"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7782 23.7782C22.7567 24.7996 21.5441 25.6099 20.2095 26.1627C18.8749 26.7155 17.4445 27 16 27C14.5555 27 13.1251 26.7155 11.7905 26.1627C10.4559 25.6099 9.24327 24.7996 8.22183 23.7782L3.97919 19.5355"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

ArrowClockwiseIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

ArrowClockwiseIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(ArrowClockwiseIcon);
