import React, { useEffect, useContext } from 'react';

import Loading from '../../../components/Loading';

import AppDescriptionForm from './AppDescriptionForm';
import { Context } from '../../../context/GeneralContext';
import TrainingSection from './TrainingSection';
import MediaSection from './MediaSection';
import ContactForm from './ContactForm';
import { getFormula } from './request';
import TitleForm from './TitleForm';

import styles from './index.module.scss';

const FormulaWeb = () => {
  const { generalData, setFormulaWeb } = useContext(Context);
  const { formula } = generalData;

  const handleFormula = () => {
    getFormula().then(res => {
      setFormulaWeb(res.data);
    });
  };

  useEffect(() => {
    handleFormula();
  }, []);

  if (Object.keys(formula).length === 0) {
    return <Loading transparent white/>;
  }

  return (
    <div className={styles.FormulaContainer}>
      <div className={styles.FormulaContent}>
        <div className={styles.Block}>
          <TitleForm style={{ flex: 1 }} />
          <ContactForm style={{ flex: 1 }} />
          <AppDescriptionForm style={{ flex: 1 }} />
        </div>
        <div className={styles.Block}>
          <MediaSection content={formula.material} style={{ flex: 1 }} />
          <TrainingSection style={{ flex: 1 }} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormulaWeb);
