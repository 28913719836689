/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DownloadSimpleIcon from '../Icons/DownloadSimpleIcon';
import { exportAsImage } from '../../utils/exportAsImage';

import styles from './index.module.scss';
import classNames from 'classnames';

function ShareQrCard({ code, size, style }) {
  const exportRef = useRef();

  return (
    <div className={classNames(style && style, styles.General)}>
      <Typography
        variant="body1"
        component="div"
        className={styles.ShareQrCardTitle}
      >
        Muestra tu código personal.
      </Typography>
      <div ref={exportRef} className={styles.ShareQrCodes}>
        <div className={styles.QrCode}>
          <Typography variant="body2" component="span">
            {code?.msg}
          </Typography>
          {code && (
            <QRCode
              value={code.link}
              size={size}
              bgColor="#ffffff"
              fgColor="#24508f"
              eyeRadius={8}
            />
          )}
          <Typography
            variant="subtitle2"
            component="span"
            className={styles.CodeText}
          >
            {code?.code}
          </Typography>
        </div>
      </div>
      <div className={styles.DownloadContent}>
        <Typography variant="body2" component="div" gutterBottom>
          Descarga y comparte.
        </Typography>
        <IconButton
          data-testid="downloadCode-test"
          onClick={() => exportAsImage(exportRef.current, code?.code)}
        >
          <DownloadSimpleIcon color="#4575ba" />
        </IconButton>
      </div>
    </div>
  );
}

export default React.memo(ShareQrCard);
