import React, { Suspense } from 'react';
import { Await, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

import Loading from '../../components/Loading';
import { sections } from '../../constants';

function compareGroups(section, userGroups) {
  if (userGroups.includes('admin-crew')) {
    return true;
  }
  let pivot = false;
  section.forEach(element => {
    if (userGroups.includes(element)) {
      pivot = true;
    }
  });
  return pivot;
}

export function isAuthorized(section) {
  return Auth.currentSession().then(({ accessToken }) => {
    const access = {};
    const groups = accessToken.payload['cognito:groups'];
    Object.keys(sections).forEach(key => {
      access[key] = compareGroups(sections[key], groups);
    });
    if (section) {
      return access[section];
    }
    return access;
  });
}

const Authorization = ({ access, white }) => {
  const auth = isAuthorized(access);
  return (
    <Suspense fallback={<Loading white={white} />}>
      {/* errorElement={<Navigate to="/" />} */}
      <Await resolve={auth}>
        <Outlet />
      </Await>
    </Suspense>
  );
};

Authorization.propTypes = {
  access: PropTypes.string,
  white: PropTypes.any,
};

export default React.memo(Authorization);
