import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function BasicDatePicker({
  margin = 'normal',
  onChange,
  name,
  error,
  helperText,
  variant,
  value,
  widthMoment,
  size,
  ...other
}) {
  const handleOnChange = value => {
    onChange({
      target: {
        name,
        value: widthMoment ? new Date(moment(value)) : value,
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        renderInput={params => (
          <TextField
            data-testid="inputDatePicker"
            margin={margin}
            size={size}
            {...params}
            error={error}
            helperText={helperText}
            variant={variant}
          />
        )}
        {...other}
        value={value}
        views={['year', 'month', 'day']}
        inputFormat="DD / MM / YYYY"
        name={name}
        maxDate={new Date()}
        onChange={handleOnChange}
      />
    </LocalizationProvider>
  );
}

BasicDatePicker.propTypes = {
  margin: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.any,
  widthMoment: PropTypes.bool,
  size: PropTypes.string,
};

export default React.memo(BasicDatePicker);
