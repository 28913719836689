import React, { useEffect, useState } from 'react';

import mediaComponent from '../../../assets/images/t_stories.png';
import PlusCircleIcon from '../../../components/Icons/PlusCircleIcon';
import { getStoriesCategories, getStoryByCategory } from './request';
import Confirmation from '../../../components/Confirmation';
import CreateCategory from './CreateCategory';
import CardContainer from '../CardContainer';
import MediaSection from './MediaSection';

import styles from './index.module.scss';
import classNames from 'classnames';
import theme from '../../../theme';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const HomeApp = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [stories, setStories] = useState({});

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleCreateNew = () => {
    setNewCategory(true);
    setSelectedCategory(null);
  };

  const handleSetSelected = category => {
    setSelectedCategory(category);
    setNewCategory(false);
  };

  const handleGetCategories = () => {
    getStoriesCategories().then(res => setCategories(res.data));
    setNewCategory(false);
  };

  const handleGetStoryCategory = () => {
    getStoryByCategory(selectedCategory.uuid).then(res => {
      setStories(res.data.content);
    });
  };

  const handleRefresh = (cancel = false) => {
    handleGetCategories();
    setSelectedCategory(null);
    if (!cancel) {
      handleOpenBackDrop();
    }
  };

  useEffect(() => {
    if (categories.length === 0) {
      handleGetCategories();
    }
    if (selectedCategory) {
      handleGetStoryCategory();
    }
  }, [selectedCategory]);

  return (
    <div className={styles.HomeAppContainer}>
      <div className={styles.Block}>
        <CardContainer
          style={{ flex: 1 }}
          title="Colecciones"
          modalChild={<img src={mediaComponent} width="100%" />}
        >
          <div className={styles.CategoryList}>
            {categories?.map(category => {
              return (
                <button
                  key={category.uuid}
                  className={classNames(
                    styles.CategoryButton,
                    selectedCategory?.uuid === category.uuid &&
                      styles.CategoryButtonSelected,
                  )}
                  onClick={() => handleSetSelected(category)}
                >
                  {category.category}
                </button>
              );
            })}
            <Tooltip title="Agregar categoría">
              <IconButton aria-label="add" onClick={handleCreateNew}>
                <PlusCircleIcon
                  fontSize="48"
                  color={theme.palette.primary.main}
                />
              </IconButton>
            </Tooltip>
          </div>
        </CardContainer>
      </div>
      {newCategory && (
        <div className={styles.Block}>
          <CreateCategory
            category={selectedCategory}
            content={stories}
            style={{ flex: 1 }}
            onRefresh={handleRefresh}
          />
        </div>
      )}
      {selectedCategory && stories && (
        <div className={styles.Block}>
          <MediaSection
            category={selectedCategory}
            content={stories}
            style={{ flex: 1 }}
            onRefresh={handleRefresh}
          />
        </div>
      )}
      <Confirmation
        message="Cambios guardados"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
    </div>
  );
};

export default React.memo(HomeApp);
