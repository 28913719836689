import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Auth, Storage } from 'aws-amplify';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

import theme from '../../../theme';
import { branch } from '../../../constants';
import TrashIcon from '../../../components/Icons/TrashIcon';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import LinkSimpleIcon from '../../../components/Icons/LinkSimpleIcon';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';

import { handleTypes } from '../constants';
import CardContainer from '../CardContainer';

import { validation } from './validations';

import styles from './MediaSection.module.scss';
import { updateStoryByCategory } from './request';

const MediaSection = ({ style, category, content, onRefresh }) => {
  const [miniatureKey, setMiniatureKey] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [resource, setResource] = useState([]);
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState(null);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: category.category || '',
      miniature: content.miniature,
    },
    validate: validation,
    onSubmit: values => handleRequest(values, category.uuid),
  });

  const handleNewItem = () => {
    setNewItem(!newItem);
  };

  const handleCancelEdit = () => {
    form.resetForm();
    setLoading(false);
    setProgress(0);
    setNewItem(false);
    setMiniatureKey(null);
    setKey(null);
    onRefresh(true);
  };

  const handleRequest = async (values, categoryId) => {
    setLoading(true);
    try {
      const media = [...resource];
      const res = await updateStoryByCategory(
        { ...values, media, miniatureKey },
        categoryId,
      );
      if (!res.notifications.requestMessage) {
        form.resetForm();
        setLoading(false);
        setProgress(0);
        setNewItem(false);
        setMiniatureKey(null);
        setKey(null);
        onRefresh();
      }
    } catch (error) {}
  };

  const addNewResource = key => {
    setResource([...resource, { key }]);
  };

  const deleteResource = index => {
    const newArray = [
      ...resource.slice(index + 1),
      ...resource.slice(0, index),
    ];
    setResource(newArray);
  };

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleUpload = async event => {
    setLoading(true);
    const file = event.target.files[0];
    const mimetype = file.type;
    let ext = mimetype.split('/')[1];
    if (ext === 'quicktime') {
      ext = 'mov';
    }
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async () => {
        const result = await Storage.put(
          `${branch}/${category.uuid}/${resource.length}.${ext}`,
          file,
          { progressCallback },
        );
        if (event.target.name === 'miniature') {
          setMiniatureKey(result.key);
          return null;
        }
        setKey(result.key);
        addNewResource(result.key);
      });
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    if (key) {
      setLoading(false);
      setProgress(0);
    }
    if (miniatureKey) {
      setLoading(false);
      setProgress(0);
    }
  }, [key, miniatureKey]);

  useEffect(() => {
    if (content.media) {
      setResource(content.media);
    }
  }, [content]);

  return (
    <CardContainer
      style={style}
      title={`Colección: ${category.category}`}
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={handleNewItem}
    >
      {!newItem && (
        <div className={styles.ActualContent}>
          <img src={content.miniature} width="100px" />
          <div>
            <Typography variant="body1" color="primary">
              Lista de historias
            </Typography>
            {resource?.map(element => {
              return (
                <Typography
                  key={element.uuid}
                  variant="body1"
                  component="a"
                  href={element.url}
                  target="_blank"
                  className={classnames(styles.TextOff, styles.ReadUrl)}
                >
                  <LinkSimpleIcon fontSize="24" color="#919497" />
                  {element.url}
                </Typography>
              );
            })}
          </div>
        </div>
      )}
      {newItem && (
        <form onSubmit={form.handleSubmit}>
          <TextField
            id="category"
            name="category"
            label="Título"
            value={form.values.category}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.category && form.errors.category}
            helperText={form.touched.category && form.errors.category}
          />
          {miniatureKey && (
            <Typography
              variant="subtitle1"
              color="success.main"
              component="div"
              className={styles.CheckText}
            >
              Miniatura lista.
              <CheckCircleIcon fontSize="24" color="#2e7d32" fontWeight="2" />
            </Typography>
          )}
          {!miniatureKey && (
            <>
              {progress > 0 && activeInput === 'miniature' ? (
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ marginTop: '25px', marginBottom: '10px' }}
                />
              ) : (
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  component="label"
                  sx={{ marginTop: '15px' }}
                  loading={loading}
                >
                  <input
                    type="file"
                    name="miniature"
                    hidden
                    onChange={e => {
                      setActiveInput(e.target.name);
                      handleUpload(e);
                    }}
                    accept={handleTypes('img')}
                  />
                  Seleccionar miniatura
                </LoadingButton>
              )}
            </>
          )}
          {progress > 0 && activeInput === 'file' ? (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ marginTop: '25px', marginBottom: '10px' }}
            />
          ) : (
            <LoadingButton
              fullWidth
              variant="outlined"
              component="label"
              sx={{ marginTop: '15px' }}
              loading={loading}
            >
              <input
                type="file"
                name="file"
                hidden
                onChange={e => {
                  setActiveInput(e.target.name);
                  handleUpload(e);
                }}
                accept={handleTypes('video')}
              />
              Agregar video
            </LoadingButton>
          )}
          <div className={styles.EditMedia}>
            {resource?.map((element, index) => {
              return (
                <Typography
                  key={element.uuid}
                  variant="body1"
                  className={classnames(styles.TextOff, styles.ReadUrl)}
                >
                  {resource.length > 1 && (
                    <IconButton onClick={() => deleteResource(index)}>
                      <TrashIcon
                        fontSize="24"
                        color={theme.palette.warning.main}
                      />
                    </IconButton>
                  )}
                  {element.url ? element.url : element.key}
                </Typography>
              );
            })}
          </div>
          <div className={styles.ActionButtons}>
            <LoadingButton variant="contained" color="primary" type="submit">
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelEdit}
            >
              Cancelar
            </Button>
          </div>
        </form>
      )}
    </CardContainer>
  );
};

MediaSection.propTypes = {
  category: PropTypes.shape({
    category: PropTypes.string,
    miniature: PropTypes.string,
    uuid: PropTypes.string,
  }),
  content: PropTypes.shape({
    media: PropTypes.array,
    miniature: PropTypes.string,
  }),
  onRefresh: PropTypes.func,
  style: PropTypes.any,
};

export default React.memo(MediaSection);
