export const validations = values => {
  const errors = {};
  if (!values.title || values.title === '') {
    errors.title = 'Titulo es requerido.';
  }
  if (!values.message || values.message === '') {
    errors.message = 'Campo requerido.';
  }
  if (!values.screen || values.screen === '') {
    errors.screen = 'Campo requerido.';
  }
  if (values.type === 'topic' && values.groups.length === 0) {
    errors.groups = 'Selecciona al menos uno.';
  }
  if (values.type !== 'topic' && values.recipients.length === 0) {
    errors.recipients = 'Se necesita al menos un destinatario.';
  }
  return errors;
};
