/* eslint-disable no-debugger */
export const toMoney = (value, currency = '') => {
  const money = typeof value === 'number' ? value : Number(value);
  if (!value) {
    return 'N/A';
  }
  if (isNaN(money)) {
    return value;
  }
  const newValue = money
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$ ${newValue} ${currency}`;
};

export const stringToNumber = value => {
  const newNumber = Number(value.replaceAll(',', '').split('.')[0]);
  if (isNaN(newNumber)) {
    return value;
  }
  return newNumber;
};
