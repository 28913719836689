import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './TriggerCard.module.scss';

const TriggerCard = ({ label, actionLabel, onClick, icon }) => {
  return (
    <div className={styles.TriggerAction}>
      <div className={styles.Icon}>{icon}</div>
      <Typography variant="h6" color="primary" gutterBottom>
        {label}
      </Typography>
      <Button variant="contained" color="primary" fullWidth onClick={onClick}>
        {actionLabel}
      </Button>
    </div>
  );
};

TriggerCard.propTypes = {
  actionLabel: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default TriggerCard;
