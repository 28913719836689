import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import LinkSimpleIcon from '../../../components/Icons/LinkSimpleIcon';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import TrashIcon from '../../../components/Icons/TrashIcon';

import { TrainingValidation } from './validations';
import { putStepsFormulaById } from './request';
import { categories } from '../constants';

import styles from './TrainingResource.module.scss';
import classnames from 'classnames';

const TrainingResource = ({ contentId, resource, update, onDelete }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const form = useFormik({
    initialValues: {
      topic: resource.topic,
      description: resource.description,
      url: resource.url,
    },
    validate: TrainingValidation,
    onSubmit: values => handleSubmit(values),
  });

  const handleSubmit = values => {
    const payload = { training: { ...values, uuid: resource.uuid } };
    setLoading(true);
    putStepsFormulaById(contentId, payload).then(res => {
      if (!res.notifications.requestMessage) {
        update();
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setEdit(false);
    });
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  return (
    <>
      <div className={styles.ResourceContainerOff}>
        <div className={styles.ResourceHeader}>
          <Typography
            variant="body1"
            fontWeight={400}
            className={styles.TextOff}
          >
            {categories[resource.topic]}
          </Typography>
          <span>
            <IconButton onClick={handleEdit}>
              <NotePencilIcon color="#4575ba" fontSize="24" fontWeight="2" />
            </IconButton>
            <IconButton onClick={handleOpenConfirm}>
              <TrashIcon color="#919497" fontSize="24" fontWeight="2" />
            </IconButton>
          </span>
        </div>
        {!edit ? (
          <>
            <Typography variant="body1" className={styles.TextOff} gutterBottom>
              {resource.description}
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href={resource.url}
              target="_blank"
              className={classnames(styles.TextOff, styles.ReadUrl)}
            >
              <LinkSimpleIcon fontSize="24" color="#919497" /> {resource.url}
            </Typography>
          </>
        ) : (
          <form onSubmit={form.handleSubmit}>
            <TextField
              id="description"
              name="description"
              label="Descripción"
              value={form.values.description}
              onChange={form.handleChange}
              margin="normal"
              size="small"
              error={form.touched.description && form.errors.description}
              helperText={form.touched.description && form.errors.description}
            />
            <TextField
              id="url"
              name="url"
              label="Link"
              value={form.values.url}
              onChange={form.handleChange}
              margin="normal"
              size="small"
              error={form.touched.url && form.errors.url}
              helperText={form.touched.url && form.errors.url}
            />
            <div className={styles.ActionButtons}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                Guardar cambios
              </LoadingButton>
              <Button variant="outlined" color="primary" onClick={handleEdit}>
                Cancelar
              </Button>
            </div>
          </form>
        )}
      </div>
      <Dialog open={openConfirm} onClose={handleOpenConfirm}>
        <div className={styles.ConfirmModal}>
          <Typography variant="h6" component="p">
            ¿Está seguro de eliminar este recurso?
          </Typography>
          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => {
                onDelete(resource.uuid);
                form.resetForm();
                handleOpenConfirm();
              }}
              sx={{ flex: 1 }}
            >
              Aceptar
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenConfirm}
              sx={{ flex: 1 }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

TrainingResource.propTypes = {
  contentId: PropTypes.any,
  onDelete: PropTypes.func,
  resource: PropTypes.shape({
    description: PropTypes.any,
    topic: PropTypes.any,
    url: PropTypes.any,
    uuid: PropTypes.any,
  }),
  update: PropTypes.func,
};

export default TrainingResource;
