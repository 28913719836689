import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

import { styles } from './Options.styles';

const payment = {
  MONTHLY: 'Mensual',
  QUARTERLY: 'Trimestral',
  'SEMI-ANNUAL': 'Semestral',
  ANNUAL: 'Contado',
};

const Options = ({ values }) => {
  return (
    <View style={styles.leadInfo}>
      <Text style={styles.leadInfoTitle}>Opciones de Cotización</Text>
      <View style={{ ...styles.centered, padding: 6 }}>
        <Text style={styles.leadInfoSubtitle}>TUS DATOS</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Nombre y apellido</Text>
        <Text style={styles.leadInfoInput}>{values.fullName}</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Género</Text>
        <Text style={styles.leadInfoInput}>
          {values.gender === 'MALE' ? 'Hombre' : 'Mujer'}
        </Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Fecha nacimiento</Text>
        <Text style={styles.leadInfoInput}>
          {moment(new Date(values.dateOfBirth).getTime()).format('DD-MM-YYYY')}
        </Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Código postal</Text>
        <Text style={styles.leadInfoInput}>{values.postalCode}</Text>
      </View>
      <View style={{ ...styles.centered, padding: 6 }}>
        <Text style={styles.leadInfoSubtitle}>TU AUTO</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Marca</Text>
        <Text style={styles.leadInfoInput}>{values.brand}</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Modelo</Text>
        <Text style={styles.leadInfoInput}>{values.subBrand}</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Año</Text>
        <Text style={styles.leadInfoInput}>{values.model}</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Cobertura</Text>
        <Text style={styles.leadInfoInput}>{values.readCoverage}</Text>
      </View>
      <View style={styles.centered}>
        <Text style={styles.leadInfoLabel}>Tipo de pago</Text>
        <Text style={styles.leadInfoInput}>{payment[values.paymentType]}</Text>
      </View>
    </View>
  );
};

Options.propTypes = {
  coverage: PropTypes.array,
  values: PropTypes.object,
};

export default Options;
