import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Authorization from '../../components/Authorization';
import IncentiveEditor from './Incentives/IncentiveEditor';
import TyCEditor from './TyC/TyCEditor';
import TabPanel from './TabPanel';

const DynamicContent = () => {
  return (
    <Routes>
      <Route element={<Authorization access="dynamicContent" white />}>
        <Route path="/" element={<TabPanel />} />
        <Route path="/incentive" element={<IncentiveEditor />} />
        <Route path="/details" element={<TyCEditor />} />
      </Route>
    </Routes>
  );
};

export default React.memo(DynamicContent);
