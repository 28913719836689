import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

import NotePencilIcon from '../../../components/Icons/NotePencilIcon';

import CardContainer from '../CardContainer';
import { getStepsFormulaById, putStepsFormulaById } from './request';

import styles from './EditStep.module.scss';

const EditStepLink = ({ style, content, update }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      showLinkToShare: content.showLinkToShare || false,
    },
    validate: () => {},
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = values => {
    setLoading(true);
    putStepsFormulaById(content.uuid, values).then(res => {
      if (!res.notifications.requestMessage) {
        getStepsFormulaById(content.uuid).then(res => {
          form.setFieldValue(
            'showLinkToShare',
            Boolean(res.data.showLinkToShare),
          );
          update();
        });
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setShow(false);
    });
  };

  const showEditButtons = () => {
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
  };

  return (
    <CardContainer
      style={style}
      title="Mostrar link para referenciar"
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
    >
      <form onSubmit={form.handleSubmit}>
        <FormControlLabel
          label={
            form.values.showLinkToShare
              ? 'Se muestra en esta sección'
              : 'No se muestra en esta sección'
          }
          control={
            <Switch
              value={form.values.showLinkToShare}
              checked={form.values.showLinkToShare}
              onChange={event => {
                form.setFieldValue('showLinkToShare', event.target.checked);
              }}
              disabled={!show}
            />
          }
        />
        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

EditStepLink.propTypes = {
  content: PropTypes.object,
  style: PropTypes.object,
  update: PropTypes.func,
};

export default EditStepLink;
