import { StyleSheet } from '@react-pdf/renderer';
import theme from '../../theme';

export const styles = StyleSheet.create({
  leadInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: 180,
    borderRadius: 15,
    backgroundColor: theme.palette.neutral[3],
  },
  leadInfoTitle: {
    backgroundColor: theme.palette.secondary.main,
    padding: 7,
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  centered: {
    padding: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leadInfoSubtitle: {
    padding: 5,
    fontSize: 8,
    fontWeight: 600,
    color: theme.palette.primary.main,
    borderRadius: 50,
    border: 1,
    borderColor: theme.palette.primary.main,
    textAlign: 'center',
    width: 70,
  },
  leadInfoLabel: {
    fontSize: 8,
    color: theme.palette.primary.main,
    marginBottom: 3,
  },
  leadInfoInput: {
    fontSize: 8,
    padding: 5,
    backgroundColor: theme.palette.neutral[0],
    borderRadius: 5,
    width: 130,
    textAlign: 'center',
  },
});
