import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import Confirmation from '../../../components/Confirmation';

import { isEmail, isUUIDv4 } from '../../../utils/validations';

import TagsForm from './TagsForm';
import TopicForm from './TopicForm';
import { initialScreen } from './constants';
import { validations } from './validations';
import ScreenSelector from './ScreenSelector';
import { postPushNotifications } from './request';

import styles from './index.module.scss';

const Push = () => {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);
  const [section, setSection] = useState(initialScreen);
  const [errorTag, setErrorTag] = useState(false);
  const [helperTextTag, setHelperTextTag] = useState('');

  const form = useFormik({
    initialValues: {
      type: 'topic',
      title: '',
      message: '',
      groups: [],
      recipients: [],
      screen: 'notifications',
    },
    validate: validations,
    onSubmit: values => {
      setLoading(true);
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async values => {
    try {
      const payload = { ...values };
      if (form.values.type === 'topic') {
        payload.recipients = values.groups;
      }
      delete payload.groups;
      const res = await postPushNotifications(payload);
      if (!res.notifications.requestMessage) {
        form.resetForm();
        handleResetTags();
        handleOpenBackDrop();
        resetSelectedScreen();
      }
    } catch (error) {}
    setLoading(false);
  };

  const onKeyDown = keyEvent => {
    if (keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleOpenScreenSelector = () => {
    setOpenSelector(true);
  };

  const handleCloseScreenSelector = () => {
    setOpenSelector(false);
  };

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
    setLoading(false);
  };

  const HandleOnDelete = tagIndex => {
    form.setFieldValue(
      'recipients',
      form.values.recipients.filter((_, i) => i !== tagIndex),
    );
  };

  const handleTagsValidation = useCallback(msg => {
    if (!msg) {
      setErrorTag(false);
      setHelperTextTag('');
      return;
    }
    setErrorTag(true);
    setHelperTextTag(msg);
  }, []);

  const HandleOnAddition = value => {
    handleTagsValidation();
    let response = true;
    const values = value.split(/[, ]+/);
    const tagsPivot = form.values.recipients;
    values.forEach(element => {
      if (
        Boolean(form.values.type === 'userId' && isUUIDv4(element)) ||
        Boolean(form.values.type === 'email' && isEmail(element))
      ) {
        if (form.values.recipients.includes(element)) {
          response = false;
          handleTagsValidation('Entrada duplicada');
        } else {
          tagsPivot.push(element);
        }
      } else {
        response = false;
        handleTagsValidation(
          'Uno o más de los agregados no cumplieron con el formato adecuado',
        );
      }
    });
    form.setFieldValue('recipients', tagsPivot);
    return response;
  };

  const handleResetTags = () => {
    form.setFieldValue('recipients', []);
    handleTagsValidation();
  };

  const handleSelectedScreen = value => {
    setSection(value);
    form.setFieldValue('screen', value.screen);
    handleCloseScreenSelector();
  };

  const resetSelectedScreen = () => {
    setSection(initialScreen);
    form.setFieldValue('screen', initialScreen.screen);
  };

  useEffect(() => {
    if (form.values.recipients > 0) {
      handleResetTags();
    }
    if (form.values.groups > 0) {
      form.setFieldValue('groups', []);
    }
  }, [form.values.type]);

  return (
    <>
      <div className={styles.NotificationsContainer}>
        <form
          className={styles.TabFormColumn}
          onKeyDown={onKeyDown}
          onSubmit={form.handleSubmit}
        >
          <div className={styles.TabForm}>
            <div className={styles.LateralInputs}>
              <FormControl className={styles.RadioTypes}>
                <FormLabel className={styles.RadioTypesTitle}>
                  Destino
                </FormLabel>
                <RadioGroup
                  name="type"
                  onChange={form.handleChange}
                  value={form.values.type}
                >
                  <FormControlLabel
                    value="topic"
                    control={<Radio />}
                    label="Grupos"
                  />
                  <FormControlLabel
                    value="userId"
                    control={<Radio />}
                    label="UUID"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={styles.InputContent}>
              {form.values.type === 'topic' && (
                <TopicForm formController={form} />
              )}
              {form.values.type === 'userId' && (
                <TagsForm
                  formController={form}
                  tags={form.values.recipients}
                  onDelete={HandleOnDelete}
                  onAddition={HandleOnAddition}
                  reset={handleResetTags}
                  error={
                    errorTag ||
                    (form.touched.recipients && Boolean(form.errors.recipients))
                  }
                  helperText={
                    helperTextTag ||
                    (form.touched.recipients && form.errors.recipients)
                  }
                />
              )}
              {form.values.type === 'email' && (
                <TagsForm
                  formController={form}
                  tags={form.values.recipients}
                  onDelete={HandleOnDelete}
                  onAddition={HandleOnAddition}
                  reset={handleResetTags}
                  error={
                    errorTag ||
                    (form.touched.recipients && Boolean(form.errors.recipients))
                  }
                  helperText={
                    helperTextTag ||
                    (form.touched.recipients && form.errors.recipients)
                  }
                />
              )}
            </div>
            <div className={styles.ButtonSelector}>
              <Button
                variant="outlined"
                color={
                  form.touched.screen && form.errors.screen
                    ? 'error'
                    : 'primary'
                }
                sx={{ width: '100%', height: '55px' }}
                onClick={handleOpenScreenSelector}
              >
                Redirección a: {form.values.screen}
              </Button>
              {section && <img src={section.img} alt={section.screen} />}
            </div>
          </div>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            color="primary"
            className={styles.SubmitBtn}
          >
            Enviar
          </LoadingButton>
        </form>
      </div>
      <ScreenSelector
        open={openSelector}
        onClose={handleCloseScreenSelector}
        onSelect={value => handleSelectedScreen(value)}
      />
      <Confirmation
        message="Notificación enviada"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
    </>
  );
};

export default React.memo(Push);
