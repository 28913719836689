import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { useSpring, animated as a } from 'react-spring';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/es';

import PaperPlaneRightIcon from '../../../components/Icons/PaperPlaneRightIcon';
import CloseIcon from '../../../components/Icons/CloseIcon';
import { Context } from '../../../context/SessionContext';
import Loading from '../../../components/Loading';

import {
  deleteKinsurerComments,
  getKinsurerComments,
  postKinsurerComments,
  putKinsurerComments,
} from './request';
import styles from './CommentsBlock.module.scss';

const CommentsBlock = ({ open, onClose, uuid }) => {
  moment.locale('es');
  const { userData } = useContext(Context);
  const [listComments, setListComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const agentId = userData.username;

  const contentProps = useSpring({
    opacity: open ? 1 : 0,
    transitionDuration: '2s',
  });

  const form = useFormik({
    initialValues: {
      createdDate: Date.now(),
      userId: agentId,
      comment: '',
    },
    onSubmit: () => handleSubmit(),
  });

  const handleReset = () => {
    form.resetForm();
    setIsEdit(null);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (isEdit) {
      putKinsurerComments(form.values, isEdit, uuid).then(() => {
        handleGetComments();
        handleReset();
        setLoading(false);
      });
    } else {
      postKinsurerComments(form.values, uuid).then(() => {
        handleGetComments();
        handleReset();
        setLoading(false);
      });
    }
  };

  const handleEditComment = element => {
    console.info('element', element);
    form.setFieldValue('comment', element.comment);
    setIsEdit(element.commentId);
  };

  const handleDeleteComment = idComment => {
    setLoading(true);
    deleteKinsurerComments(idComment, uuid).then(() => {
      handleGetComments();
      setLoading(false);
    });
  };

  const handleGetComments = () => {
    getKinsurerComments(uuid).then(res => {
      setListComments(res.data);
    });
  };

  useEffect(() => {
    handleGetComments();
  }, []);

  if (!listComments) {
    return <Loading white flex />;
  }

  return (
    <a.div
      className={styles.CommentsBlockContainer}
      style={{ ...contentProps }}
    >
      <div className={styles.HeaderButton} onClick={onClose}>
        <Typography variant="h6" color="primary">
          Comentarios
        </Typography>
        <CloseIcon fontWeight="2" fontSize="24" color="#4575ba" />
      </div>
      <div className={styles.CommentsContainer}>
        {listComments?.map(element => {
          return (
            <div className={styles.Comment} key={element.commentId}>
              <Typography variant="body1" color="primary">
                {element.agentFullName}
              </Typography>
              <Typography
                variant="caption"
                sx={{ textAlign: 'end', fontWeight: '200' }}
              >
                {moment(new Date(element.createdDate)).format('DD-MM-YYYY')} /{' '}
                {moment(new Date(element.createdDate)).format('h:mm a')}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: '400' }}>
                {element.comment}
              </Typography>
              {element.idAgent === agentId && (
                <div className={styles.ButtonsInLine}>
                  <Typography
                    variant="body1"
                    color="primary"
                    component="button"
                    className={styles.TextButton}
                    onClick={() => handleEditComment(element)}
                  >
                    Editar
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    component="button"
                    className={styles.TextButton}
                    onClick={() => handleDeleteComment(element.commentId)}
                  >
                    Borrar
                  </Typography>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <form onSubmit={form.handleSubmit} className={styles.InputContainer}>
        <TextField
          margin="normal"
          size="small"
          id="comment"
          placeholder="Añadir comentario"
          name="comment"
          disabled={loading}
          value={form.values.comment}
          onChange={e => {
            form.handleChange(e);
            if (e.target.value === '') {
              handleReset();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size="20px" />
                ) : (
                  <IconButton aria-label="send-comment" type="submit">
                    <PaperPlaneRightIcon
                      fontSize="32"
                      color="#4575ba"
                      fontWeight="2"
                      className={styles.SendIcon}
                    />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </form>
    </a.div>
  );
};

CommentsBlock.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  uuid: PropTypes.string,
};

export default CommentsBlock;
