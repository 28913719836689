import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';

import Loading from '../../../components/Loading';
import Confirmation from '../../../components/Confirmation';

import EditStep from './EditStep';
import EditStepLink from './EditStepLink';
import MediaSection from './MediaSection';
import CardContainer from '../CardContainer';
import TrainingSection from './TrainingSection';
import { getStepsFormula, getStepsFormulaById } from './request';

import styles from './index.module.scss';

const Formula = () => {
  const [steps, setSteps] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleFormula = () => {
    getStepsFormula().then(res => {
      setSteps(res.data);
    });
  };

  const handleSelectedStep = async step => {
    try {
      const category = await getStepsFormulaById(step.uuid);
      setSelectedStep({ ...category.data, uuid: step.uuid });
    } catch (error) {}
  };

  useEffect(() => {
    handleFormula();
  }, []);

  if (!steps) {
    return <Loading transparent white />;
  }

  return (
    <>
      <div className={styles.FormulaContainer}>
        <div className={styles.FormulaContent}>
          <div className={styles.Block}>
            <CardContainer title="Pasos">
              <div className={styles.StepsContainer}>
                {steps.map(step => {
                  return (
                    <button
                      key={step.uuid}
                      className={classNames(
                        styles.Step,
                        selectedStep &&
                          selectedStep.uuid === step.uuid &&
                          styles.StepSelected,
                      )}
                      onClick={() => handleSelectedStep(step)}
                    >
                      <Typography variant="body1" color="primary">
                        {step.description}
                      </Typography>
                      <img src={step.miniature} alt={step.name} />
                    </button>
                  );
                })}
              </div>
            </CardContainer>
          </div>
          {selectedStep && (
            <>
              <div className={styles.Block}>
                <EditStep
                  content={selectedStep}
                  style={{ flex: 1 }}
                  update={() => {
                    handleOpenBackDrop();
                    handleFormula();
                  }}
                />
                <EditStepLink
                  content={selectedStep}
                  style={{ flex: 1 }}
                  update={() => {
                    handleOpenBackDrop();
                    handleFormula();
                  }}
                />
              </div>
              <div className={styles.Block}>
                <MediaSection
                  content={selectedStep}
                  style={{ flex: 1 }}
                  update={() => {
                    handleSelectedStep(selectedStep);
                    handleOpenBackDrop();
                  }}
                />
                <TrainingSection
                  content={selectedStep}
                  style={{ flex: 1 }}
                  update={() => {
                    handleSelectedStep(selectedStep);
                    handleOpenBackDrop();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Confirmation
        message="Cambios actualizados"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
    </>
  );
};

export default Formula;
