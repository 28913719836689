import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { pdfjs, Document, Page } from 'react-pdf';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import styles from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocViewer = ({ open, handleClose, document }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.ViewerContainer}
    >
      {document.type === 'application/pdf' ? (
        <>
          <Pagination
            count={numPages}
            value={pageNumber}
            onChange={(e, value) => setPageNumber(value)}
            sx={{ margin: 'auto', marginTop: '10px' }}
          />
          <Document
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
            file={document.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </>
      ) : (
        <object
          data={document.url}
          type={document.type}
          className={styles.ObjectProperties}
        >
          <p className={styles.ErrorFile}>
            Existe un problema con el formato del archivo.
          </p>
        </object>
      )}
      <Button
        variant="text"
        color="primary"
        className={styles.DownloadButton}
        href={document.url}
      >
        Descargar
      </Button>
    </Dialog>
  );
};

DocViewer.propTypes = {
  document: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default React.memo(DocViewer);
