import React, { useEffect, useState } from 'react';

import theme from '../../../theme';

import DescriptionCategory from './DescriptionCategory';
import CardContainer from '../CardContainer';
import MediaSection from './MediaSection';
import General from './General';

import { getMaterialKinsu, getCategoryMaterial } from './request';
import { categoriesMap } from './constants';

import styles from './index.module.scss';
import classnames from 'classnames';

const Material = () => {
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [mediaContent, setMediaContent] = useState({});
  const [header, setHeader] = useState({});

  useEffect(() => {
    if (selectedCategory) {
      getCategoryMaterial(selectedCategory.uuid).then(res =>
        setMediaContent(res.data),
      );
    }
  }, [selectedCategory]);

  useEffect(() => {
    getMaterialKinsu().then(res => {
      const filtered = res.data.filter(element => element.name !== 'HEADER');
      setCategories(filtered);
      const header = res.data.filter(element => element.name === 'HEADER');
      setHeader(header[0]);
    });
  }, []);

  return (
    <div className={styles.FormulaContainer}>
      <div className={styles.FormulaContent}>
        <div className={styles.Block}>
          <General value={header} />
        </div>
        <div className={styles.Block}>
          <CardContainer style={{ flex: 1 }} title="Categorías">
            <div className={styles.CategoryList}>
              {categories?.map(category => {
                const Foo = categoriesMap[category.name].icon;
                return (
                  <button
                    key={category.uuid}
                    className={classnames(
                      styles.CategoryButton,
                      selectedCategory?.uuid === category.uuid &&
                        styles.CategoryButtonSelected,
                    )}
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    <Foo
                      fontsize="24"
                      color={theme.palette.primary.main}
                      fontWeight="2"
                    />
                    {categoriesMap[category.name].title}
                  </button>
                );
              })}
            </div>
          </CardContainer>
        </div>
        {selectedCategory && mediaContent && (
          <div className={styles.Block}>
            <DescriptionCategory
              title={categoriesMap[selectedCategory.name].title}
              content={mediaContent}
              category={selectedCategory.uuid}
            />
            <MediaSection
              title={categoriesMap[selectedCategory.name].title}
              content={mediaContent}
              category={selectedCategory.uuid}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Material);
