/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function PaperPlaneRightIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.442 15.1274L6.32759 3.3034C6.14996 3.20392 5.9462 3.16104 5.74354 3.18047C5.54089 3.1999 5.34899 3.28072 5.1935 3.41214C5.03801 3.54355 4.92633 3.71929 4.87339 3.91587C4.82045 4.11246 4.82877 4.32051 4.89725 4.51224L8.87988 15.6636C8.95756 15.8811 8.95756 16.1188 8.87988 16.3363L4.89725 27.4876C4.82877 27.6794 4.82045 27.8874 4.87339 28.084C4.92633 28.2806 5.03801 28.4563 5.1935 28.5878C5.34899 28.7192 5.54089 28.8 5.74355 28.8194C5.9462 28.8389 6.14996 28.796 6.32759 28.6965L27.442 16.8725C27.597 16.7856 27.7262 16.659 27.8161 16.5056C27.906 16.3523 27.9533 16.1777 27.9533 15.9999C27.9533 15.8222 27.906 15.6476 27.8161 15.4943C27.7262 15.3409 27.597 15.2143 27.442 15.1274V15.1274Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 16H17"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

PaperPlaneRightIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

PaperPlaneRightIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(PaperPlaneRightIcon);
