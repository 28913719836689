import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Auth, Storage } from 'aws-amplify';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import ImageSquareIcon from '../../../components/Icons/ImageSquareIcon';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';
import LinkSimpleIcon from '../../../components/Icons/LinkSimpleIcon';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import TrashIcon from '../../../components/Icons/TrashIcon';

import { mediaValidation } from './validations';
import { branch } from '../../../constants';
import { handleTypes } from '../constants';

import styles from './MediaResource.module.scss';
import classnames from 'classnames';
import { putStepsFormulaById } from './request';

const MediaResource = ({ resource, contentId, onDelete, update }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [key, setKey] = useState(null);
  const [progress, setProgress] = useState(0);
  const [keyMiniature, setKeyMiniature] = useState(null);
  const [activeInput, setActiveInput] = useState(null);

  const form = useFormik({
    initialValues: {
      miniature: resource.miniature,
      summary: resource.summary,
      source: resource.source,
      title: resource.title,
      type: resource.type,
    },
    validate: mediaValidation,
    onSubmit: values => handleSubmit(values),
  });

  const handleSubmit = values => {
    const payload = { material: { ...values, uuid: resource.uuid } };
    if (key) {
      payload.material.key = key;
      delete payload.material.source;
    }
    if (keyMiniature) {
      payload.material.keyMiniature = keyMiniature;
      delete payload.material.miniature;
    }
    setLoading(true);
    putStepsFormulaById(contentId, payload).then(res => {
      if (!res.notifications.requestMessage) {
        update();
        closeEditButtons();
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setProgress(0);
      setEdit(false);
      form.resetForm();
    });
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleUpload = async event => {
    const validate = await form.validateForm();
    if (Object.keys(validate).length === 0) {
      setLoading(true);
      const file = event.target.files[0];
      const mimetype = file.type;
      let ext = mimetype.split('/')[1];
      if (ext === 'quicktime') {
        ext = 'mov';
      }
      try {
        Auth.currentAuthenticatedUser({
          bypassCache: true,
        }).then(async () => {
          const result = await Storage.put(
            `${branch}/${contentId}/${resource.uuid}.${ext}`,
            file,
            {
              progressCallback,
            },
          );
          if (event.target.name === 'miniature') {
            setKeyMiniature(result.key);
            return null;
          }
          setKey(result.key);
        });
      } catch (error) {
        console.info(error);
      }
    } else {
      alert('Se requere un título y descripción para subir un nuevo recurso');
      event.target.value = null;
    }
  };

  const closeEditButtons = () => {
    form.resetForm();
    setKey(null);
    setKeyMiniature(null);
    setProgress(0);
    setEdit(false);
  };

  useEffect(() => {
    if (keyMiniature) {
      setLoading(false);
      setProgress(0);
    }
    if (key) {
      const payload = {
        key,
      };
      if (keyMiniature) {
        payload.keyMiniature = keyMiniature;
      }
      handleSubmit({ ...form.values, ...payload });
      setKeyMiniature(null);
      setActiveInput(null);
      setKey(null);
    }
  }, [key, keyMiniature]);

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <>
      <div className={styles.ResourceContainerOff}>
        <div className={styles.ResourceHeader}>
          <Typography
            variant="body1"
            fontWeight={400}
            className={styles.TextOff}
          >
            {resource.title}
          </Typography>
          <span>
            <IconButton onClick={handleEdit}>
              <NotePencilIcon color="#4575ba" fontSize="24" fontWeight="2" />
            </IconButton>
            <IconButton onClick={handleOpenConfirm}>
              <TrashIcon color="#919497" fontSize="24" fontWeight="2" />
            </IconButton>
          </span>
        </div>
        {!edit ? (
          <>
            <Typography variant="body1" className={styles.TextOff} gutterBottom>
              {resource.summary}
            </Typography>
            {resource.miniature && (
              <Typography
                variant="body1"
                component="a"
                href={resource.miniature}
                target="_blank"
                className={classnames(styles.TextOff, styles.ReadUrl)}
              >
                <ImageSquareIcon fontSize="24" color="#919497" />{' '}
                {resource.miniature}
              </Typography>
            )}
            <Typography
              variant="body1"
              component="a"
              href={resource.source}
              target="_blank"
              className={classnames(styles.TextOff, styles.ReadUrl)}
            >
              <LinkSimpleIcon fontSize="24" color="#919497" /> {resource.source}
            </Typography>
          </>
        ) : (
          <form onSubmit={form.handleSubmit}>
            <TextField
              id="title"
              name="title"
              label="Título"
              value={form.values.title}
              onChange={form.handleChange}
              margin="normal"
              size="small"
              error={form.touched.title && form.errors.title}
              helperText={form.touched.title && form.errors.title}
            />
            <TextField
              id="summary"
              name="summary"
              label="Descripción"
              value={form.values.summary}
              onChange={form.handleChange}
              margin="normal"
              size="small"
              error={form.touched.summary && form.errors.summary}
              helperText={form.touched.summary && form.errors.summary}
            />
            {keyMiniature && (
              <Typography
                variant="subtitle1"
                color="success.main"
                component="div"
                className={styles.CheckText}
              >
                Miniatura lista.
                <CheckCircleIcon fontSize="24" color="#2e7d32" fontWeight="2" />
              </Typography>
            )}
            {form.values.type === 'video' && !keyMiniature && (
              <>
                {progress > 0 && activeInput === 'miniature' ? (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ marginTop: '25px', marginBottom: '10px' }}
                  />
                ) : (
                  <LoadingButton
                    fullWidth
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: '15px' }}
                    loading={loading}
                  >
                    <input
                      type="file"
                      name="miniature"
                      hidden
                      onChange={e => {
                        setActiveInput(e.target.name);
                        handleUpload(e);
                      }}
                      accept={handleTypes('img')}
                    />
                    Selectionar miniatura
                  </LoadingButton>
                )}
              </>
            )}
            {progress > 0 && activeInput === 'file' ? (
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ marginTop: '25px', marginBottom: '10px' }}
              />
            ) : (
              <LoadingButton
                fullWidth
                variant="outlined"
                component="label"
                sx={{ marginTop: '15px' }}
                loading={loading}
              >
                <input
                  type="file"
                  name="file"
                  hidden
                  onChange={e => {
                    setActiveInput(e.target.name);
                    handleUpload(e);
                  }}
                  accept={handleTypes(form.values.type)}
                />
                Selectionar archivo
              </LoadingButton>
            )}

            <div className={styles.ActionButtons}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                Guardar cambios
              </LoadingButton>
              <Button
                variant="outlined"
                color="primary"
                onClick={closeEditButtons}
              >
                Cancelar
              </Button>
            </div>
          </form>
        )}
      </div>
      <Dialog open={openConfirm} onClose={handleOpenConfirm}>
        <div className={styles.ConfirmModal}>
          <Typography variant="h6" component="p">
            ¿Está seguro de eliminar este recurso?
          </Typography>
          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => {
                onDelete(resource.uuid);
                setLoading(false);
                form.resetForm();
              }}
              sx={{ flex: 1 }}
            >
              Aceptar
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenConfirm}
              sx={{ flex: 1 }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

MediaResource.propTypes = {
  contentId: PropTypes.string,
  onDelete: PropTypes.func,
  resource: PropTypes.any,
  update: PropTypes.func,
};

export default React.memo(MediaResource);
