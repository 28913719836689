import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext();
export const initialUserState = {
  attributes: {},
  username: '',
};

const SessionContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(initialUserState);

  return (
    <Context.Provider value={{ userData, setUserData }}>
      {children}
    </Context.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.element,
};

export default SessionContextProvider;
