import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';

import QuestionIcon from '../../components/Icons/QuestionIcon';

import styles from './CardContainer.module.scss';

const CardContainer = ({
  style,
  title,
  children,
  action,
  modalChild,
  icon,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.CardContainer} style={style}>
        {title && (
          <div className={styles.Header}>
            <Typography variant="h5" color="primary" fontWeight="400">
              {title}
              {modalChild && (
                <IconButton
                  aria-label={title}
                  onClick={handleOpenDialog}
                  sx={{ marginLeft: '15px' }}
                >
                  <QuestionIcon color="#919497" fontSize="28" />
                </IconButton>
              )}
            </Typography>
            {!disabled && action && (
              <IconButton aria-label={title} onClick={action}>
                {icon}
              </IconButton>
            )}
          </div>
        )}
        <div className={styles.Forms}>{children}</div>
      </div>
      {modalChild && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleOpenDialog}
        >
          {modalChild}
        </Backdrop>
      )}
    </>
  );
};

CardContainer.propTypes = {
  action: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  modalChild: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
};

export default React.memo(CardContainer);
