import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext();
export const initialLeadsState = {
  details: {},
  quotationData: {
    paymentType: 'MONTHLY',
    coverage: '1',
  },
  catalogues: {},
  recalculate: false,
  insurantsUpdates: {},
};

const SessionContextProvider = ({ children }) => {
  const [contextData, setContextData] = useState(initialLeadsState);

  const handleSetContextData = data => {
    setContextData({
      ...contextData,
      ...data,
    });
  };

  return (
    <Context.Provider
      value={{ contextData, setContextData: handleSetContextData }}
    >
      {children}
    </Context.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.element,
};

export default SessionContextProvider;
