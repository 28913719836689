/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Pagination,
  InputAdornment,
  Typography,
} from '@mui/material';

import MagnifyingGlass from '../../../components/Icons/MagnifyingGlassIcon';
import Loading from '../../../components/Loading';
import KinsurersList from './KinsurersList';
import { getListKinsurers } from './request';
import { validations } from './validations';

import styles from './index.module.scss';

const KinsurerList = () => {
  const [data, setData] = useState([]);
  const [nextKinsurer, setNextKinsurer] = useState(null);
  const [count, setCount] = useState(0);
  const [clearCount, setClearCount] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rows, setRows] = useState([]);
  const tableRows = 25;

  const form = useFormik({
    initialValues: {
      search: '',
    },
    validate: validations,
  });

  const handleUpdateData = values => {
    if (!values) {
      getListKinsurers().then(res => {
        setData(res.data);
        setNextKinsurer(res.data?.pagination?.nextItem?.uuid);
      });
    } else {
      if (nextKinsurer) {
        getListKinsurers(values, nextKinsurer).then(res => {
          setData([...data, res.data]);
          setNextKinsurer(res.data?.pagination?.nextItem?.uuid);
        });
      } else {
        getListKinsurers(values).then(res => {
          setClearCount(true);
          setData(res.data);
          setNextKinsurer(res.data?.pagination?.nextItem?.uuid);
        });
      }
    }
  };

  const keyPress = e => {
    if (e.code === 'Enter') {
      form.setFieldTouched(e.target.name);
      handleUpdateData(form.values);
    }
  };

  const handleChangePage = value => {
    if (value > count) {
      handleUpdateData(null, nextKinsurer);
      setPageNumber(value);
    } else {
      setPageNumber(value);
    }
  };

  const handleReset = () => {
    form.resetForm();
    handleUpdateData();
    setClearCount(true);
  };

  useEffect(() => {
    handleUpdateData();
  }, []);

  useEffect(() => {
    if (data) {
      let pages = data.length / tableRows;
      if (!Number.isInteger(pages)) {
        pages = Math.floor(pages) + 1;
      }
      if (clearCount) {
        setCount(pages);
        setClearCount(false);
      } else {
        setCount(count + pages);
      }
    }
  }, [data]);

  useEffect(() => {
    let firstOne = 0;
    let lastOne = tableRows;
    const rowsArray = [];
    if (data && data.length >= 1) {
      for (let i = 0; i < count; i++) {
        const rowsPage = data.slice(firstOne, lastOne);
        rowsArray.push([...rowsPage]);
        firstOne = lastOne;
        lastOne += tableRows;
        if (lastOne > data.length) {
          lastOne = data.length;
        }
      }
      setRows(rowsArray);
    }
  }, [data, count]);

  return (
    <div className={styles.HomeContainer}>
      <div className={styles.SearchBar}>
        <Typography variant="h4" color="primary">
          Kinsurers
        </Typography>
        <TextField
          id="search"
          name="search"
          label="Buscar"
          size="small"
          value={form.values.search}
          sx={{ maxWidth: '370px', position: 'relative', top: '20px' }}
          onChange={e => {
            form.handleChange(e);
            if (e.target.value === '') {
              handleReset();
            }
          }}
          onKeyDown={e => keyPress(e)}
          onBlur={form.handleBlur}
          error={form.touched.search && form.errors.search}
          helperText={form.touched.search && form.errors.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MagnifyingGlass fontSize="16" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {!data && <Loading flex white />}
      {data && rows.length > 0 && (
        <div className={styles.KinsurersTable}>
          <KinsurersList rows={rows[pageNumber - 1]} />
          {count > 1 && (
            <Pagination
              count={count + 1}
              value={pageNumber}
              onChange={(e, value) => handleChangePage(value)}
              className={styles.PaginationPosition}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(KinsurerList);
