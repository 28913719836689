import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Button } from '@mui/material';

import man from '../../../assets/images/man.png';
import woman from '../../../assets/images/woman.png';
import Select from '../../../components/Inputs/Select';
import DatePicker from '../../../components/Inputs/DatePicker';
import RadioCardInput from '../../../components/Inputs/RadioCardInput';

import { Context } from '../context';
import { getCoverages } from './request';

import styles from './ConfigPanel.module.scss';

function ConfigPanel() {
  const { contextData, setContextData } = useContext(Context);
  const { quotationData } = contextData;

  const [coveragesData, setCoveragesData] = useState([]);

  const form = useFormik({
    initialValues: {
      paymentType: quotationData.paymentType,
      coverage: quotationData.coverage,
      gender: quotationData.gender,
      postalCode: quotationData.postalCode,
      dateOfBirth: quotationData.dateOfBirth,
    },
    // validate: TrainingValidation,
    onSubmit: values => {
      setContextData({
        quotationData: {
          ...contextData.quotationData,
          ...values,
        },
      });
    },
  });

  const handleGetCoverages = () => {
    getCoverages().then(({ data }) => {
      setCoveragesData(data);
    });
  };

  useEffect(() => {
    handleGetCoverages();
  }, []);

  return (
    <form onSubmit={form.handleSubmit}>
      <Select
        name="paymentType"
        size="small"
        margin="normal"
        label="Forma de pago"
        value={form.values.paymentType}
        onChange={form.handleChange}
      >
        <MenuItem value="MONTHLY">MENSUAL</MenuItem>
        <MenuItem value="QUARTERLY">TRIMESTRAL</MenuItem>
        <MenuItem value="SEMI-ANNUAL">SEMESTRAL</MenuItem>
        <MenuItem value="ANNUAL">CONTADO</MenuItem>
      </Select>

      <Select
        name="coverage"
        size="small"
        margin="normal"
        label="Forma de pago"
        value={form.values.coverage}
        onChange={form.handleChange}
      >
        {coveragesData.map(coverage => (
          <MenuItem key={coverage.id} value={coverage.id}>
            {coverage.description}
          </MenuItem>
        ))}
      </Select>

      <FormControl component="fieldset" className={styles.RadioCardFieldset}>
        <FormLabel component="legend">Indica tu género</FormLabel>
        <RadioGroup
          aria-label="gender"
          className={styles.RadioCardGroup}
          onChange={form.handleChange}
          name="gender"
          value={form.values.gender}
        >
          <RadioCardInput value="FEMALE" label="Femenino" icon={woman} />
          <RadioCardInput value="MALE" label="Masculino" icon={man} />
        </RadioGroup>
      </FormControl>

      <DatePicker
        name="dateOfBirth"
        label="Fecha de nacimiento"
        size="small"
        margin="normal"
        onChange={form.handleChange}
        value={form.values.dateOfBirth}
        error={form.errors.dateOfBirth && form.touched.dateOfBirth}
        helperText={form.errors.dateOfBirth && form.touched.dateOfBirth}
      />

      <TextField
        name="postalCode"
        label="Código postal"
        size="small"
        margin="normal"
        onChange={form.handleChange}
        value={form.values.postalCode}
        error={form.errors.postalCode && form.touched.postalCode}
        helperText={form.errors.postalCode && form.touched.postalCode}
      />

      <Button variant="contained" fullWidth type="submit">
        Aplicar
      </Button>
    </form>
  );
}

ConfigPanel.propTypes = {
  onSubmit: PropTypes.func,
};

export default React.memo(ConfigPanel);
