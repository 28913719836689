/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function CloudArrowUpIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 25.998H9.00003C8.00733 25.9971 7.02618 25.785 6.12172 25.3758C5.21725 24.9667 4.41016 24.3698 3.754 23.6249C3.09785 22.88 2.60764 22.004 2.31591 21.0551C2.02419 20.1063 1.93762 19.1062 2.06196 18.1213C2.1863 17.1364 2.51869 16.1892 3.03708 15.3426C3.55548 14.496 4.24801 13.7694 5.06871 13.2109C5.88942 12.6524 6.81952 12.2749 7.79729 12.1033C8.77507 11.9318 9.77814 11.9702 10.7399 12.216"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15.998C10.0002 14.4136 10.3768 12.8518 11.0989 11.4415C11.821 10.0312 12.868 8.81262 14.1534 7.88625C15.4388 6.95988 16.926 6.35221 18.4923 6.11333C20.0587 5.87445 21.6594 6.01118 23.1625 6.51227C24.6656 7.01335 26.0282 7.86444 27.1379 8.99541C28.2476 10.1264 29.0726 11.5048 29.5451 13.0172C30.0175 14.5296 30.1239 16.1326 29.8553 17.6941C29.5867 19.2557 28.9509 20.731 28.0003 21.9986"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7573 20.2408L18.9999 15.998L23.2426 20.2408"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 25.998V15.998"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

CloudArrowUpIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

CloudArrowUpIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(CloudArrowUpIcon);
