export const validations = values => {
  const errors = {};
  if (values.contactResult === '') {
    errors.contactResult = 'Campo requerido';
  }
  if (values.reason === '') {
    errors.reason = 'Campo requerido';
  }
  return errors;
};
