import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import Loading from '../Loading';

import styles from './index.module.scss';
import classNames from 'classnames';

const GroupSelector = ({
  style,
  label,
  bgColor,
  position = 'vertical',
  error,
  helperText,
  name,
  onChange,
  groups,
  checked,
  valueProperty = 'PK',
  unControlled = false,
  ...other
}) => {
  if (groups.length === 0) {
    return (
      <div className={styles.Border} style={style}>
        <Loading flex white />
      </div>
    );
  }

  return (
    <div className={styles.Border} style={style}>
      <div
        className={classNames(
          styles.SelectorContainer,
          position === 'horizontal' && styles.SelectorContainerHorizontal,
          error && styles.ErrorContainer,
        )}
      >
        <label style={{ backgroundColor: bgColor }}>{label}</label>
        <FormGroup error={error} name={name}>
          {groups?.map((element, index) => {
            return (
              <Tooltip
                key={`${element.name}-${index}`}
                title={element.description}
                placement="right"
                arrow
              >
                <FormControlLabel
                  control={
                    !unControlled ? (
                      <Checkbox
                        name={name}
                        defaultChecked={checked.includes(
                          element[valueProperty],
                        )}
                        value={element[valueProperty]}
                      />
                    ) : (
                      <Checkbox
                        name={name}
                        value={element[valueProperty]}
                        checked={checked.includes(element[valueProperty])}
                      />
                    )
                  }
                  label={element.name}
                  onChange={onChange}
                  {...other}
                />
              </Tooltip>
            );
          })}
        </FormGroup>
      </div>
      {error && <span>{helperText}</span>}
    </div>
  );
};

GroupSelector.propTypes = {
  bgColor: PropTypes.string,
  checked: PropTypes.array,
  error: PropTypes.string,
  groups: PropTypes.array,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  other: PropTypes.any,
  position: PropTypes.oneOf(['vertical', 'horizontal']),
  style: PropTypes.object,
  unControlled: PropTypes.bool,
  valueProperty: PropTypes.string,
};

export default React.memo(GroupSelector);
