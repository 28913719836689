import React from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';

import styles from './InsurantConfiguration.module.scss';
import CarInsurance from '../../../assets/images/car-insurance.png';

function GeneralCoverages() {
  return (
    <div className={styles.container}>
      <div className={styles.itemHeader}>
        <img src={CarInsurance} className={styles.icon} />
        <Typography variant="caption">Caracteristicas incluidas</Typography>
      </div>
      <div className={classnames(styles.item, styles.itemExtra)} />
      <Typography className={styles.item}>Primer pago</Typography>
      <Typography className={styles.item}>Pago total</Typography>
      <Typography className={styles.item}>Daños Materiales</Typography>
      <Typography className={styles.item}>Robo Total</Typography>
      <Typography className={styles.item}>Gastos Médicos</Typography>
      <Typography className={styles.item}>Responsabilidad Civil</Typography>
      <Typography className={styles.item}>
        RC a Terceros en sus bienes
      </Typography>
      <Typography className={styles.item}>
        RC a Terceros en sus personas
      </Typography>
      <Typography className={styles.item}>Asistencia Completa</Typography>
      <Typography className={styles.item}>Defensa Jurídica</Typography>
      <Typography className={styles.item}>Accidentes al conductor</Typography>
      <Typography className={styles.item}>
        RC catastrófica por muerte accidental
      </Typography>
      <Typography className={styles.item}>Robo Parcial</Typography>
      <Typography className={styles.item}>Auto Sustituto</Typography>
      <Typography className={styles.item}>
        No deducible por PT por DM
      </Typography>
      <Typography className={styles.item}>
        No deducible por PP por DM
      </Typography>
      <Typography className={styles.item}>Llantas</Typography>
    </div>
  );
}

export default React.memo(GeneralCoverages);
