export const isEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const isUUIDv4 = uuid => {
  const uuidv4 = String(uuid).match(
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  );
  const uuidv4JustNumbers = String(uuid).match(
    /^[0-9A-F]{8}[0-9A-F]{4}4[0-9A-F]{3}[89AB][0-9A-F]{3}[0-9A-F]{12}$/i,
  );
  return uuidv4 || uuidv4JustNumbers;
};

export const isCURP = curp => {
  const mexican = String(curp)
    .toUpperCase()
    .match(
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    );
  return mexican;
};

export const isCLABE = clabe => {
  const valid = clabe.match(/^([0-9 ]{21})$/);
  return valid;
};

export const isPHONE = number => {
  const valid = number.match(
    /^([+]\d{1,2}[-\s]?)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/,
  );
  return valid;
};
