/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function QrCodeIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className} data-testid="QrCodeIcon-Test">
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0002 6H7.00024C6.44796 6 6.00024 6.44772 6.00024 7V13C6.00024 13.5523 6.44796 14 7.00024 14H13.0002C13.5525 14 14.0002 13.5523 14.0002 13V7C14.0002 6.44772 13.5525 6 13.0002 6Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0002 18H7.00024C6.44796 18 6.00024 18.4477 6.00024 19V25C6.00024 25.5523 6.44796 26 7.00024 26H13.0002C13.5525 26 14.0002 25.5523 14.0002 25V19C14.0002 18.4477 13.5525 18 13.0002 18Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0002 6H19.0002C18.448 6 18.0002 6.44772 18.0002 7V13C18.0002 13.5523 18.448 14 19.0002 14H25.0002C25.5525 14 26.0002 13.5523 26.0002 13V7C26.0002 6.44772 25.5525 6 25.0002 6Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0002 18V22"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0002 26H22.0002V18"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0002 20H26.0002"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.0002 24V26"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

QrCodeIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

QrCodeIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(QrCodeIcon);
