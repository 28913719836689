import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getKinsurerDetails = uuid => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}`);
};

export const getKinsurerActivationTracking = uuid => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/activationTracking`);
};

export const postKinsurerActivationTracking = (values, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/activationTracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: values.userId,
      contactType: values.contactType,
      isSuccessfulContact: values.isSuccessfulContact,
      contactResult: values.contactResult,
      isInterested: values.isInterested,
      reason: values.reason,
      isSuccessfulActivation: values.isSuccessfulActivation,
      comments: values.comments,
      createdDate: values.createdDate,
    }),
  });
};

export const getKinsurerComments = uuid => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/comments`);
};

export const postKinsurerComments = (values, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: values.userId, comment: values.comment }),
  });
};

export const putKinsurerComments = (values, idComment, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/comments`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: values.userId,
      comment: values.comment,
      editionDate: values.createdDate,
      idComment,
    }),
  });
};

export const deleteKinsurerComments = (idComment, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/comments`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idComment,
    }),
  });
};
