import { urlBase } from '../../../../constants';
import request from '../../../../utils/request';

export function AddNewContent({ values, html }) {
  return request(`${urlBase}/jarvis/content`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      category: 'TyC',
      content: html,
      customPath: values.customPath,
      title: values.title,
      status: values.status,
    }),
  });
}

export function updateContent({ values, html, uuid }) {
  return request(`${urlBase}/jarvis/content/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: html,
      customPath: values.customPath,
      title: values.title,
      description: values.description,
      effectiveDate: values.effectiveDate,
      status: values.status,
    }),
  });
}
