import React, { useContext, useEffect, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Auth, Storage } from 'aws-amplify';
import moment from 'moment';
import 'moment/locale/es';

import identification from '../../../assets/images/identification.png';
import Confirmation from '../../../components/Confirmation';
import home from '../../../assets/images/homeSimple.png';
import DocViewer from '../../../components/DocViewer';
import Loading from '../../../components/Loading';
import Header from '../../../components/Header';
import Eye from '../../../components/Icons/Eye';

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { getKinsurerFilesOps, putKinsurerFilesOps } from './request';
import { Context } from '../../../context/SessionContext';
import { kinsurerDocsBucket } from '../../../constants';

import styles from './index.module.scss';

const icons = {
  home,
  credential: identification,
};
const translate = {
  FRONT_SIDE: 'Frente',
  BACK_SIDE: 'Reverso',
};

const getMimeType = url => {
  if (url) {
    const res = url.split('.');
    switch (res[res.length - 1]) {
      case 'pdf' || 'PDF':
        return 'application/pdf';
      case 'jpeg' || 'jpg' || 'JPEG' || 'JPG':
        return 'image/jpeg';
      case 'png' || 'PNG':
        return 'image/png';
      case 'heic' || 'HEIC' || 'heif' || 'HEIF':
        return 'image/heic';
      case 'doc' || 'docx':
        return 'application/msword';
      default:
        return 'application/octet-stream';
    }
  } else {
    return false;
  }
};

const UploadDocuments = () => {
  const { uuid } = useParams();
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [selectedDocView, setSelectedDocView] = useState({});
  const { userData } = useContext(Context);
  moment.locale('es');

  async function handleGetFiles() {
    const res = await getKinsurerFilesOps(uuid);
    setFiles(res.data?.documents);
  }

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleSetOpenDoc = () => {
    setOpenDoc(!openDoc);
  };

  const handleSetSelectedDocView = (url, type, name) => {
    setSelectedDocView({ url, type, name });
    handleSetOpenDoc();
  };

  const handleUpload = async (event, docId, type, name) => {
    setLoading(true);
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(event.target.files[0]);
    const ext = event.target.files[0].type;
    fileReader.onload = async () => {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(() => {
        Storage.put(
          `${uuid}/${type}/${name}.${ext.split('/')[1]}`,
          fileReader.result,
          kinsurerDocsBucket,
        ).then(key => {
          putKinsurerFilesOps(
            uuid,
            docId,
            userData.username,
            key.key,
            type,
            name,
          ).then(res => {
            if (!res.notifications.requestCode) {
              handleOpenBackDrop();
              setFiles(res.data?.documents);
            }
            setLoading(false);
          });
        });
      });
    };
  };

  useEffect(() => {
    handleGetFiles();
  }, []);

  if (!files) {
    return <Loading white />;
  }

  return (
    <div className={styles.UploadDocumentsContainer}>
      <Header title="Expediente del Kinsurer" onBack />
      <div className={styles.Accordion}>
        {files.length > 0 &&
          files.map((element, index) => (
            <Accordion
              defaultExpanded={true}
              disableGutters={true}
              key={element.id}
              className={styles.Accordion}
              sx={
                !(index % 2)
                  ? { background: '#f4f4f4', boxShadow: 0 }
                  : { background: '#fBfBfB', boxShadow: 0 }
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={styles.DocHeader}>
                  <img src={icons[element.uiConfig.iconKey]} alt="file" />
                  <Typography variant="h5">{element.type}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {element?.files.map(file => {
                  const date = moment(new Date(file?.createdDate)).format(
                    'dddd, DD-MM-YYYY',
                  );
                  const hour = moment(new Date(file?.createdDate)).format(
                    'h:mm a',
                  );
                  const mimetype = getMimeType(file?.source);
                  return (
                    <div key={file.name} className={styles.DocBody}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {translate[file.name]}
                      </Typography>
                      {file.status === 'PENDING_TO_UPLOAD' ? (
                        <LoadingButton
                          variant="contained"
                          loading={loading}
                          component="label"
                        >
                          <input
                            type="file"
                            name="file"
                            hidden
                            onChange={event =>
                              handleUpload(
                                event,
                                element.id,
                                element.type,
                                file.name,
                              )
                            }
                            accept="image/jpeg,image/gif,image/png,image/x-eps,image/heic,image/heif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                          {file.uiConfig.title}
                        </LoadingButton>
                      ) : (
                        <>
                          <Typography variant="body2">
                            {capitalizeFirstLetter(date)}
                          </Typography>
                          <Typography variant="body2">{hour}</Typography>
                          <Tooltip title="Ver Documento">
                            <IconButton
                              onClick={() =>
                                handleSetSelectedDocView(
                                  file.source,
                                  mimetype,
                                  file.name,
                                )
                              }
                            >
                              <Eye fontSize="32" color="#919497" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
      <Confirmation
        message="Documento guardado correctamente"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
      <DocViewer
        open={openDoc}
        handleClose={handleSetOpenDoc}
        document={selectedDocView}
      />
    </div>
  );
};

export default React.memo(UploadDocuments);
