import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import moment from 'moment';
import 'moment/locale/es';

import identification from '../../../assets/images/identification.png';
import home from '../../../assets/images/homeSimple.png';
import Eye from '../../../components/Icons/Eye';

import styles from './File.module.scss';

function FileInput({
  icon,
  title,
  files,
  documentId,
  handleSelected,
  handleViewDoc,
}) {
  moment.locale('es');
  const icons = {
    home,
    credential: identification,
  };

  const getMimeType = url => {
    const res = url.split('.');
    switch (res[res.length - 1]) {
      case 'pdf' || 'PDF':
        return 'application/pdf';
      case 'jpeg' || 'jpg' || 'JPEG' || 'JPG':
        return 'image/jpeg';
      case 'png' || 'PNG':
        return 'image/png';
      case 'heic' || 'HEIC' || 'heif' || 'HEIF':
        return 'image/heic';
      default:
        return 'application/octet-stream';
    }
  };

  const checkDisable = (status, type) => {
    if (type === 'APPROVED') {
      if (
        status === 'REJECTED' ||
        status === 'APPROVED' ||
        status === 'PENDING_TO_UPLOAD'
      ) {
        return true;
      }
    }
    if (type === 'REJECTED') {
      if (status === 'REJECTED' || status === 'PENDING_TO_UPLOAD') {
        return true;
      }
    }
  };

  return (
    <div className={styles.DocumentCard}>
      <div>
        <img src={icons[icon]} alt={icons[icon]} />
      </div>
      <div>
        <Typography
          variant="body1"
          component="div"
          className={styles.DocumentCardTitle}
        >
          {title}
        </Typography>
        {files?.map(
          ({ name, uploadedFile, uploadDate, status, reasonForRejection }) => {
            let mimetype = '';
            if (uploadedFile) {
              mimetype = getMimeType(uploadedFile);
            }
            return (
              <Fragment key={name}>
                <div className={styles.OpenDoc}>
                  <Typography variant="body2" component="div">
                    {name === 'FRONT_SIDE' ? 'Frente' : 'Reverso'}
                  </Typography>

                  {uploadedFile && mimetype !== '' ? (
                    <Tooltip title="Ver Documento">
                      <IconButton
                        onClick={() => handleViewDoc(uploadedFile, mimetype, name)}
                      >
                        <Eye fontSize="32" color="#919497" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Typography variant="body2" color="warning.main">
                      No disponible
                    </Typography>
                  )}
                </div>
                <div className={styles.StatusButtons}>
                  <Button
                    variant="outlined"
                    color="warning"
                    disabled={checkDisable(status, 'REJECTED')}
                    onClick={() => handleSelected('REJECTED', documentId, name)}
                  >
                    Rechazar
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    disabled={checkDisable(status, 'APPROVED')}
                    onClick={() => handleSelected('APPROVED', documentId, name)}
                  >
                    Aprobar
                  </Button>
                </div>
                {uploadDate && (
                  <Typography
                    variant="body2"
                    component="div"
                    color="#919497"
                    className={styles.Band}
                  >
                    <Typography
                      variant="body2"
                      color="#919497"
                      sx={{ marginRight: '5px' }}
                    >
                      Última modificación:
                    </Typography>
                    {moment(new Date(uploadDate)).format('DD-MM-YYYY')}
                  </Typography>
                )}
                {status === 'REJECTED' && (
                  <Typography
                    variant="body2"
                    component="div"
                    className={styles.WarningBand}
                  >
                    <Typography
                      variant="body2"
                      color="error.main"
                      sx={{ marginRight: '5px' }}
                    >
                      Razón
                    </Typography>
                    {reasonForRejection}
                  </Typography>
                )}
              </Fragment>
            );
          },
        )}
      </div>
    </div>
  );
}

FileInput.propTypes = {
  documentId: PropTypes.any,
  files: PropTypes.array,
  handleSelected: PropTypes.func,
  handleViewDoc: PropTypes.func,
  icon: PropTypes.any,
  title: PropTypes.any,
};

export default React.memo(FileInput);
