/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function QuestionIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0001 28C22.6275 28 28.0001 22.6274 28.0001 16C28.0001 9.37258 22.6275 4 16.0001 4C9.3727 4 4.00012 9.37258 4.00012 16C4.00012 22.6274 9.3727 28 16.0001 28Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0001 19V18C16.7912 18 17.5646 17.7654 18.2224 17.3259C18.8802 16.8864 19.3929 16.2616 19.6956 15.5307C19.9984 14.7998 20.0776 13.9956 19.9233 13.2196C19.7689 12.4437 19.388 11.731 18.8285 11.1716C18.2691 10.6122 17.5564 10.2312 16.7805 10.0769C16.0046 9.92252 15.2003 10.0017 14.4694 10.3045C13.7385 10.6072 13.1138 11.1199 12.6742 11.7777C12.2347 12.4355 12.0001 13.2089 12.0001 14"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 24.25C16.6904 24.25 17.25 23.6904 17.25 23C17.25 22.3096 16.6904 21.75 16 21.75C15.3096 21.75 14.75 22.3096 14.75 23C14.75 23.6904 15.3096 24.25 16 24.25Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

QuestionIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

QuestionIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(QuestionIcon);
