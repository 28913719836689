export const generalValidation = values => {
  const errors = {};
  if (values.description === '') {
    errors.description = 'No puede quedarse vacio';
  }
  return errors;
};

export const mediaValidation = values => {
  const errors = {};
  if (values.summary === '') {
    errors.summary = 'No puede quedarse vacio';
  }
  if (values.title === '') {
    errors.title = 'No puede quedarse vacio';
  }
  return errors;
};
