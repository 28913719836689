/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function LinkSimpleIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99829 15.999H21.9983"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9974 21.9995H7.99744C6.40614 21.9995 4.88001 21.3674 3.7548 20.2422C2.62958 19.1169 1.99744 17.5908 1.99744 15.9995C1.99744 14.4082 2.62958 12.8821 3.7548 11.7569C4.88001 10.6317 6.40614 9.99951 7.99744 9.99951H12.9974"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9983 21.9995H23.9983C25.5896 21.9995 27.1157 21.3674 28.2409 20.2422C29.3662 19.1169 29.9983 17.5908 29.9983 15.9995C29.9983 14.4082 29.3662 12.8821 28.2409 11.7569C27.1157 10.6317 25.5896 9.99951 23.9983 9.99951H18.9983"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

LinkSimpleIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

LinkSimpleIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(LinkSimpleIcon);
