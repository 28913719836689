import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getMaterialKinsu = () => {
  return request(`${urlBase}/jarvis/material/categories`);
};

export const getCategoryMaterial = categoryId => {
  return request(`${urlBase}/jarvis/categories/${categoryId}/material`);
};

export const putCategoryMaterial = (categoryId, values) => {
  return request(`${urlBase}/jarvis/categories/${categoryId}/material`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
};

export const deleteCategoryMaterial = contentId => {
  return request(`${urlBase}/jarvis/categories/material/${contentId}`, {
    method: 'DELETE',
  });
};
