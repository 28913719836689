export const categories = {
  CAR: 'Autos',
  HEALTH: 'Salud',
  HOME: 'Hogar',
  GENERAL: 'General',
};

export const mediaTypes = {
  img: 'Imagen',
  video: 'Video',
};

export const handleTypes = media => {
  switch (media) {
    case 'video':
      return 'video/quicktime,video/mp4';
    case 'img':
      return 'image/jpeg,image/gif,image/png,image/x-eps,image/heic,image/heif';
    default:
      return 'video/quicktime,video/mp4';
  }
};
