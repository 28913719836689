import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Authorization from '../../components/Authorization';
import TabPanel from './TabPanel';

const Multimedia = () => {
  return (
    <Routes>
      <Route element={<Authorization access="multimedia" white />}>
        <Route path="/:tabSelected/*" element={<TabPanel />} />
      </Route>
    </Routes>
  );
};

export default React.memo(Multimedia);
