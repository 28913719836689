import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import moment from 'moment';
import 'moment/locale/es';

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import CaretRightIcon from '../../../components/Icons/CaretRightIcon';
import { statusOptions } from '../constants';
import { getTyC } from './request';

import styles from './index.module.scss';

const TyCTab = () => {
  const navigate = useNavigate();
  const [TyCData, setTyCData] = useState([]);

  useEffect(() => {
    getTyC().then(({ data }) => {
      setTyCData(data);
    });
  }, []);

  return (
    <div className={styles.IncentivesTab}>
      {TyCData && (
        <div className={styles.IncentivesTable}>
          <Table size="small" aria-label="simple table">
            <TableHead className={styles.TableHead}>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Creado</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TyCData.map(row => {
                const date = moment(new Date(row.createdDate)).format(
                  'dddd, DD-MM-YYYY',
                );
                const hour = moment(new Date(row.createdDate)).format('h:mm a');
                return (
                  <TableRow key={row.uuid}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {capitalizeFirstLetter(date)}
                      </Typography>
                      <Typography variant="body2">{hour}</Typography>
                    </TableCell>
                    <TableCell>{statusOptions[row.status]}</TableCell>
                    <TableCell
                      className={styles.Actions}
                      onClick={() =>
                        navigate('details', {
                          state: {
                            content: row,
                          },
                        })
                      }
                    >
                      <CaretRightIcon fontSize="24" color="#4575ba" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default React.memo(TyCTab);
