import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getModels = () => {
  return request(`${urlBase}/cars/models`);
};

export const getBrands = ({ model }) => {
  return request(`${urlBase}/cars/models/${model}/brands`);
};

export const getSubBrands = ({ model, brand }) => {
  return request(`${urlBase}/cars/models/${model}/brands/${brand}/subBrands`);
};

export function getVersions({ model, brand, subBrand }) {
  return request(
    `${urlBase}/cars/models/${model}/brands/${brand}/subBrands/${subBrand}/versions`,
  );
}
