import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Auth, Storage } from 'aws-amplify';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import { branch } from '../../../constants';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';

import { handleTypes } from '../constants';
import CardContainer from '../CardContainer';
import { getStepsFormulaById, putStepsFormulaById } from './request';

import styles from './EditStep.module.scss';

const EditStep = ({ style, content, update }) => {
  const [key, setKey] = useState(null);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const form = useFormik({
    initialValues: {
      description: content.description || '',
    },
    validate: () => {},
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = values => {
    setLoading(true);
    putStepsFormulaById(content.uuid, values).then(res => {
      if (!res.notifications.requestMessage) {
        getStepsFormulaById(content.uuid).then(res => {
          form.setFieldValue('description', res.data.description);
          update();
        });
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setProgress(0);
      setShow(false);
      setDisabled(true);
    });
  };

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleUpload = async event => {
    setLoading(true);
    const file = event.target.files[0];
    const mimetype = file.type;
    let ext = mimetype.split('/')[1];
    if (ext === 'quicktime') {
      ext = 'mov';
    }
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async () => {
        const result = await Storage.put(
          `${branch}/${content.uuid}/miniature.${ext}`,
          file,
          { progressCallback },
        );
        setKey(result.key);
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValidation = async event => {
    const validate = await form.validateForm();
    if (Object.keys(validate).length === 0) {
      handleUpload(event);
    } else {
      alert('Se requere un título para subir un nuevo recurso');
    }
  };

  const showEditButtons = () => {
    setDisabled(false);
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
    setDisabled(true);
  };

  useEffect(() => {
    if (key) {
      handleSubmit({ key, ...form.values });
    }
  }, [key]);

  return (
    <CardContainer
      style={style}
      title="Editar Categoría"
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
    >
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="description"
          name="description"
          label="Título"
          value={form.values.description}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.description && Boolean(form.errors.description)}
          helperText={form.touched.description && form.errors.description}
        />
        {show && (
          <>
            {progress > 0 ? (
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ marginTop: '25px', marginBottom: '10px' }}
              />
            ) : (
              <LoadingButton
                fullWidth
                variant="outlined"
                component="label"
                sx={{ marginTop: '15px' }}
                loading={loading}
              >
                <input
                  type="file"
                  name="file"
                  hidden
                  onChange={e => handleValidation(e)}
                  accept={handleTypes('img')}
                />
                Selectionar miniatura
              </LoadingButton>
            )}
          </>
        )}
        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

EditStep.propTypes = {
  content: PropTypes.object,
  style: PropTypes.object,
  update: PropTypes.func,
};

export default EditStep;
