import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Auth, Storage } from 'aws-amplify';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import mediaComponent from '../../../assets/images/media-component.png';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';
import PlusCircleIcon from '../../../components/Icons/PlusCircleIcon';
import Select from '../../../components/Inputs/Select';

import { handleTypes, mediaTypes } from '../constants';
import CardContainer from '../CardContainer';
import MediaResource from './MediaResource';

import {
  deleteCategoryMaterial,
  getCategoryMaterial,
  putCategoryMaterial,
} from './request';
import { mediaValidation } from './validations';
import { branch } from '../../../constants';
import styles from './index.module.scss';

const MediaSection = ({ style, title, content, category }) => {
  const [resource, setResource] = useState([]);
  const [keyMiniature, setKeyMiniature] = useState(null);
  const [filterTypes, setFilterTypes] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newItem, setNewItem] = useState(null);
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState(null);

  const form = useFormik({
    initialValues: {
      summary: '',
      title: '',
      type: 'video',
    },
    validate: mediaValidation,
    onSubmit: values => putNewResource(values),
  });

  const handleNewItem = () => {
    setNewItem(!newItem);
  };

  const putNewResource = (values, payload) => {
    const body = {
      material: { ...values },
    };
    if (payload.uuid) {
      body.material.uuid = payload.uuid;
    }
    if (payload.key) {
      body.material.key = payload.key;
    }
    if (payload.keyMiniature) {
      body.material.keyMiniature = payload.keyMiniature;
    }
    setLoading(true);
    putCategoryMaterial(category, body).then(res => {
      if (!res.notifications.requestMessage) {
        getCategoryMaterial(category).then(res => {
          setResource(res.data.material);
          form.resetForm();
          setNewItem(false);
          setProgress(0);
        });
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
    });
  };

  const deleteResource = uuid => {
    deleteCategoryMaterial(uuid).then(res => {
      if (!res.notifications.requestMessage) {
        getCategoryMaterial(category).then(res =>
          setResource(res.data.material),
        );
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
    });
  };

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleUpload = async event => {
    setLoading(true);
    const file = event.target.files[0];
    const mimetype = file.type;
    let ext = mimetype.split('/')[1];
    if (ext === 'quicktime') {
      ext = 'mov';
    }
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async () => {
        const result = await Storage.put(
          `${branch}/${category}/${resource.length}.${ext}`,
          file,
          { progressCallback },
        );
        if (event.target.name === 'miniature') {
          setKeyMiniature(result.key);
          return null;
        }
        setKey(result.key);
      });
    } catch (error) {
      console.info(error);
    }
  };

  const handleValidation = async event => {
    setActiveInput(event.target.name);
    const validate = await form.validateForm();
    if (Object.keys(validate).length === 0) {
      handleUpload(event);
    } else {
      alert('Se requere un título y descripción para subir un nuevo recurso');
      event.target.value = null;
    }
  };

  useEffect(() => {
    if (keyMiniature) {
      setLoading(false);
      setProgress(0);
    }
    if (key) {
      putNewResource(form.values, { key, keyMiniature });
      setKeyMiniature(null);
      setActiveInput(null);
      setLoading(false);
      setKey(null);
    }
  }, [key, keyMiniature]);

  useEffect(() => {
    setFilterTypes(handleTypes(form.values.type));
  }, [form.values.type]);

  useEffect(() => {
    setResource(content?.material);
  }, [content]);

  return (
    <CardContainer
      style={style}
      title={`Contenidos de ${title}`}
      icon={<PlusCircleIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={handleNewItem}
      modalChild={<img src={mediaComponent} width="100%" />}
    >
      {newItem && (
        <form onSubmit={form.handleSubmit}>
          <TextField
            id="title"
            name="title"
            label="Título"
            value={form.values.title}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.title && form.errors.title}
            helperText={form.touched.title && form.errors.title}
          />
          <TextField
            id="summary"
            name="summary"
            label="Descripción"
            value={form.values.summary}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.summary && form.errors.summary}
            helperText={form.touched.summary && form.errors.summary}
          />
          <Select
            id="type"
            value={form.values.type}
            name="type"
            label="Tipo de recurso"
            margin="normal"
            size="small"
            onChange={form.handleChange}
          >
            {Object.entries(mediaTypes).map(value => (
              <MenuItem key={value[0]} value={value[0]}>
                {value[1]}
              </MenuItem>
            ))}
          </Select>
          {keyMiniature && (
            <Typography
              variant="subtitle1"
              color="success.main"
              component="div"
              className={styles.CheckText}
            >
              Miniatura lista.
              <CheckCircleIcon fontSize="24" color="#2e7d32" fontWeight="2" />
            </Typography>
          )}
          {form.values.type === 'video' && !keyMiniature && (
            <>
              {progress > 0 && activeInput === 'miniature' ? (
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ marginTop: '25px', marginBottom: '10px' }}
                />
              ) : (
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  component="label"
                  sx={{ marginTop: '15px' }}
                  loading={loading}
                >
                  <input
                    type="file"
                    name="miniature"
                    hidden
                    onChange={e => handleValidation(e)}
                    accept={handleTypes('img')}
                  />
                  Selectionar miniatura
                </LoadingButton>
              )}
            </>
          )}

          {progress > 0 && activeInput === 'file' ? (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ marginTop: '25px', marginBottom: '10px' }}
            />
          ) : (
            <LoadingButton
              fullWidth
              variant="outlined"
              component="label"
              sx={{ marginTop: '15px' }}
              loading={loading}
            >
              <input
                type="file"
                name="file"
                hidden
                onChange={e => handleValidation(e)}
                accept={filterTypes}
              />
              Selectionar recurso
            </LoadingButton>
          )}
          <div className={styles.ActionButtons}>
            <Button variant="outlined" color="primary" onClick={handleNewItem}>
              Cancelar
            </Button>
          </div>
        </form>
      )}
      {resource?.length > 0 &&
        resource.map((element, index) => {
          return (
            <MediaResource
              key={`${element.uuid}-${index}`}
              index={index}
              categoryId={category}
              content={element}
              onSave={putNewResource}
              onDelete={deleteResource}
            />
          );
        })}
    </CardContainer>
  );
};

MediaSection.propTypes = {
  content: PropTypes.object,
  style: PropTypes.any,
  title: PropTypes.string,
  category: PropTypes.string,
};

export default React.memo(MediaSection);
