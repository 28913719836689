import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputLabel, MenuItem, TextField, InputAdornment } from '@mui/material';
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from 'draft-js';

import Header from '../../../../components/Header';
import Select from '../../../../components/Inputs/Select';

import { statusOptions } from '../../constants';

import { toolbar } from './toolbar';
import styles from './index.module.scss';
import { validations } from './validation';
import { AddNewContent, updateContent } from './request';

const IncentiveEditor = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    if (state) {
      const editorState = convertFromHTML(state.content.content);
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(editorState),
      );
    } else {
      return EditorState.createEmpty();
    }
  });

  const HandleEditorChange = value => {
    setEditorState(value);
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToRaw(editorState.getCurrentContent());
    const stringHTML = draftToHtml(currentContentAsHTML);
    return stringHTML;
  };

  const handleSubmitForm = async values => {
    setLoading(true);
    const html = convertContentToHTML();
    if (state?.content) {
      await updateContent({ values, html, uuid: state.content.uuid });
    } else {
      await AddNewContent({ values, html });
    }
    setLoading(false);
    navigate(-1);
  };

  const form = useFormik({
    initialValues: {
      customPath: state?.content.customPath || '',
      title: state?.content.title || '',
      status: state?.content.status || 'STAGING',
    },
    validate: validations,
    onSubmit: handleSubmitForm,
  });

  return (
    <div className={styles.InventivesContainer}>
      <Header
        title={
          state?.content
            ? 'Editar terminos y condiciones'
            : 'Crear nuevos terminos y condiciones'
        }
        onBack
      />
      <form onSubmit={form.handleSubmit} className={styles.IncentivesForm}>
        <div className={styles.IncentivesInputsContainer}>
          <div className={styles.Flex}>
            <TextField
              name="customPath"
              label="URL"
              margin="normal"
              size="small"
              value={form.values.customPath}
              onChange={form.handleChange}
              error={form.touched.customPath && Boolean(form.errors.customPath)}
              helperText={form.touched.customPath && form.errors.customPath}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    https://kinsu.mx/terms-and-conditions/promos/
                  </InputAdornment>
                ),
              }}
            />
            <Select
              value={form.values.status}
              name="status"
              label="Estatus"
              margin="normal"
              size="small"
              onChange={form.handleChange}
              error={form.touched.status && form.errors.status}
              helperText={form.touched.status && form.errors.status}
            >
              {Object.entries(statusOptions).map(value => {
                return (
                  <MenuItem key={value[0]} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <TextField
            fullWidth
            name="title"
            label="Título"
            margin="normal"
            size="small"
            value={form.values.title}
            onChange={form.handleChange}
            error={form.touched.title && Boolean(form.errors.title)}
            helperText={form.touched.title && form.errors.title}
          />
        </div>
        <div className={styles.EditorContainer}>
          <InputLabel>Contenido</InputLabel>
          <Editor
            editorState={editorState}
            onEditorStateChange={HandleEditorChange}
            toolbar={toolbar}
          />
        </div>
        <div className={styles.Actions}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
          >
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default React.memo(IncentiveEditor);
