import HeartbeatIcon from '../../../components/Icons/HeartbeatIcon';
import search from '../../../components/Icons/SearchProspectsIcon';
import circles from '../../../components/Icons/CirclesFour';
import trend from '../../../components/Icons/TrendUpIcon';
import car from '../../../components/Icons/CarFrontIcon';
import pig from '../../../components/Icons/SavePigIcon';
import pets from '../../../components/Icons/PetsIcon';

export const categoriesMap = {
  GENERAL: {
    title: 'General',
    icon: circles,
  },
  CAR: {
    title: 'Autos',
    icon: car,
  },
  HEALTH: {
    title: 'Salud',
    icon: HeartbeatIcon,
  },
  SALES: {
    title: 'Ventas',
    icon: trend,
  },
  PETS: {
    title: 'Mascotas',
    icon: pets,
  },
  PROSPECT: {
    title: 'Prospectar',
    icon: search,
  },
  LIFE: {
    title: 'Vida',
    icon: pig,
  },
  INCENTIVES: {
    title: 'Incentivos',
    icon: trend,
  },
};
