import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getKinsurerFilesOps = uuid => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/files`);
};

export const putKinsurerFilesOps = (uuid, docId, agentId, key, type, name) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/files`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentId: docId,
      agentId,
      source: key,
      type,
      name,
    }),
  });
};
