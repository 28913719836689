import { urlBase } from '../../../constants';
import request from '../../../utils/request';
import { isEmail, isPHONE, isUUIDv4 } from '../../../utils/validations';

export const getListKinsurers = (values, next) => {
  let url = `${urlBase}/jarvis/kinsurers`;
  if (values && isEmail(values.search)) {
    url = `${url}?email=${values.search}`;
  }
  if (values && isUUIDv4(values.search)) {
    url = `${url}?uuid=${values.search}`;
  }
  if (values && isPHONE(values.search)) {
    url = `${url}?phone=${values.search}`;
  }
  if (next) {
    url = `${url}&next=${next}`;
  }
  return request(url);
};
