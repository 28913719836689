import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { Auth, Storage } from 'aws-amplify';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

import { reportsBucket } from '../../constants';
import styles from './UploadCard.module.scss';

const UploadCard = ({ label, actionLabel, icon, reportID, confirm }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const progressCallback = progress => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100,
    );
    setProgress(progressInPercentage);
  };

  const handleUpload = async event => {
    setLoading(true);
    const file = event.target.files[0];
    const mimetype = file.type;
    let ext = mimetype.split('/')[1];
    if (ext === 'quicktime') {
      ext = 'mov';
    }
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then(async () => {
        await Storage.put(`${reportID}${new Date().valueOf()}.${ext}`, file, {
          customPrefix: {
            public: `${reportID}/`,
          },
          level: 'public',
          ...reportsBucket,
          progressCallback,
        });
        confirm();
        setLoading(false);
        setProgress(0);
      });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <div className={styles.TriggerAction}>
      <div className={styles.Icon}>{icon}</div>
      <Typography variant="h6" color="primary" gutterBottom>
        {label}
      </Typography>
      {progress > 0 ? (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginTop: '25px', marginBottom: '10px' }}
        />
      ) : (
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          component="label"
          loading={loading}
        >
          <input
            type="file"
            name="file"
            hidden
            onChange={handleUpload}
            accept="text/csv"
          />
          {actionLabel}
        </LoadingButton>
      )}
    </div>
  );
};

UploadCard.propTypes = {
  actionLabel: PropTypes.string,
  confirm: PropTypes.func,
  icon: PropTypes.element,
  label: PropTypes.string,
  reportID: PropTypes.string,
};

export default UploadCard;
