import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import CardContainer from '../CardContainer';

import styles from './index.module.scss';
import { generalValidation } from './validations';

const General = ({ style, value }) => {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      description: value?.description || '',
    },
    validate: generalValidation,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = values => {
    setLoading(true);
    // putFormula(header.uuid, header.category, values).then(res => {
    //   if (!res.notifications.requestMessage) {
    //     setCategoryFormulaWeb(header.category, res.data.uuid.Attributes);
    //   } else {
    //     alert('Hubo un error por favor intentelo más tarde');
    //     window.location.reload();
    //   }
    //   setLoading(false);
    //   setShow(false);
    //   setDisabled(true);
    // });
  };

  const showEditButtons = () => {
    setDisabled(false);
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
    setDisabled(true);
  };

  return (
    <CardContainer
      style={style}
      title="Descripción General"
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
      disabled
      // modalChild={<img src={titleComponent} width="100%" />}
    >
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="description"
          name="description"
          label="Descripción"
          value={form.values.description}
          onChange={form.handleChange}
          multiline
          maxRows={5}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.description && form.errors.description}
          helperText={form.touched.description && form.errors.description}
        />
        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

General.propTypes = {
  style: PropTypes.any,
  value: PropTypes.object,
};

export default General;
