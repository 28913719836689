/* eslint-disable no-debugger */
import { urlBase } from '../../../constants';
import request from '../../../utils/request';
import moment from 'moment/moment';

export const getCoverages = () => {
  return request(`${urlBase}/car/catalogues/coverage-packages`);
};

export const getModels = () => {
  return request(`${urlBase}/cars/models`);
};

export const getBrands = ({ model }) => {
  return request(`${urlBase}/cars/models/${model}/brands`);
};

export const getSubBrands = ({ model, brand }) => {
  return request(`${urlBase}/cars/models/${model}/brands/${brand}/subBrands`);
};

export function getVersions({ model, brand, subBrand }) {
  return request(
    `${urlBase}/cars/models/${model}/brands/${brand}/subBrands/${subBrand}/versions`,
  );
}

export const getVersionsNoHomologate = ({
  insurance,
  model,
  brand,
  subBrand,
}) => {
  return request(
    `${urlBase}/homologation/insurance/${insurance}/models/${model}/brands/${brand}/subBrands/${subBrand}/versiones`,
  );
};

export const getQuotation = ({
  quotationData,
  insuranceName,
  coveragesConfigurable,
  details,
  formData,
  update,
  externalId,
}) => {
  const payload = {
    insuranceName,
    leadId: details.uuid,
    paymentType: quotationData.paymentType,
    coveragePackageId: quotationData.coverage,
    vehicle: {
      id: quotationData.version,
      model: Number(quotationData.model),
      brand: quotationData.brand,
      subBrand: quotationData.subBrand,
      versionId: quotationData.version,
      externalId,
    },
    insurant: {
      dateOfBirth: moment(new Date(quotationData.dateOfBirth).getTime()).format(
        'YYYY-MM-DD',
      ),
      gender: quotationData.gender,
      postalCode: quotationData.postalCode,
    },
  };

  if (update) {
    payload.discount = formData.discount;
    payload.coverages = coveragesConfigurable.reduce(
      (accumulator, currentCoverage) => {
        if (currentCoverage.type === 'SUM' || currentCoverage.type === 'FREE') {
          accumulator.push({
            id: currentCoverage.id,
            sumInsured: formData[currentCoverage.id],
          });
        }
        if (currentCoverage.type === 'DED') {
          accumulator.push({
            id: currentCoverage.id,
            deductible: formData[currentCoverage.id],
          });
        }
        if (
          currentCoverage.type === 'BOOL' &&
          (formData[currentCoverage.id] === true ||
            formData[currentCoverage.id] !== 0 ||
            formData[currentCoverage.id].length > 0)
        ) {
          accumulator.push({ id: currentCoverage.id });
        }
        return accumulator;
      },
      [],
    );
  }

  return request(`${urlBase}/car/quotations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};
