import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading';
import { openInNewTab } from '../../../utils/openInNewTab';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import UserCircleIcon from '../../../components/Icons/UserCircleIcon';
import EnvelopeOpenIcon from '../../../components/Icons/EnvelopeOpenIcon';

import { Context } from '../context';

import ConfigPanel from './ConfigPanel';
import VehiclePanel from './VehiclePanel';
import InsurantEmpty from './InsurantEmpty';
import GeneralCoverages from './GeneralCoverages';
import InsurantConfiguration from './InsurantConfiguration';

import theme from '../../../theme';
import styles from './index.module.scss';

function InternalComparator() {
  const navigate = useNavigate();
  const [resumeData, setResumeData] = useState(null);

  const { contextData, setContextData } = useContext(Context);
  const { details, quotationData } = contextData;

  const form = useFormik({
    initialValues: {
      model: quotationData.model,
      brand: quotationData.brand,
      subBrand: quotationData.subBrand,
      version: quotationData.version,
    },
    // validate: TrainingValidation,
    onSubmit: data => {
      setContextData({
        quotationData: { ...quotationData, ...data },
      });
    },
  });

  const handlerResumeData = (newData, insurance) => {
    setResumeData({
      ...resumeData,
      [insurance]: newData,
    });
  };

  useEffect(() => {
    if (!details || !details?.fullName) {
      navigate(-1);
    }
  }, []);

  if (!details?.fullName) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Header title="Cotizador interno" onBack>
        <div className={styles.leadData}>
          <div className={styles.leadDataText}>
            <UserCircleIcon
              color={theme.palette.primary.main}
              className={styles.leadDataIcon}
            />
            <Typography>{details.fullName}</Typography>
          </div>
          <div className={styles.leadDataText}>
            <EnvelopeOpenIcon
              color={theme.palette.neutral[5]}
              className={styles.leadDataIcon}
            />
            <Typography>{details.email}</Typography>
          </div>
          <div className={styles.leadDataText}>
            <PhoneIcon
              color={theme.palette.neutral[5]}
              className={styles.leadDataIcon}
            />
            <Typography>{details.phoneNumber}</Typography>
          </div>
        </div>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            if (localStorage.getItem('lastQuotation') !== null) {
              localStorage.removeItem('lastQuotation');
            }
            localStorage.setItem(
              'lastQuotation',
              JSON.stringify({ resumeData, details, quotationData }),
            );
            const origin = window.location.origin;
            openInNewTab(`${origin}/printCarQuotation`);
          }}
        >
          Descargar PDF
        </Button>
      </Header>

      <div className={styles.quotationContainer}>
        <div className={styles.configPanel}>
          <ConfigPanel />
        </div>
        <div className={styles.informationPanel}>
          <VehiclePanel form={form} />
          {form.values.version && (
            <div className={styles.detailsPanel}>
              <GeneralCoverages />
              <div className={styles.detailsPanelScroll}>
                <InsurantConfiguration
                  insuranceName="mapfre"
                  details={details}
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantConfiguration
                  insuranceName="ana"
                  details={details}
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantConfiguration
                  insuranceName="chubb"
                  details={details}
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantConfiguration
                  insuranceName="qualitas"
                  details={details}
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantEmpty
                  insuranceName="hdi"
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantEmpty
                  insuranceName="axxa"
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
                <InsurantEmpty
                  insuranceName="atlas"
                  quotationData={quotationData}
                  onComplete={handlerResumeData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(InternalComparator);
