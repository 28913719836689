import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import styles from './index.module.scss';
import Tags from '../../../components/Inputs/Tags';
import theme from '../../../theme';

const TagsForm = ({
  formController,
  tags,
  onDelete,
  onAddition,
  reset,
  error,
  helperText,
}) => {
  return (
    <div className={styles.TagsForm}>
      <TextField
        name="title"
        label="Título"
        value={formController.values.title}
        onChange={formController.handleChange}
        onBlur={formController.handleBlur}
        margin="normal"
        size="small"
        error={formController.touched.message && formController.errors.message}
        helperText={
          formController.touched.message && formController.errors.message
        }
      />
      <TextField
        name="message"
        label="Mensaje"
        value={formController.values.message}
        onChange={formController.handleChange}
        onBlur={formController.handleBlur}
        margin="normal"
        size="small"
        error={formController.touched.message && formController.errors.message}
        helperText={
          formController.touched.message && formController.errors.message
        }
      />
      <Tags
        style={{ marginTop: 15 }}
        bgColor={theme.palette.neutral[0]}
        label="Remitentes"
        bubbles={tags}
        onAddition={onAddition}
        onDelete={onDelete}
        reset={reset}
        error={error}
        helperText={helperText}
      />
    </div>
  );
};

TagsForm.propTypes = {
  error: PropTypes.bool,
  formController: PropTypes.object,
  helperText: PropTypes.string,
  onAddition: PropTypes.func,
  onDelete: PropTypes.func,
  reset: PropTypes.func,
  tags: PropTypes.array,
};

export default React.memo(TagsForm);
