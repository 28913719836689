import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table, IconButton, Tooltip } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';

import 'moment/locale/es';

import KeyIcon from '../../../components/Icons/KeyIcon';
import CaretRightIcon from '../../../components/Icons/CaretRightIcon';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

import { statusLead, types } from '../LeadDetails/constants';

import styles from './LeadsList.module.scss';

const LeadsList = ({ rows }) => {
  const navigate = useNavigate();
  moment.locale('es');
  return (
    <Table size="small" aria-label="simple table" stickyHeader>
      <TableHead className={styles.TableHeaders}>
        <TableRow>
          <TableCell>UUID</TableCell>
          <TableCell>Lead</TableCell>
          <TableCell>Fecha de creación</TableCell>
          <TableCell>Teléfono</TableCell>
          <TableCell>Kinsurer Asociado</TableCell>
          <TableCell>Producto</TableCell>
          <TableCell>Estatus</TableCell>
          <TableCell>Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows &&
          rows.map(row => {
            const date = moment(new Date(row.createdDate)).format(
              'dddd, DD-MM-YYYY',
            );
            const hour = moment(new Date(row.createdDate)).format('h:mm a');
            return (
              <TableRow key={row.uuid}>
                <TableCell>
                  <Tooltip title={row.uuid}>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(row.uuid).then(() => {
                          alert('Copiado');
                        });
                      }}
                    >
                      <KeyIcon fontSize="24" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    {row.fullName}
                  </Typography>
                  <Typography variant="button">{row.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {capitalizeFirstLetter(date)}
                  </Typography>
                  <Typography variant="body2">{hour}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{row.phoneNumber}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {row.isReferencedByKinsurer ? (
                    <Typography variant="body2" color="success.main">
                      Si
                    </Typography>
                  ) : (
                    <Typography variant="body2">No</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {types[row.productType]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {statusLead[row.status]}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.Actions}
                  onClick={() => navigate(`${row.uuid}`)}
                >
                  <CaretRightIcon
                    fontSize="24"
                    fontWeight="1.9"
                    color="#4575ba"
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

LeadsList.propTypes = {
  rows: PropTypes.array,
};

export default React.memo(LeadsList);
