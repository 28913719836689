import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getStepsFormula = () => {
  return request(`${urlBase}/jarvis/formula/categories`);
};

export const getStepsFormulaById = categoryId => {
  return request(`${urlBase}/jarvis/formula/${categoryId}`);
};

export const putStepsFormulaById = (categoryId, values) => {
  return request(`${urlBase}/jarvis/formula/${categoryId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
};

export const deleteCategoryFormula = contentId => {
  return request(`${urlBase}/jarvis/categories/formula/${contentId}`, {
    method: 'DELETE',
  });
};
