import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import theme from '../../../theme';
import UserIcon from '../../../components/Icons/UserIcon';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import EnvelopeOpenIcon from '../../../components/Icons/EnvelopeOpenIcon';

import SharedLinks from './SharedLinks';
import { occupations, maritalStatus, statusKinsurer } from './constants';

import styles from './kinsurerInfo.module.scss';

const statusKinsurerColor = {
  REGISTERED: styles.statusColorGray,
  ACTIVE: styles.statusColorYellow,
  ENGAGE: styles.statusColorGreen,
};

const kinsurerInfo = ({ details }) => {
  const navigate = useNavigate();

  const getOccupation = () => {
    try {
      return occupations.filter(element => element.id === details.occupation)[0]
        .description;
    } catch (error) {
      return false;
    }
  };

  const getMaritalStatus = () => {
    try {
      return maritalStatus.filter(element => element.id === details.maritalStatus)[0]
        .description;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className={styles.InfoContainer}>
      <div className={styles.Details}>
        <div className={styles.EditAction}>
          <Tooltip title="Editar Kinsurer">
            <IconButton
              onClick={() =>
                navigate('update', {
                  state: {
                    curp: details?.curp,
                    clabe: details?.clabe,
                    dateOfBirth: details?.dateOfBirth,
                    occupation: details?.occupation,
                    maritalStatus: details?.maritalStatus,
                    postalCode: details?.postalCode,
                    neighborhood: details?.neighborhood,
                  },
                })
              }
            >
              <NotePencilIcon fontSize="24" color="#4575ba" fontWeight="2" />
            </IconButton>
          </Tooltip>
        </div>
        <Typography variant="h6" sx={{ fontWeight: 400 }}>
          {details.fullName}
        </Typography>
        <div>
          <div className={styles.Column}>
            <Typography variant="subtitle1">
              <EnvelopeOpenIcon fontSize="14" className={styles.AlignIcon} />
              {details.email}
            </Typography>
            <Typography variant="subtitle1">
              <PhoneIcon fontSize="14" className={styles.AlignIcon} />
              {details.phoneNumber}
            </Typography>
            <div className={styles.statusAndLeads}>
              <Typography
                variant="body2"
                className={classNames(
                  styles.status,
                  statusKinsurerColor[details.status],
                )}
              >
                {statusKinsurer[details.status]}
              </Typography>
              <span>
                <Typography variant="subtitle2" component="span">
                  Leads:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                  {details.totalLeads}
                </Typography>
              </span>
            </div>
          </div>
          <div className={styles.Column}>
            <span>
              <Typography variant="subtitle2" component="span">
                Fecha de registro:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {moment(new Date(details.createdDate)).format('DD-MM-YYYY')}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Hunter type:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {details.hunterType}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Funnel Stage:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {details.funnelStage}
              </Typography>
            </span>
            <Divider dark sx={{ margin: '5px 0' }} />
            <span>
              <Typography variant="subtitle2" component="span">
                CLABE:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.clabe
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {details.clabe ? details.clabe : 'Sin información'}
              </Typography>
            </span>
          </div>
          <div className={styles.Column}>
            <span>
              <Typography variant="subtitle2" component="span">
                CURP:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.curp
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {details.curp ? details.curp : 'Sin información'}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Fecha de nacimiento:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.dateOfBirth
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {details.dateOfBirth
                  ? moment(new Date(details.dateOfBirth)).format('DD-MM-YYYY')
                  : 'Sin información'}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Ocupación:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.occupation
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {getOccupation() || 'Sin información'}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Estado Civil:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.maritalStatus
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {getMaritalStatus() || 'Sin información'}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                C.P.:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.postalCode
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {details.postalCode ? details.postalCode : 'Sin información'}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Colonia:{' '}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color={
                  details.neighborhood
                    ? theme.palette.neutral[9]
                    : theme.palette.neutral[6]
                }
              >
                {details.neighborhood
                  ? details.neighborhood
                  : 'Sin información'}
              </Typography>
            </span>
          </div>
        </div>
      </div>

      <div className={styles.Additional}>
        {details.isReferencedByKinsurer && (
          <div className={styles.refInfo}>
            <div className={styles.Column}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }}>
                Kinsurer asociado
              </Typography>
              <Typography variant="body1">
                <UserIcon fontSize="14" className={styles.AlignIcon} />
                {details.referencedKinsurer?.fullName}
              </Typography>
              <Typography variant="body1">
                <EnvelopeOpenIcon fontSize="14" className={styles.AlignIcon} />
                {details.referencedKinsurer?.email}
              </Typography>
              <Typography variant="body1">
                <PhoneIcon fontSize="14" className={styles.AlignIcon} />
                {details.referencedKinsurer?.phoneNumber}
              </Typography>
            </div>
          </div>
        )}
        <SharedLinks
          urls={{
            leadsUrl: details?.leadsUrl,
            referralUrl: details?.referralUrl,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(kinsurerInfo);
