import { isCLABE, isCURP } from '../../../utils/validations';

export const validations = values => {
  const errors = {};
  if (values.curp !== '' && !isCURP(values.curp)) {
    errors.curp = 'No cumple con las reglas de formato';
  }
  if (values.clabe !== '' && !isCLABE(values.clabe)) {
    errors.clabe = 'No cumple con las reglas de formato';
  }
  return errors;
};

export function validatePassword(values) {
  const errors = {};
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,40}$/g.test(
      values.password,
    ) ||
    values.password === ''
  ) {
    errors.password = 'Email no cumple con los requerimientos';
  }
  return errors;
}
