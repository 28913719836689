import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import contactComponent from '../../../assets/images/contact-component.png';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import { Context } from '../../../context/GeneralContext';
import { contactValidation } from './validations';
import CardContainer from '../CardContainer';
import { putFormula } from './request';

import styles from './index.module.scss';

const ContactForm = ({ style }) => {
  const { generalData, setCategoryFormulaWeb } = useContext(Context);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { formula } = generalData;
  const { contact } = formula;

  const form = useFormik({
    initialValues: {
      summary: contact.content.summary,
      email: contact.content.email,
      phone: contact.content.phone,
    },
    validate: contactValidation,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = values => {
    setLoading(true);
    putFormula(contact.uuid, contact.category, values).then(res => {
      if (!res.notifications.requestMessage) {
        setCategoryFormulaWeb(contact.category, res.data.uuid.Attributes);
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setShow(false);
      setDisabled(true);
    });
  };

  const showEditButtons = () => {
    setDisabled(false);
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
    setDisabled(true);
  };

  return (
    <CardContainer
      style={style}
      title={contact.description}
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
      modalChild={<img src={contactComponent} width="100%" />}
    >
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="summary"
          name="summary"
          label="Descripción"
          value={form.values.summary}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.summary && form.errors.summary}
          helperText={form.touched.summary && form.errors.summary}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.email && form.errors.email}
          helperText={form.touched.email && form.errors.email}
        />
        <TextField
          id="phone"
          name="phone"
          label="WhatsApp"
          value={form.values.phone}
          onChange={form.handleChange}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.phone && form.errors.phone}
          helperText={form.touched.phone && form.errors.phone}
        />
        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

ContactForm.propTypes = {
  style: PropTypes.any,
};

export default ContactForm;
