import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext();
export const initialUserState = {
  formula: {},
};

const GeneralContextProvider = ({ children }) => {
  const [generalData, setGeneralData] = useState(initialUserState);

  const setFormulaWeb = res => {
    const formulaData = {};
    res.map(element => {
      switch (element.category) {
        case 'header':
          formulaData.header = element;
          break;
        case 'contact':
          formulaData.contact = element;
          break;
        case 'appInfo':
          formulaData.appInfo = element;
          break;
        case 'material':
          formulaData.material = element;
          break;
        case 'training':
          formulaData.training = element;
          break;
        default:
          break;
      }
      setGeneralData({ ...generalData, formula: formulaData });
      return null;
    });
  };

  const setCategoryFormulaWeb = (category, values) => {
    setGeneralData({
      ...generalData,
      formula: {
        ...generalData.formula,
        [category]: {
          ...generalData.formula[category],
          ...values,
        },
      },
    });
  };

  return (
    <Context.Provider value={{ generalData, setFormulaWeb, setCategoryFormulaWeb }}>
      {children}
    </Context.Provider>
  );
};

GeneralContextProvider.propTypes = {
  children: PropTypes.element,
};

export default GeneralContextProvider;
