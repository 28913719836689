import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';

import EyeClosedIcon from '../../../components/Icons/EyeClosed';
import EyeIcon from '../../../components/Icons/Eye';

import { validatePassword } from './validations';
import { putResetPassword } from './request';

import styles from './index.module.scss';

const ResetPasswordForm = ({ handleClose, open, handleAlert, uuid }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      password: 'Kinsu123',
    },
    validate: validatePassword,
    onSubmit: values => handleSubmit(values),
  });

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = values => {
    setLoading(true);
    putResetPassword(values, uuid).then(res => {
      console.info('res', res);
      if (!res.notifications.requestMessage) {
        handleAlert();
        handleClose();
        setLoading(false);
      }
    });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form className={styles.ResetPasswordForm} onSubmit={form.handleSubmit}>
        <Typography variant="subtitle1" gutterBottom>
          ¿Está seguro de restablecer la contraseña del usuario?
        </Typography>
        <FormControl
          variant="outlined"
          margin="normal"
          sx={{ width: '100%' }}
          className={
            form.errors.password && form.touched.password && styles.SignUpFormPassword
          }
        >
          <InputLabel>Contraseña</InputLabel>
          <OutlinedInput
            name="password"
            value={form.values.password}
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            onChange={form.handleChange}
            error={form.errors.password && form.touched.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  data-testid="password_adornment"
                  aria-label="toggle password visibility"
                  onClick={togglePassword}
                  edge="end"
                >
                  {showPassword ? <EyeIcon /> : <EyeClosedIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          {form.errors.password && form.touched.password && (
            <FormHelperText>Contraseña invalida</FormHelperText>
          )}
        </FormControl>
        <p id="parent-modal-description">
          Recuerda que la contraseña debe cumplir con lo siguiente:
          <ul>
            <li>Mínimo 8 caracteres</li>
            <li>Al menos una letra minúscula</li>
            <li>Al menos una letra mayúscula</li>
            <li>Al menos un número</li>
          </ul>
        </p>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          Confirmar
        </LoadingButton>
      </form>
    </Dialog>
  );
};

ResetPasswordForm.propTypes = {
  handleClose: PropTypes.func,
  handleAlert: PropTypes.func,
  open: PropTypes.bool,
  uuid: PropTypes.string,
};

export default React.memo(ResetPasswordForm);
