/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from './index.module.scss';

function RadioCardInput({ value, label, icon, size }) {
  const sizeTypes = {
    small: styles.RadioCardInputSmall,
  };
  return (
    <div className={classNames(styles.RadioCardInput, size && sizeTypes[size])}>
      <FormControlLabel
        value={value}
        control={<Radio size={size} />}
        label={label}
      />
      {icon && <img src={icon} alt={`icon ${label}`} />}
    </div>
  );
}

export default React.memo(RadioCardInput);
