import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const GetCategoryGroups = (category = 'status') => {
  return request(`${urlBase}/notifications/segmentation/${category}`);
};

export const postEmail = values => {
  return request(`${urlBase}/kinsurer-management/sendEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
};
