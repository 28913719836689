import { urlBase } from '../../../constants';
import request from '../../../utils/request';
import { isEmail, isUUIDv4 } from '../../../utils/validations';

export const getListLeads = (search, next) => {
  let url = `${urlBase}/jarvis/leads?`;
  if (search && isEmail(search)) {
    url = `${url}&email=${search}`;
  }
  if (search && isUUIDv4(search)) {
    url = `${url}&uuid=${search}`;
  }
  if (next) {
    url = `${url}&next=${next}`;
  }
  return request(url);
};
