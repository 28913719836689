import React from 'react';
import PropTypes from 'prop-types';

function SearchProspectsIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <path
          opacity="0.2"
          d="M14.4994 25C20.2984 25 24.9994 20.299 24.9994 14.5C24.9994 8.70101 20.2984 4 14.4994 4C8.7004 4 3.99939 8.70101 3.99939 14.5C3.99939 20.299 8.7004 25 14.4994 25Z"
          fill="#4575BA"
        /> */}
        <path
          d="M14.4994 25C20.2984 25 24.9994 20.299 24.9994 14.5C24.9994 8.70101 20.2984 4 14.4994 4C8.7004 4 3.99939 8.70101 3.99939 14.5C3.99939 20.299 8.7004 25 14.4994 25Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9236 21.9248L27.9987 27.9999"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 16.75C15.933 16.75 17.5 15.183 17.5 13.25C17.5 11.317 15.933 9.75 14 9.75C12.067 9.75 10.5 11.317 10.5 13.25C10.5 15.183 12.067 16.75 14 16.75Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
        <path
          d="M8.6947 19.812C9.23259 18.881 10.006 18.1079 10.9373 17.5704C11.8685 17.0329 12.9248 16.75 14.0001 16.75C15.0753 16.75 16.1316 17.033 17.0628 17.5705C17.9941 18.108 18.7675 18.8811 19.3053 19.8121"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

SearchProspectsIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

SearchProspectsIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.any,
};

export default React.memo(SearchProspectsIcon);
