import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import theme from '../../../theme';
import CaretLeftIcon from '../../../components/Icons/CaretLeftIcon';

import { appSections } from './constants';

import styles from './ScreenSelector.module.scss';
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ScreenSelector = ({ onClose, open, onSelect }) => {
  const [selected, setSelected] = useState({});
  const [before, setBefore] = useState({});
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (selected) {
      if (selected.children) {
        setChildren(selected.children);
        setBefore(selected);
      }
    }
  }, [selected]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullWidth
      maxWidth="xl"
    >
      <Typography
        variant="h5"
        color="primary"
        sx={{ marginBottom: 2, padding: 2 }}
      >
        Elige una pantalla para redireccionar dentro de la App.
      </Typography>
      <DialogContent className={styles.SelectionContainer}>
        {children.length > 0 ? (
          <>
            <IconButton
              className={styles.GoBackAction}
              onClick={() => {
                setChildren([]);
              }}
            >
              <CaretLeftIcon fontSize="28" color={theme.palette.primary.main} />
            </IconButton>
            <button
              className={classNames(
                styles.CardButton,
                selected.screen === before.screen && styles.SelectedCard,
              )}
              onClick={() => {
                setChildren([]);
              }}
            >
              <img src={before.img} alt={before.screen} />
              <Typography variant="subtitle1">{before.name}</Typography>
            </button>
            <div className={styles.Separator}></div>
            {children.map(section => {
              return (
                <button
                  key={section.screen}
                  className={classNames(
                    styles.CardButton,
                    selected.screen === section.screen && styles.SelectedCard,
                  )}
                  onClick={() => setSelected(section)}
                >
                  <img src={section.img} alt={section.screen} />
                  <Typography variant="subtitle1">{section.name}</Typography>
                </button>
              );
            })}
          </>
        ) : (
          <>
            {appSections.map(section => {
              return (
                <button
                  key={section.screen}
                  className={classNames(
                    styles.CardButton,
                    selected.screen === section.screen && styles.SelectedCard,
                  )}
                  onClick={() => setSelected(section)}
                >
                  <img src={section.img} alt={section.screen} />
                  <Typography variant="subtitle1">{section.name}</Typography>
                </button>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onSelect(selected)}
          sx={{ marginBottom: 2, marginRight: 2 }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ScreenSelector.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
};

export default React.memo(ScreenSelector);
