import React from 'react';
import PropTypes from 'prop-types';

import SharedInput from '../../../components/SharedInput';
import styles from './index.module.scss';

const SharedLinks = ({ urls }) => {
  const { leadsUrl, referralUrl } = urls;
  const code = leadsUrl.split('/');
  return (
    <div className={styles.SharedLinks}>
      <SharedInput
        url={leadsUrl}
        code={code[code.length - 1]}
        msgText={'Cotiza y compara el seguro que buscas!'}
      />
      <SharedInput
        url={referralUrl}
        code={code[code.length - 1]}
        msgText={'Registrate en Kinsu.'}
      />
    </div>
  );
};

SharedLinks.propTypes = {
  urls: PropTypes.object,
};

export default SharedLinks;
