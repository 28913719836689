import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import theme from '../../../theme';
import GroupSelector from '../../../components/GroupSelector';

import { GetCategoryGroups } from './request';

import styles from './index.module.scss';

const TopicForm = ({ formController }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    GetCategoryGroups().then(res => setGroups(res.data.body.groups));
  }, []);

  return (
    <div className={styles.TopicForm}>
      <TextField
        name="title"
        label="Título"
        value={formController.values.title}
        onChange={formController.handleChange}
        onBlur={formController.handleBlur}
        margin="normal"
        size="small"
        error={formController.touched.message && formController.errors.message}
        helperText={
          formController.touched.message && formController.errors.message
        }
      />
      <TextField
        name="message"
        label="Mensaje"
        value={formController.values.message}
        onChange={formController.handleChange}
        onBlur={formController.handleBlur}
        margin="normal"
        size="small"
        error={formController.touched.message && formController.errors.message}
        helperText={
          formController.touched.message && formController.errors.message
        }
      />
      <GroupSelector
        label="Estatus del Kinsurer"
        bgColor={theme.palette.neutral[0]}
        name="groups"
        groups={groups}
        onChange={formController.handleChange}
        onBlur={formController.handleBlur}
        error={formController.touched.groups && formController.errors.groups}
        helperText={
          formController.touched.groups && formController.errors.groups
        }
        checked={formController.values.groups}
        position="horizontal"
        valueProperty="topic"
        unControlled={true}
        style={{ marginTop: '18px' }}
      />
    </div>
  );
};

TopicForm.propTypes = {
  formController: PropTypes.object,
};

export default React.memo(TopicForm);
