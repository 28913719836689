/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function FloppyDiskIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27 11.4142V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H20.5858C20.7171 5 20.8471 5.02587 20.9685 5.07612C21.0898 5.12638 21.2 5.20004 21.2929 5.29289L26.7071 10.7071C26.8 10.8 26.8736 10.9102 26.9239 11.0315C26.9741 11.1529 27 11.2829 27 11.4142Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0007 23.0011C17.9337 23.0011 19.5007 21.4341 19.5007 19.5011C19.5007 17.5681 17.9337 16.0011 16.0007 16.0011C14.0677 16.0011 12.5007 17.5681 12.5007 19.5011C12.5007 21.4341 14.0677 23.0011 16.0007 23.0011Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 12.0011H12V5.0011"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

FloppyDiskIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

FloppyDiskIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(FloppyDiskIcon);
