import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

const ProductConfiguration = ({ type, data }) => {
  switch (type) {
    case 'CAR':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Nombre:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.name} {data.insurant.lastName}{' '}
              {data.insurant.secondLastName}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Contacto:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.phoneNumber}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              email:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.email}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Fecha de nacimiento:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {moment(new Date(data.insurant.dateOfBirth)).format('DD-MM-YYYY')}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Genero:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.gender === 'MALE' ? 'Hombre' : 'Mujer'}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Código postal:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.postalCode}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Auto:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.vehicle.brand}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Modelo:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.vehicle.subBrand}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Año:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.vehicle.model}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Versión:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.vehicle.version}
            </Typography>
          </span>
        </>
      );
    case 'HEALTH':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              key:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              value
            </Typography>
          </span>
        </>
      );
    case 'HOME':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Dirección:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {`${data.address.street} ${data.address.number} ${data.address.intNumber}`}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Colonia:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {`${data.address.neighborhood} ${data.address.postalCode}`}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Estado:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {`${data.address.town}, ${data.address.state}`}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Propiedad:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.type}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Area:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.area}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Pisos:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.numberFloor}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Sotanos:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.basements}
            </Typography>
          </span>
          {data.property.orientation && (
            <span>
              <Typography variant="subtitle2" component="span">
                Orientación:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {data.property.orientation}
              </Typography>
            </span>
          )}
          <span>
            <Typography variant="subtitle2" component="span">
              Valor de la propiedad:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.valuation}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Valor de los contenidos:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.contentValuation}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Incluye fenómenos naturales:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.property.includePhenomenon ? 'Si' : 'No'}
            </Typography>
          </span>
        </>
      );
    case 'MOTORCYCLE':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Fecha de nacimiento:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {moment(new Date(data.insurant.dateOfBirth)).format('DD-MM-YYYY')}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Genero:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.gender === 'MALE' ? 'Hombre' : 'Mujer'}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Código postal:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.insurant.postalCode}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Moto:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.moto.brand}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Modelo:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.moto.subBrand}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Año:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.moto.model}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Versión:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.moto.version}
            </Typography>
          </span>
        </>
      );
    case 'PETS':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Tipo:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.type === 'CAT' ? 'Gato' : 'Perro'}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Descripción:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.description}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Raza:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.isRage ? 'Si' : 'No'}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Edad:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.petAge}
            </Typography>
          </span>
        </>
      );
    case 'LIFE':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Contibución:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.contribution}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Moneda:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.currency}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Fecha de nacimiento:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {moment(new Date(data.dateOfBirth)).format('DD-MM-YYYY')}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Genero:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.gender === 'MALE' ? 'Hombre' : 'Mujer'}
            </Typography>
          </span>
        </>
      );
    case 'RETIREMENT':
      return (
        <>
          <span>
            <Typography variant="subtitle2" component="span">
              Fecha de nacimiento:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {moment(new Date(data.dateOfBirth)).format('DD-MM-YYYY')}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Ocupacíon:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.occupation}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Periodo:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.period}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" component="span">
              Código postal:{' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.postalCode}
            </Typography>
          </span>
        </>
      );

    default:
      break;
  }
};

ProductConfiguration.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
};

export default React.memo(ProductConfiguration);
