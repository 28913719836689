/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

import Select from '../../components/Inputs/Select';
import CheckCircleIcon from '../../components/Icons/CheckCircleIcon';
import XCircleIcon from '../../components/Icons/XCircleIcon';
import QuestionIcon from '../../components/Icons/QuestionIcon';
import ArrowRightIcon from '../../components/Icons/ArrowRightIcon';
import ArrowLeftRightIcon from '../../components/Icons/ArrowsLeftRightIcon';
import Header from '../../components/Header';
import { Context } from '../../context/SessionContext';

import {
  getModels,
  getBrands,
  getSubBrands,
  getVersions,
  setSaveHomolog,
  putDisassociate,
} from './request';
import styles from './index.module.scss';
import { Card } from '@mui/material';

function CarCatalogHomologation() {
  const [modelsData, setModelsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [subBrandsData, setSubBrandsData] = useState([]);
  const [baseVersionsData, setBaseVersionsData] = useState([]);
  const [insuranceVersionsData, setInsuranceVersionsData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [versionSelected, setVersionSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loadingDisassociation, setLoadingDisassociation] = useState(false);
  const { userData } = useContext(Context);

  const form = useFormik({
    initialValues: {
      insurance: '',
      model: '',
      brand: '',
      subBrand: '',
      version: '',
      baseVersionSelected: '',
      insuranceVersionSelected: '',
    },
    // validate: TrainingValidation,
    // onSubmit: values => addNewResource(values),
  });

  const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled(props => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const handleChangeInsurance = event => {
    form.handleChange(event);
    if (form.values.brand !== '') {
      handleGetSubBrands({
        insurance: event.target.value,
        brand: form.values.brand,
      });
    }
  };

  const handleSort = (a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  };

  const handleClose = () => {
    setOpenSuccess(false);
    setOpen(false);
    setError(null);
  };

  const handleGetModels = () => {
    getModels().then(({ data }) => {
      setModelsData(data);
    });
  };

  const handleGetBrands = event => {
    form.handleChange(event);
    getBrands({ model: event.target.value }).then(({ data }) => {
      setBrandsData(data.sort(handleSort));
      if (
        form.values.insurance !== '' &&
        form.values.brand !== '' &&
        data.find(item => item.id === form.values.brand)
      ) {
        handleGetSubBrands({ model: event.target.value });
      } else {
        form.setFieldValue('brand', '');
        setSubBrandsData([]);
      }
    });
  };

  const handleGetSubBrands = ({ insurance, model, brand }) => {
    setBaseVersionsData([]);
    form.setFieldValue('subBrand', '');
    getSubBrands({
      insurance: insurance || form.values.insurance,
      model: model || form.values.model,
      brand: brand || form.values.brand,
    }).then(({ data }) => {
      setSubBrandsData(data.sort(handleSort));
    });
  };

  const handleGetVersions = subBrand => {
    setBaseVersionsData([]);
    setInsuranceVersionsData([]);
    getVersions({
      insurance: form.values.insurance,
      model: form.values.model,
      brand: form.values.brand,
      subBrand,
    }).then(({ data, notifications }) => {
      if (data) {
        setBaseVersionsData(data.baseVersions);
        setInsuranceVersionsData(data.insuranceVersions);
      } else {
        setError(notifications);
        setOpen(true);
      }
    });
  };

  const handleReloadGetVersion = () => {
    getVersions({
      insurance: form.values.insurance,
      model: form.values.model,
      brand: form.values.brand,
      subBrand: form.values.subBrand,
    }).then(({ data, notifications }) => {
      if (data) {
        setBaseVersionsData(data.baseVersions);
        setInsuranceVersionsData(data.insuranceVersions);
      } else {
        setError(notifications);
        setOpen(true);
      }
    });
  };

  const handleToggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleDisassociate = () => {
    setLoadingDisassociation(true);
    putDisassociate({
      insuranceName: form.values.insurance,
      baseVersionId: baseVersionSelectedData.id,
      userId: userData.username
    }).then(() => {
      setLoadingDisassociation(false);
      handleReloadGetVersion();
      form.setFieldValue('insuranceVersionSelected', '');
      form.setFieldValue('baseVersionSelected', '');
    });
  };

  const handleOnSubmit = () => {
    setLoading(true);
    setSaveHomolog({
      idBaseVersion: form.values.baseVersionSelected,
      idInsuranceVersion: form.values.insuranceVersionSelected,
      userId: userData.username
    })
      .then(({ data, notifications }) => {
        if (data) {
          handleReloadGetVersion();
          setOpenSuccess(true);
        } else {
          setError(notifications);
          setOpen(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetModels();
  }, []);

  const baseVersionSelectedData =
    baseVersionsData.find(
      item => item.id === form.values.baseVersionSelected,
    ) || {};

  const insuranceVersionSelectedData =
    insuranceVersionsData.find(
      item => item.id === form.values.insuranceVersionSelected,
    ) || {};

  const isDisable = Boolean(
    baseVersionSelectedData[form.values.insurance.toLocaleLowerCase()]
      ?.versionId,
  );

  return (
    <div className={styles.content}>
      <Header title="Homologación de Autos" />
      <form>
        <div className={styles.controls}>
          <Select
            label="Aseguradora"
            name="insurance"
            size="small"
            margin="normal"
            value={form.values.insurance}
            onChange={handleChangeInsurance}
            error={form.touched.insurance && form.errors.insurance}
            helperText={form.touched.insurance && form.errors.insurance}
          >
            <MenuItem value="MAPFRE">MAPFRE</MenuItem>
            <MenuItem value="QUALITAS">QUALITAS</MenuItem>
            <MenuItem value="ANA">ANA</MenuItem>
            <MenuItem value="CHUBB">CHUBB</MenuItem>
            <MenuItem value="AXA">AXA</MenuItem>
          </Select>

          <Select
            label="Año"
            name="model"
            size="small"
            margin="normal"
            value={form.values.model}
            onChange={handleGetBrands}
            error={form.touched.model && form.errors.model}
            helperText={form.touched.model && form.errors.model}
          >
            {modelsData.map(model => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>

          <Select
            label="Marca"
            name="brand"
            size="small"
            margin="normal"
            disabled={!form.values.model}
            value={form.values.brand}
            loading={'true'}
            onChange={event => {
              form.handleChange(event);
              handleGetSubBrands({ brand: event.target.value });
              setExpanded(true);
            }}
            error={form.touched.brand && form.errors.brand}
            helperText={form.touched.brand && form.errors.brand}
          >
            {brandsData.map(brand => (
              <MenuItem key={brand.id} value={brand.id}>
                {brand.description}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className={styles.accordion}>
          <Accordion expanded={expanded} onChange={handleToggleAccordion}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{form.values.subBrand}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="subBrand"
                  value={form.values.subBrand}
                  onChange={event => {
                    form.handleChange(event);
                    handleGetVersions(event.target.value);
                    setExpanded(false);
                  }}
                >
                  {subBrandsData.map(subBrand => (
                    <div key={subBrand.id} className={styles.subBrand}>
                      <FormControlLabel
                        value={subBrand.id}
                        control={<Radio />}
                        label={subBrand.name}
                      />
                      ({subBrand.baseVersions})({subBrand.insuranceVersions})
                      {subBrand.isFullHomologated && (
                        <CheckCircleIcon color="green" fontSize="24" />
                      )}
                      {!subBrand.isFullHomologated && (
                        <XCircleIcon color="red" fontSize="24" />
                      )}
                      {subBrand.insuranceIsFullHomologated && (
                        <CheckCircleIcon color="green" fontSize="24" />
                      )}
                      {!subBrand.insuranceIsFullHomologated && (
                        <XCircleIcon color="red" fontSize="24" />
                      )}
                      {!subBrand.isNotFound && (
                        <QuestionIcon color="gray" fontSize="24" />
                      )}
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </div>
      </form>

      {!error && baseVersionsData.length > 0 && (
        <>
          <div className={styles.container}>
            <div className={styles.subContainer}>
              <Typography variant="h6" className={styles.subContainerTitle}>
                Catálogo Base
              </Typography>
              <RadioGroup
                name="baseVersionSelected"
                value={form.values.baseVersionSelected}
                onChange={form.handleChange}
              >
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>idCatalog</TableCell>
                        <TableCell>idHomologate</TableCell>
                        <TableCell align="center">Descripción</TableCell>
                        <TableCell align="center">Descripción larga</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {baseVersionsData.map(baseVersionsData => {
                        const isSelected =
                          baseVersionsData[
                            form.values.insurance.toLocaleLowerCase()
                          ]?.versionId;
                        return (
                          <TableRow
                            key={baseVersionsData.id}
                            selected={Boolean(
                              isSelected && isSelected === versionSelected,
                            )}
                            onClick={() => setVersionSelected(isSelected)}
                          >
                            <TableCell component="th" scope="row">
                              {baseVersionsData[
                                form.values.insurance.toLocaleLowerCase()
                              ] && (
                                <CheckCircleIcon color="green" fontSize="24" />
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {baseVersionsData.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {
                                baseVersionsData[
                                  form.values.insurance.toLocaleLowerCase()
                                ]?.versionId
                              }
                            </TableCell>
                            <TableCell align="left">
                              {baseVersionsData.description}
                            </TableCell>
                            <TableCell align="left">
                              {baseVersionsData.largeDescription}
                            </TableCell>
                            <TableCell align="right">
                              <Radio value={baseVersionsData.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RadioGroup>
            </div>

            <div className={styles.subContainer}>
              <Typography variant="h6" className={styles.subContainerTitle}>
                Catálogo de la aseguradora
              </Typography>
              <RadioGroup
                name="insuranceVersionSelected"
                value={form.values.insuranceVersionSelected}
                onChange={form.handleChange}
              >
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell align="center">Descripción</TableCell>
                        <TableCell align="center">Descripción larga</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {insuranceVersionsData.map(insuranceVersionData => {
                        return (
                          <TableRow
                            key={insuranceVersionData.id}
                            selected={insuranceVersionData.id.includes(
                              versionSelected,
                            )}
                          >
                            <TableCell component="th" scope="row">
                              {insuranceVersionData.id}
                            </TableCell>
                            <TableCell align="left">
                              {insuranceVersionData.description}
                            </TableCell>
                            <TableCell align="left">
                              {insuranceVersionData.largeDescription}
                            </TableCell>
                            <TableCell align="right">
                              <Radio value={insuranceVersionData.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RadioGroup>
            </div>
          </div>
          <div className={styles.homologCard}>
            <Card className={styles.homologSubCard}>
              <p>
                <i>id:</i> {baseVersionSelectedData.id}
              </p>
              <p>
                <i>desc:</i> {baseVersionSelectedData.description}
              </p>
              <p>
                <i>large desc:</i>
                {baseVersionSelectedData.largeDescription}
              </p>
            </Card>
            <ArrowLeftRightIcon />
            <Card className={styles.homologSubCard}>
              <p>
                <i>id:</i> {insuranceVersionSelectedData.id}
              </p>
              <p>
                <i>desc:</i> {insuranceVersionSelectedData.description}
              </p>
              <p>
                <i>large desc:</i>{' '}
                {insuranceVersionSelectedData.largeDescription}
              </p>
            </Card>
            <ArrowRightIcon />
            <div className={styles.actions}>
              <Button
                variant="contained"
                size="large"
                disabled={
                  !(
                    baseVersionSelectedData.id &&
                    insuranceVersionSelectedData.id
                  ) || loading
                }
                onClick={handleOnSubmit}
              >
                Guardar relación
              </Button>
              <LoadingButton
                variant="contained"
                size="large"
                type="button"
                disabled={!isDisable}
                loading={loadingDisassociation}
                onClick={handleDisassociate}
              >
                Desvincular
              </LoadingButton>
            </div>
          </div>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error al homologar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: {error?.requestCode} Mensaje: {error?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuccess}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Homologación completa</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Su homologación se realizo correctamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(CarCatalogHomologation);
