import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, IconButton } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import moment from 'moment';
import 'moment/locale/es';

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import CaretRightIcon from '../../../components/Icons/CaretRightIcon';
import { statusKinsurer } from '../KinsurerDetails/constants';
import KeyIcon from '../../../components/Icons/KeyIcon';

import styles from './KinsurersList.module.scss';

export const statusKinsurerColor = {
  REGISTERED: styles.statusColorGray,
  ACTIVE: styles.statusColorYellow,
  ENGAGE: styles.statusColorGreen,
};

const KinsurersList = ({ rows }) => {
  const navigate = useNavigate();
  moment.locale('es');
  return (
    <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>UUID</TableCell>
          <TableCell>Kinsurer</TableCell>
          <TableCell>Fecha de creación</TableCell>
          <TableCell>Teléfono</TableCell>
          <TableCell>Kinsurer Asociado</TableCell>
          <TableCell>Estatus</TableCell>
          <TableCell>Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows &&
          rows.map(row => {
            const date = moment(new Date(row.createdDate)).format(
              'dddd, DD-MM-YYYY',
            );
            const hour = moment(new Date(row.createdDate)).format('h:mm a');
            return (
              <TableRow key={row.uuid}>
                <TableCell>
                  <Tooltip title={row.uuid}>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(row.uuid).then(() => {
                          alert('Copiado');
                        });
                      }}
                    >
                      <KeyIcon fontSize="24" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" gutterBottom>
                    {row.fullName}
                  </Typography>
                  <Typography variant="button">{row.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {capitalizeFirstLetter(date)}
                  </Typography>
                  <Typography variant="body2">{hour}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{row.phoneNumber}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {row.isReferencedByKinsurer ? (
                    <Typography variant="body2" color="success.main">
                      Si
                    </Typography>
                  ) : (
                    <Typography variant="body2">No</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    className={statusKinsurerColor[row.status]}
                  >
                    {statusKinsurer[row.status]}
                  </Typography>
                </TableCell>
                <TableCell
                  className={styles.Actions}
                  onClick={() => navigate(`${row.uuid}`)}
                >
                  <CaretRightIcon
                    fontSize="24"
                    fontWeight="1.9"
                    color="#4575ba"
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

KinsurersList.propTypes = {
  rows: PropTypes.array,
};

export default KinsurersList;
