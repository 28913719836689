/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function LightbulbIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className} data-testid="InfoIcon-Test">
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0005 24.5H20.0005"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0005 29H19.0005"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.38602 20.5C7.86262 19.1565 6.78449 17.3809 6.29509 15.4095C5.80569 13.4381 5.92823 11.3645 6.6464 9.46444C7.36458 7.56442 8.64433 5.92815 10.3154 4.77338C11.9864 3.6186 13.9695 3.00008 16.0007 3.00012C18.0319 3.00016 20.015 3.61876 21.6859 4.7736C23.3569 5.92845 24.6366 7.56477 25.3547 9.46482C26.0728 11.3649 26.1953 13.4385 25.7058 15.4099C25.2163 17.3813 24.1381 19.1568 22.6147 20.5003"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

LightbulbIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

LightbulbIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '18', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(LightbulbIcon);
