import { urlBase } from '../../constants';
import request from '../../utils/request';

export const getModels = () => {
  return request(`${urlBase}/cars/models`);
};

export const getBrands = ({ model }) => {
  return request(`${urlBase}/cars/models/${model}/brands`);
};

export const getSubBrands = ({ insurance, model, brand }) => {
  return request(
    `${urlBase}/homologation/insurance/${insurance}/models/${model}/brands/${brand}/subBrands`,
  );
};

export const getVersions = ({ insurance, model, brand, subBrand }) => {
  return request(
    `${urlBase}/homologation/insurance/${insurance}/models/${model}/brands/${brand}/subBrands/${subBrand}/versiones`,
  );
};

export const setSaveHomolog = ({ idBaseVersion, idInsuranceVersion, userId }) => {
  return request(`${urlBase}/homologation`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idBaseVersion,
      idInsuranceVersion,
      userId
    }),
  });
};

export const putDisassociate = ({ insuranceName, baseVersionId, userId }) => {
  return request(`${urlBase}/homologation/disassociate`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      insuranceName,
      baseVersionId,
      userId
    }),
  });
};
