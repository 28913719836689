import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';

import FormControl from '@mui/material/FormControl';
import Select from '../../../components/Inputs/Select';
import DatePicker from '../../../components/Inputs/DatePicker';
import RadioCardInput from '../../../components/Inputs/RadioCardInput';

import man from '../../../assets/images/man.png';
import woman from '../../../assets/images/woman.png';

import styles from './Form.module.scss';
import { getModels, getBrands, getSubBrands, getVersions } from './request';

function Form({ onSubmit, quotationData, catalogues }) {
  const [modelsData, setModelsData] = useState(catalogues.modelsData || []);
  const [brandsData, setBrandsData] = useState(catalogues.brandsData || []);
  const [subBrandsData, setSubBrandsData] = useState(
    catalogues.subBrandsData || [],
  );
  const [versionsData, setVersionsData] = useState(
    catalogues.versionsData || [],
  );

  const form = useFormik({
    initialValues: {
      gender: quotationData.gender || 'FEMALE',
      postalCode: quotationData.postalCode || '',
      dateOfBirth: quotationData.dateOfBirth || new Date(),
      model: quotationData.model || '',
      brand: quotationData.brand || '',
      subBrand: quotationData.subBrand || '',
      version: quotationData.version || '',
    },
    // validate: TrainingValidation,
    onSubmit: values => {
      onSubmit({
        values,
        catalogues: {
          modelsData,
          brandsData,
          subBrandsData,
          versionsData,
        },
      });
    },
  });

  const handleSort = (a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  };

  const handleGetModels = () => {
    getModels().then(({ data }) => {
      setModelsData(data);
    });
  };

  const handleGetBrands = model => {
    form.setFieldValue('brand', '');
    form.setFieldValue('subBrand', '');
    form.setFieldValue('version', '');
    getBrands({ model }).then(({ data }) => {
      setBrandsData(data.sort(handleSort));
    });
  };

  const handleGetSubBrands = brand => {
    form.setFieldValue('subBrand', '');
    form.setFieldValue('version', '');
    getSubBrands({
      model: form.values.model,
      brand,
    }).then(({ data }) => {
      setSubBrandsData(data.sort(handleSort));
    });
  };

  const handleGetVersions = subBrand => {
    form.setFieldValue('version', '');
    getVersions({
      model: form.values.model,
      brand: form.values.brand,
      subBrand,
    }).then(({ data }) => {
      setVersionsData(data.sort(handleSort));
    });
  };

  useEffect(() => {
    handleGetModels();
  }, []);

  return (
    <form onSubmit={form.handleSubmit} className={styles.form}>
      <FormControl component="fieldset" className={styles.RadioCardFieldset}>
        <FormLabel component="legend">Indica tu género</FormLabel>
        <RadioGroup
          aria-label="gender"
          className={styles.RadioCardGroup}
          onChange={form.handleChange}
          name="gender"
          value={form.values.gender}
        >
          <RadioCardInput value="FEMALE" label="Femenino" icon={woman} />
          <RadioCardInput value="MALE" label="Masculino" icon={man} />
        </RadioGroup>
      </FormControl>

      <DatePicker
        name="dateOfBirth"
        label="Fecha de nacimiento"
        size="small"
        margin="normal"
        onChange={form.handleChange}
        value={form.values.dateOfBirth}
        error={form.errors.dateOfBirth && form.touched.dateOfBirth}
        helperText={form.errors.dateOfBirth && form.touched.dateOfBirth}
      />

      <TextField
        name="postalCode"
        label="Código postal"
        size="small"
        margin="normal"
        onChange={form.handleChange}
        value={form.values.postalCode}
        error={form.errors.postalCode && form.touched.postalCode}
        helperText={form.errors.postalCode && form.touched.postalCode}
      />

      <Select
        label="Año"
        name="model"
        size="small"
        margin="normal"
        value={form.values.model}
        onChange={event => {
          form.handleChange(event);
          handleGetBrands(event.target.value);
        }}
        error={form.touched.model && form.errors.model}
        helperText={form.touched.model && form.errors.model}
      >
        {modelsData.map(model => (
          <MenuItem key={model} value={model}>
            {model}
          </MenuItem>
        ))}
      </Select>

      <Select
        label="Marca"
        name="brand"
        size="small"
        margin="normal"
        disabled={!form.values.model}
        value={form.values.brand}
        loading={'true'}
        onChange={event => {
          form.handleChange(event);
          handleGetSubBrands(event.target.value);
        }}
        error={form.touched.brand && form.errors.brand}
        helperText={form.touched.brand && form.errors.brand}
      >
        {brandsData.map(brand => (
          <MenuItem key={brand.id} value={brand.id}>
            {brand.description}
          </MenuItem>
        ))}
      </Select>

      <Select
        label="Modelo"
        name="subBrand"
        size="small"
        margin="normal"
        disabled={!form.values.brand}
        value={form.values.subBrand}
        loading={'true'}
        onChange={event => {
          form.handleChange(event);
          handleGetVersions(event.target.value);
        }}
        error={form.touched.subBrand && form.errors.subBrand}
        helperText={form.touched.subBrand && form.errors.subBrand}
      >
        {subBrandsData.map(subBrand => (
          <MenuItem key={subBrand.id} value={subBrand.id}>
            {subBrand.description}
          </MenuItem>
        ))}
      </Select>

      <Select
        label="Versión"
        name="version"
        size="small"
        margin="normal"
        disabled={!form.values.subBrand}
        value={form.values.version}
        loading={'true'}
        onChange={form.handleChange}
        error={form.touched.version && form.errors.version}
        helperText={form.touched.version && form.errors.version}
      >
        {versionsData.map(version => (
          <MenuItem key={version.id} value={version.id}>
            {version.description}
          </MenuItem>
        ))}
      </Select>

      <div className={styles.actions}>
        <Button variant="contained" color="primary" type="submit">
          Cotizar seguro
        </Button>
      </div>
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  quotationData: PropTypes.object,
  catalogues: PropTypes.object,
};

export default React.memo(Form);
