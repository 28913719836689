import React from 'react';
import PropTypes from 'prop-types';

function PetsIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.65767 26.8564C8.65767 26.8564 8.7436 24.979 11.2172 24.979C13.6909 24.979 13.2665 28.5632 13.2665 28.8131C13.2665 29.0629 13.0986 33.0001 6.1827 33.0001C-0.733179 33.0001 0.781943 26.8788 2.23228 24.4673C3.46205 22.3676 4.58162 20.2053 5.58644 17.9891C5.58644 17.9891 4.73237 15.0805 5.32995 13.2877C5.92754 11.4949 6.86623 9.45361 6.86623 9.45361L8.57305 11.8374H11.1326L13.0986 9.87536C13.0986 9.87536 15.7428 14.1418 14.1232 17.8965C14.1232 17.8965 18.5602 22.8451 18.5602 27.4539C18.5602 32.0628 15.6595 32.7423 14.1232 32.7423L20.9492 32.9129"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
        <path
          d="M18.0446 12.3486C18.0446 12.3486 17.7881 17.3832 15.3145 19.4311"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
        <path
          d="M22.1405 5.35234C22.1405 5.35234 22.9945 10.0458 19.4103 10.0458C15.8261 10.0458 17.956 4.66882 17.956 4.66882C17.956 4.66882 18.9833 1 22.2211 1C25.4589 1 27.5967 3.55958 28.8765 3.55958C30.1563 3.55958 32.2902 3.55958 32.2902 4.5842C32.2902 5.60883 34.5166 11.2462 24.5295 11.2462"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
        <path
          d="M26.6632 13.626C26.6632 13.626 26.2362 18.49 25.3834 20.6225C24.5307 22.755 26.6632 30.0094 26.6632 30.0094"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
        <path
          d="M20.69 17.1279C20.69 17.1279 20.5194 29.586 24.6153 32.6639H27.6865"
          stroke={color}
          strokeWidth={fontWeight}
          strokeMiterlimit="10"
        />
      </svg>
    </span>
  );
}

PetsIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

PetsIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.any,
};

export default React.memo(PetsIcon);
