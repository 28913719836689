import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading';

import { getLeadDetails, getLeadActivationTracking } from './request';
import ContactTracking, { ContactTrackingHeader } from './ContactTracking';
import PersonalInfo from './PersonalInfo';

import styles from './index.module.scss';
import FormTracking from './FormTracking';
import { Context } from '../context';

const LeadDetails = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [open, setOpen] = useState(false);
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(true);

  const { contextData, setContextData } = useContext(Context);
  const { details } = contextData;

  const handleGetLeadsDetails = () => {
    setLoading(true);
    Promise.all([getLeadDetails(uuid), getLeadActivationTracking(uuid)]).then(
      response => {
        setContextData({ details: response[0].data });
        setTracking(response[0].data);
        setLoading(false);
      },
    );
  };

  const handleOpenForm = () => {
    setOpen(!open);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleGetLeadsDetails();
  }, []);

  if (!details || loading) {
    return <Loading white flex />;
  }

  return (
    <div className={styles.ContactLeadContainer}>
      <Header title="Información del Lead" onBack>
        {details.productType === 'CAR' && (
          <Button
            variant="outlined"
            color="primary"
            className={styles.Quotation}
            onClick={() => navigate('quotation')}
          >
            Cotizar
          </Button>
        )}
      </Header>
      <div className={styles.Header}>
        <PersonalInfo data={details} />
      </div>
      <div className={styles.TrackingElements}>
        <div className={styles.TrackingTitle}>
          <Typography variant="h6" color="primary">
            Seguimiento de contacto
          </Typography>
          <Button
            variant="contained"
            className={styles.NewContactButton}
            onClick={handleOpenForm}
          >
            Nuevo intento
          </Button>
        </div>
        <div className={styles.ContactTracking}>
          {tracking.length > 0 &&
            tracking.map((element, index) => (
              <Accordion
                defaultExpanded={true}
                disableGutters={true}
                key={element.uuid}
                className={styles.Accordion}
                sx={
                  !(index % 2)
                    ? { background: '#f4f4f4', boxShadow: 0 }
                    : { background: '#fBfBfB', boxShadow: 0 }
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ContactTrackingHeader info={element} />
                </AccordionSummary>
                <AccordionDetails>
                  <ContactTracking info={element} />
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
      <Modal open={open} onClose={handleCloseForm}>
        <FormTracking
          style={styles.Modal}
          uuid={uuid}
          onOpen={handleOpenForm}
          onRefreshList={handleGetLeadsDetails}
        />
      </Modal>
    </div>
  );
};

export default React.memo(LeadDetails);
