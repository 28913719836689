import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Authorization from '../../components/Authorization';

import CarCatalogHomologation from '../CarCatalogHomologation';
import DynamicContent from '../DynamicContent';
import Notifications from '../Notifications';
import ComingSoon from '../ComingSoon';
import Dashboard from '../Dashboard';
import Kinsurers from '../Kinsurers';
import Multimedia from '../Multimedia';
import Leads from '../Leads';
import OpsReports from '../OpsReports';

const FramePage = () => {
  return (
    <Dashboard>
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/leads/*" element={<Leads />} />
        <Route path="/kinsurers/*" element={<Kinsurers />} />
        <Route
          path="/dynamicContent/:tabSelected/*"
          element={<DynamicContent />}
        />
        <Route path="/multimedia/*" element={<Multimedia />} />
        <Route path="/notifications/*" element={<Notifications />} />

        <Route element={<Authorization access="reports" white />}>
          <Route path="/reports" element={<OpsReports />} />
        </Route>
        <Route element={<Authorization access="homologate" white />}>
          <Route
            path="/car-catalog-homologate"
            element={<CarCatalogHomologation />}
          />
        </Route>
      </Routes>
    </Dashboard>
  );
};

export default React.memo(FramePage);
