import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Typography } from '@mui/material';

import CloudArrowUpIcon from '../../components/Icons/CloudArrowUpIcon';
import LightningIcon from '../../components/Icons/LightningIcon';
import Confirmation from '../../components/Confirmation';
import Header from '../../components/Header';
import theme from '../../theme';

import TriggerCard from './TriggerCard';
import UploadCard from './UploadCard';

import styles from './index.module.scss';
import { setTrigger } from './request';

const OpsReports = () => {
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleOpenConfirm = reportID => {
    setOpenConfirm(!openConfirm);
    if (!reportID) {
      setSelectedTrigger(null);
      return;
    }
    setSelectedTrigger(reportID);
  };

  const handleTrigger = () => {
    try {
      setTrigger(selectedTrigger);
      handleOpenBackDrop();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.ReportsContainer}>
      <Header title="Reportes" />
      <div className={styles.ActionsContainer}>
        <TriggerCard
          label="Kinsurers"
          icon={
            <LightningIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Accionar"
          onClick={() => handleOpenConfirm('kinsurers')}
        />
        <TriggerCard
          label="Kinsurers Tracking"
          icon={
            <LightningIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Accionar"
          onClick={() => handleOpenConfirm('kinsurersTracking')}
        />
        <TriggerCard
          label="Leads"
          icon={
            <LightningIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Accionar"
          onClick={() => handleOpenConfirm('leads')}
        />
        <TriggerCard
          label="Emisiones"
          icon={
            <LightningIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Accionar"
          onClick={() => handleOpenConfirm('policies')}
        />
      </div>
      <Typography variant="h5" color="primary">
        Subir reportes
      </Typography>
      <div className={styles.ActionsContainer}>
        <UploadCard
          label="Archivo de emisiones"
          icon={
            <CloudArrowUpIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Subir"
          reportID="SyncIssuanceData"
          confirm={handleOpenBackDrop}
        />
        <UploadCard
          label="Archivo de pagos"
          icon={
            <CloudArrowUpIcon color={theme.palette.neutral[5]} fontWeight="2" />
          }
          actionLabel="Subir"
          reportID="SyncPaymentsData"
          confirm={handleOpenBackDrop}
        />
      </div>
      <Dialog open={openConfirm} onClose={handleOpenConfirm}>
        <div className={styles.ConfirmModal}>
          <Typography variant="h6" component="p">
            ¿Está seguro de ejecutar esta acción?
          </Typography>
          <div>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleTrigger();
                handleOpenConfirm();
              }}
              sx={{ flex: 1 }}
            >
              Aceptar
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenConfirm}
              sx={{ flex: 1 }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Dialog>
      <Confirmation
        message="El reporte estará disponible en menos de 5 minutos en Slack"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
    </div>
  );
};

export default OpsReports;
