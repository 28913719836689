import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';
import Eraser from '../../Icons/Eraser';

import styles from './index.module.scss';

const Tags = ({
  style,
  label,
  onAddition,
  bgColor,
  bubbles,
  onDelete,
  error,
  reset,
  helperText,
}) => {
  const tagsInput = useRef();

  const handlePressEnter = event => {
    if (event.key === 'Enter') {
      const valid = onAddition(event.target.value);
      if (valid) {
        tagsInput.current.value = '';
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={style}
        className={classNames(styles.TagBorder, error && styles.TagBorderError)}
      >
        <Typography
          variant="caption"
          color={theme.palette.neutral[6]}
          component="span"
          style={{ backgroundColor: bgColor }}
        >
          {label}
        </Typography>
        <div className={styles.ControlInputContainer}>
          <input
            type="text"
            ref={tagsInput}
            onKeyDown={handlePressEnter}
            className={styles.tagInput}
          />
          <Tooltip title="Eliminar todo">
            <IconButton
              onClick={() => {
                reset();
                tagsInput.current.value = '';
              }}
            >
              <Eraser fontSize="24" color={theme.palette.primary.main} />
            </IconButton>
          </Tooltip>
        </div>
        <Stack className={styles.StackTags} direction="row" spacing={1}>
          {bubbles.length > 0 &&
            bubbles.map((bubble, index) => {
              return (
                <Chip
                  key={index}
                  label={bubble}
                  onDelete={() => {
                    onDelete(index);
                  }}
                />
              );
            })}
        </Stack>
      </div>
      {error && (
        <Typography
          variant="caption"
          color={theme.palette.error.main}
          component="span"
          className={styles.ErrorLabel}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};

Tags.propTypes = {
  bgColor: PropTypes.string,
  bubbles: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onAddition: PropTypes.func,
  onDelete: PropTypes.func,
  reset: PropTypes.func,
  style: PropTypes.any,
};

export default React.memo(Tags);
