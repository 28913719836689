import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowLeftIcon from '../Icons/ArrowLeftIcon';

import styles from './index.module.scss';

const Header = ({ title, subtitle, onBack, children, breadcrumbs }) => {
  const navigate = useNavigate();

  //   const breadcrumbs = [
  //     <Link underline="hover" key="1" color="inherit" href="/">
  //       MUI
  //     </Link>,
  //     <Link
  //       underline="hover"
  //       key="2"
  //       color="inherit"
  //       href="/material-ui/getting-started/installation/"
  //     >
  //       Core
  //     </Link>,
  //     <Typography key="3" color="text.primary">
  //       Breadcrumb
  //     </Typography>,
  //   ];

  return (
    <div className={styles.HeaderContainer}>
      <div className={styles.HeaderPrincipal}>
        {onBack && (
          <IconButton onClick={() => navigate(-1)} className={styles.ArrowIcon}>
            <ArrowLeftIcon fontSize="28" color="#4575ba" fontWeight="2" />
          </IconButton>
        )}
        <div>
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" color="primary">
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={styles.HeaderChildren}>{children}</div>
      </div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};

Header.propTypes = {
  breadcrumbs: PropTypes.any,
  children: PropTypes.any,
  onBack: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default React.memo(Header);
