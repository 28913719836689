import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header';

import ComingSoon from '../ComingSoon';
import IncentivesTab from './Incentives';
import TyCTab from './TyC';

import styles from './index.module.scss';

function TabPanel(props) {
  const { children, value, index } = props;
  return <>{value === index && <>{children}</>}</>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const DynamicContent = () => {
  const navigate = useNavigate();
  const { tabSelected } = useParams();

  const handleChangeTab = (_, tabValue) => {
    navigate(`/dashboard/dynamicContent/${tabValue}`);
  };

  return (
    <div className={styles.IncentivesContainer}>
      <Header title="Administración de contenidos">
        {tabSelected === 'incentives' && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('incentive')}
          >
            Crear nuevo
          </Button>
        )}
        {tabSelected === 'tyc' && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('details')}
          >
            Crear nuevo
          </Button>
        )}
      </Header>
      <div className={styles.ContentTabs}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabSelected} onChange={handleChangeTab}>
            <Tab
              value="incentives"
              label="Incentivos"
              sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            />
            <Tab
              value="tyc"
              label="Terminos y condiciones"
              sx={{ flex: 1, maxWidth: 'none' }}
            />
            <Tab
              value="blog"
              label="Blog"
              sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            />
          </Tabs>
        </Box>
      </div>
      <TabPanel value={tabSelected} index="incentives">
        <IncentivesTab />
      </TabPanel>
      <TabPanel value={tabSelected} index="tyc">
        <TyCTab />
      </TabPanel>
      <TabPanel value={tabSelected} index="blog">
        <div className={styles.ContentTabs}>
          <ComingSoon />
        </div>
      </TabPanel>
    </div>
  );
};

export default React.memo(DynamicContent);
