import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import CaretRightIcon from '../../../components/Icons/CaretRightIcon';
import Loading from '../../../components/Loading';
import Header from '../../../components/Header';

import { getKinsurerDetails, getKinsurerActivationTracking } from './request';
import ContactTracking, { ContactTrackingHeader } from './ContactTracking';
import CommentsBlock from './CommentsBlock';
import FormTracking from './FormTracking';

import styles from './index.module.scss';
import KinsurerInfo from './kinsurerInfo';

const KinsurerDetails = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [details, setDetails] = useState(null);
  const [tracking, setTracking] = useState([]);
  const [open, setOpen] = useState(false);
  const [showComments, setShowComments] = useState(true);

  const handleGetKinsurersDetails = () => {
    getKinsurerDetails(uuid).then(res => {
      setDetails(res.data);
    });
    getKinsurerActivationTracking(uuid).then(res => {
      setTracking(res.data);
    });
  };

  const handleOpenForm = () => {
    setOpen(!open);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };
  const handleOpenComments = () => {
    setShowComments(!showComments);
  };

  useEffect(() => {
    handleGetKinsurersDetails();
  }, []);

  if (!details) {
    return <Loading white />;
  }

  return (
    <div className={styles.ContactKinsurerContainer}>
      <Header title="Información del Kinsurer" onBack>
        <Button
          variant="outlined"
          color="primary"
          className={styles.ShowDocs}
          onClick={() => navigate('documents')}
        >
          Revisar documentos
        </Button>
      </Header>

      <KinsurerInfo details={details} />

      <div className={styles.TrackingAndCommentsContainer}>
        <div className={styles.TrackingElements}>
          <div className={styles.TrackingTitle}>
            <Typography variant="h6" color="primary">
              Seguimiento de contacto
            </Typography>
            <div>
              <Button
                variant="contained"
                className={styles.NewContactButton}
                onClick={handleOpenForm}
              >
                Nuevo intento
              </Button>
              {!showComments && (
                <Button
                  variant="text"
                  color="primary"
                  className={styles.NewContactButton}
                  onClick={handleOpenComments}
                >
                  Comentarios
                  <CaretRightIcon fontSize="24" color="#4575ba" />
                </Button>
              )}
            </div>
          </div>
          <div className={styles.ContactTracking}>
            {tracking.length > 0 &&
              tracking.map((element, index) => (
                <Accordion
                  defaultExpanded={true}
                  disableGutters={true}
                  key={element.uuid}
                  className={styles.Accordion}
                  sx={
                    !(index % 2)
                      ? { background: '#f4f4f4', boxShadow: 0 }
                      : { background: '#fBfBfB', boxShadow: 0 }
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ContactTrackingHeader info={element} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <ContactTracking info={element} />
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </div>
        {showComments && (
          <CommentsBlock
            open={showComments}
            onClose={handleOpenComments}
            uuid={uuid}
          />
        )}
      </div>
      <Modal open={open} onClose={handleCloseForm}>
        <FormTracking
          style={styles.Modal}
          uuid={uuid}
          onOpen={handleOpenForm}
          onRefreshList={handleGetKinsurersDetails}
        />
      </Modal>
    </div>
  );
};

export default React.memo(KinsurerDetails);
