import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';

import { updateKinsurerDocuments } from './request';
import { validations } from './validations';

import styles from './UpdateDialog.module.scss';

const UpdateDialog = ({ uuid, open, handleClose, document, updated }) => {
  const [loading, setLoading] = useState(false);
  const { status, name, documentId } = document;

  const form = useFormik({
    initialValues: {
      reasonForRejection: '',
    },
    validate: validations,
  });

  const handleSubmit = () => {
    setLoading(true);
    const values = {
      reasonForRejection: form.values.reasonForRejection,
      idDocument: documentId,
      name,
      newStatus: status,
    };
    updateKinsurerDocuments(uuid, values).then(res => {
      if (!res.notifications.requestMessage) {
        updated(res.data);
        setLoading(false);
        handleClose();
      }
      form.resetForm();
    });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={styles.UpdateDialogContainer}>
        {status === 'APPROVED' && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              ¿Esta seguro de aprobar este documento?
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Aceptar
            </Button>
          </>
        )}
        {status === 'REJECTED' && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              ¿Esta seguro de rechazar este documento?
            </Typography>
            <TextField
              name="reasonForRejection"
              label="Razón por rechazo"
              value={form.values.reasonForRejection}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={
                form.touched.reasonForRejection &&
                form.errors.reasonForRejection
              }
              helperText={
                form.touched.reasonForRejection &&
                form.errors.reasonForRejection
              }
              sx={{ marginTop: '15px' }}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              Aceptar
            </LoadingButton>
          </>
        )}
      </div>
    </Dialog>
  );
};

UpdateDialog.propTypes = {
  document: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  updated: PropTypes.func,
  uuid: PropTypes.string,
};

export default React.memo(UpdateDialog);
