import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';

import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';
import DocViewer from '../../../components/DocViewer';
import Loading from '../../../components/Loading';
import Header from '../../../components/Header';

import { getKinsurerDocuments } from './request';
import UpdateDialog from './UpdateDialog';
import File from './File';

import styles from './index.module.scss';

const CheckDocuments = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [selectedDocView, setSelectedDocView] = useState({});

  const handleGetDocs = () => {
    getKinsurerDocuments(uuid).then(res => {
      setDocuments(res.data?.documents);
    });
  };

  const handleOpenBackdrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleSetOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSetOpenDoc = () => {
    setOpenDoc(!openDoc);
  };

  const handleSetSelectedDocuments = (status, documentId, name) => {
    setSelectedDocument({ documentId, name, status });
    handleSetOpenDialog();
  };

  const handleSetSelectedDocView = (url, type, name) => {
    setSelectedDocView({ url, type, name });
    handleSetOpenDoc();
  };

  const updatedInfo = data => {
    setDocuments(data?.documents);
    handleOpenBackdrop();
  };

  useEffect(() => {
    handleGetDocs();
  }, []);

  useEffect(() => {
    if (openBackDrop) {
      setTimeout(() => {
        handleOpenBackdrop();
      }, 3000);
    }
  }, [openBackDrop]);

  if (!documents && documents.length === 0) {
    return <Loading white />;
  }

  return (
    <>
      <div className={styles.CheckDocumentsContainer}>
        <Header title="Documentos del Kinsurer" onBack>
          <Button
            variant="outlined"
            color="primary"
            className={styles.ShowDocs}
            onClick={() => navigate('upload')}
          >
            Expediente
          </Button>
        </Header>
        <div className={styles.DocsList}>
          {documents.length > 0 &&
            documents.map(element => {
              return (
                <File
                  key={element.id}
                  icon={element.uiConfig.iconKey}
                  title={element.uiConfig.title}
                  files={element.files}
                  documentId={element.id}
                  handleSelected={handleSetSelectedDocuments}
                  handleViewDoc={handleSetSelectedDocView}
                />
              );
            })}
        </div>
        <UpdateDialog
          open={openDialog}
          handleClose={handleSetOpenDialog}
          document={selectedDocument}
          uuid={uuid}
          updated={updatedInfo}
        />
      </div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0,0,0,0.8)',
        }}
        open={openBackDrop}
      >
        <div className={styles.BackdropContent}>
          <CheckCircleIcon fontSize="48" color="white" />
          <Typography variant="h4" component="p" gutterBottom>
            Información actualizada.
          </Typography>
        </div>
      </Backdrop>
      <DocViewer
        open={openDoc}
        handleClose={handleSetOpenDoc}
        document={selectedDocView}
      />
    </>
  );
};

export default React.memo(CheckDocuments);
