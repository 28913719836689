import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading';
import UserIcon from '../../../components/Icons/UserIcon';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import UserCircleIcon from '../../../components/Icons/UserCircleIcon';
import EnvelopeOpenIcon from '../../../components/Icons/EnvelopeOpenIcon';

import Form from './Form';
import styles from './index.module.scss';

import { Context } from '../context';

import theme from '../../../theme';

function InternalQuotation() {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { contextData, setContextData } = useContext(Context);
  const { details, quotationData, catalogues } = contextData;

  const handlerOnSubmit = ({ values, catalogues }) => {
    setContextData({
      quotationData: { ...quotationData, ...values },
      catalogues,
    });
    navigate(`/dashboard/leads/${uuid}/comparator`);
  };

  useEffect(() => {
    if (!details || !details?.fullName) {
      navigate(-1);
    }
  }, []);

  if (!details?.fullName) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Header title="Cotizador interno" onBack />
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.data}>
            <div className={styles.dataItem}>
              <UserCircleIcon color={theme.palette.primary.main} />
              <Typography color="neutral.7">{details.fullName}</Typography>
            </div>
            <div className={styles.dataItem}>
              <EnvelopeOpenIcon color={theme.palette.neutral[7]} />
              <Typography color="neutral.7">{details.email}</Typography>
            </div>
            <div className={styles.dataItem}>
              <PhoneIcon color={theme.palette.neutral[7]} />
              <Typography color="neutral.7">{details.phoneNumber}</Typography>
            </div>
            {details.referencedKinsurer && <div className={styles.divider} />}
          </div>
          {details.referencedKinsurer && (
            <div className={styles.data}>
              <Typography
                variant="h6"
                color="primary.main"
                className={styles.subTitle}
              >
                Kinsurer asociado
              </Typography>
              <div className={styles.dataItem}>
                <UserIcon color={theme.palette.primary.main} />
                <Typography color="neutral.7">
                  {details.referencedKinsurer.fullName}
                </Typography>
              </div>
              <div className={styles.dataItem}>
                <EnvelopeOpenIcon color={theme.palette.primary.main} />
                <Typography color="neutral.7">
                  {details.referencedKinsurer.email}
                </Typography>
              </div>
              <div className={styles.dataItem}>
                <PhoneIcon color={theme.palette.primary.main} />
                <Typography color="neutral.7">
                  {details.referencedKinsurer.phoneNumber}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className={styles.section}>
          <Form
            onSubmit={handlerOnSubmit}
            quotationData={quotationData}
            catalogues={catalogues}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(InternalQuotation);
