import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@mui/material';

import Select from '../../../components/Inputs/Select';

import { Context } from '../context';
import styles from './VehiclePanel.module.scss';
import { getBrands, getSubBrands, getVersions } from './request';

function VehiclePanel({ form }) {
  const { contextData, setContextData } = useContext(Context);
  const { catalogues } = contextData;

  const [brandsData, setBrandsData] = useState(catalogues.brandsData);
  const [subBrandsData, setSubBrandsData] = useState(catalogues.subBrandsData);
  const [versionsData, setVersionsData] = useState(catalogues.versionsData);

  const handleSort = (a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  };

  const handleGetBrands = model => {
    setBrandsData([]);
    setSubBrandsData([]);
    setVersionsData([]);
    form.setFieldValue('brand', '');
    form.setFieldValue('subBrand', '');
    form.setFieldValue('version', '');
    getBrands({ model }).then(({ data }) => {
      setBrandsData(data.sort(handleSort));
    });
  };

  const handleGetSubBrands = brand => {
    setSubBrandsData([]);
    setVersionsData([]);
    form.setFieldValue('subBrand', '');
    form.setFieldValue('version', '');
    getSubBrands({
      model: form.values.model,
      brand,
    }).then(({ data }) => {
      setSubBrandsData(data.sort(handleSort));
    });
  };

  const handleGetVersions = subBrand => {
    setVersionsData([]);
    form.setFieldValue('version', '');
    getVersions({
      model: form.values.model,
      brand: form.values.brand,
      subBrand,
    }).then(({ data }) => {
      const versions = data.sort(handleSort);
      setVersionsData(versions);
    });
  };

  const handleOnSubmit = () => {
    setContextData({
      catalogues: {
        modelsData: catalogues.modelsData,
        brandsData,
        subBrandsData,
        versionsData,
      },
    });
    form.handleSubmit();
  };

  return (
    <form className={styles.container}>
      <div className={styles.item}>
        <Typography>Año:</Typography>
        <Select
          name="model"
          size="small"
          value={form.values.model}
          onChange={event => {
            form.handleChange(event);
            handleGetBrands(event.target.value);
          }}
          error={form.touched.model && form.errors.model}
          helperText={form.touched.model && form.errors.model}
        >
          {catalogues.modelsData.map(model => (
            <MenuItem key={model} value={model}>
              {model}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.item}>
        <Typography>Marca:</Typography>
        <Select
          name="brand"
          size="small"
          disabled={!form.values.model}
          value={form.values.brand}
          loading={'true'}
          onChange={event => {
            form.handleChange(event);
            handleGetSubBrands(event.target.value);
          }}
          error={form.touched.brand && form.errors.brand}
          helperText={form.touched.brand && form.errors.brand}
        >
          {brandsData.map(brand => (
            <MenuItem key={brand.id} value={brand.id}>
              {brand.description}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.item}>
        <Typography>Modelo:</Typography>
        <Select
          name="subBrand"
          size="small"
          disabled={!form.values.brand}
          value={form.values.subBrand}
          loading={'true'}
          onChange={event => {
            form.handleChange(event);
            handleGetVersions(event.target.value);
          }}
          error={form.touched.subBrand && form.errors.subBrand}
          helperText={form.touched.subBrand && form.errors.subBrand}
        >
          {subBrandsData.map(subBrand => (
            <MenuItem key={subBrand.id} value={subBrand.id}>
              {subBrand.description}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.item}>
        <Typography>Versión:</Typography>
        <Select
          name="version"
          size="small"
          disabled={!form.values.subBrand}
          value={form.values.version}
          loading={'true'}
          onChange={event => {
            form.handleChange(event);
            handleOnSubmit(event);
          }}
          error={form.touched.version && form.errors.version}
          helperText={form.touched.version && form.errors.version}
        >
          {versionsData.map(version => (
            <MenuItem key={version.id} value={version.id}>
              {version.description}
            </MenuItem>
          ))}
        </Select>
      </div>
    </form>
  );
}

VehiclePanel.propTypes = {
  form: PropTypes.object,
};

export default React.memo(VehiclePanel);
