export const generalValidation = values => {
  const errors = {};
  return errors;
};

export const mediaValidation = values => {
  const errors = {};
  if (values.title === '') {
    errors.title = 'No puede quedarse vacio';
  }
  return errors;
};

export const TrainingValidation = values => {
  const errors = {};
  if (values.url === '') {
    errors.url = 'No puede quedarse vacio';
  }
  if (values.topic === '') {
    errors.topic = 'No puede quedarse vacio';
  }
  return errors;
};
