import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Select from '../../../components/Inputs/Select';
import { Context } from '../../../context/SessionContext';

import { contactResult, contactType, reasons } from './constants';
import { postKinsurerActivationTracking } from './request';
import { stringToBool } from '../../../utils/stringToBool';

import styles from './FormTracking.module.scss';
import classnames from 'classnames';

const FormTracking = ({ uuid, onOpen, onRefreshList, style }) => {
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(false);
  const [interested, setInterested] = useState(false);
  const [reasonsByValue, setReasonsByValue] = useState([]);
  const [contactResultByValue, setContactResultByValue] = useState([]);
  const { userData } = useContext(Context);

  const form = useFormik({
    initialValues: {
      createdDate: Date.now(),
      userId: userData.username,
      contactType: 1,
      isSuccessfulContact: false,
      contactResult: '',
      isInterested: false,
      reason: '',
      isSuccessfulActivation: false,
      comments: '',
    },
    onSubmit: values => handleFormSubmit(values),
  });

  const handleFormSubmit = values => {
    setLoading(true);
    postKinsurerActivationTracking(values, uuid).then(() => {
      setLoading(false);
      onRefreshList();
      onOpen();
    });
  };

  const handleTypeContact = () => {
    const filteredContacts = contactResult.filter(
      e => e.type === parseInt(form.values.contactType, 10),
    );
    setContactResultByValue(filteredContacts);
  };

  const handleIsInterested = () => {
    setInterested(stringToBool(form.values.isInterested));
    const filteredReasons = reasons.filter(element => {
      return element.bool === stringToBool(form.values.isInterested);
    });
    filteredReasons.push(reasons[9]);
    if (parseInt(form.values.contactType, 10) === 2) {
      const doublefilter = filteredReasons.filter(
        element => element.value !== 1,
      );
      setReasonsByValue(doublefilter);
    } else {
      setReasonsByValue(filteredReasons);
    }
  };

  const handleSetContact = value => {
    setContact(value);
  };

  useEffect(() => {
    handleTypeContact();
    handleIsInterested();
  }, [form.values.contactType]);

  useEffect(() => {
    handleIsInterested();
  }, [form.values.isInterested]);

  useEffect(() => {
    handleSetContact(stringToBool(form.values.isSuccessfulContact));
  }, [form.values.isSuccessfulContact]);

  return (
    <div className={classnames(styles.FormTrakingContainer, style)}>
      <form onSubmit={form.handleSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo de contacto:</FormLabel>
          <RadioGroup
            row
            aria-label="Tipo de contacto:"
            onChange={form.handleChange}
            name="contactType"
            value={form.values.contactType}
          >
            {Object.entries(contactType).map(value => (
              <FormControlLabel
                control={<Radio />}
                key={value[0]}
                value={value[0]}
                label={value[1]}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">¿Hubo contacto?</FormLabel>
          <RadioGroup
            row
            aria-label="¿Hubo contacto?"
            onChange={form.handleChange}
            name="isSuccessfulContact"
            value={form.values.isSuccessfulContact}
          >
            <FormControlLabel control={<Radio />} value="true" label="Si" />
            <FormControlLabel control={<Radio />} value="false" label="No" />
          </RadioGroup>
        </FormControl>
        {!contact && (
          <Select
            id="contactResult-select"
            value={form.values.contactResult}
            name="contactResult"
            label="Resultado:"
            margin="normal"
            onChange={form.handleChange}
            error={form.touched.contactResult && form.errors.contactResult}
            helperText={form.touched.contactResult && form.errors.contactResult}
          >
            {contactResultByValue.map(value => (
              <MenuItem key={value.code} value={value.code}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {contact && (
          <>
            <FormControl component="fieldset">
              <FormLabel component="legend">Está interesado:</FormLabel>
              <RadioGroup
                row
                aria-label="Está interesado:"
                onChange={form.handleChange}
                name="isInterested"
                value={form.values.isInterested}
              >
                <FormControlLabel
                  control={<Radio />}
                  value="true"
                  label={'Si'}
                />
                <FormControlLabel
                  control={<Radio />}
                  value="false"
                  label={'No'}
                />
              </RadioGroup>
            </FormControl>
            <Select
              id="reason-select"
              value={form.values.reason}
              name="reason"
              label="Razón:"
              margin="normal"
              onChange={form.handleChange}
              error={form.touched.reason && form.errors.reason}
              helperText={form.touched.reason && form.errors.reason}
            >
              {reasonsByValue.map(e => (
                <MenuItem key={e.label} value={e.value}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
            {interested && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Activación exitosa:</FormLabel>
                <RadioGroup
                  row
                  aria-label="Activación exitosa:"
                  onChange={form.handleChange}
                  name="isSuccessfulActivation"
                  value={form.values.isSuccessfulActivation}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="true"
                    label={'Si'}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="false"
                    label={'No'}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </>
        )}
        <TextField
          margin="normal"
          id="comments"
          label="Comentarios"
          name="comments"
          value={form.values.comments}
          onChange={form.handleChange}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          Guardar
        </LoadingButton>
      </form>
    </div>
  );
};

FormTracking.propTypes = {
  onOpen: PropTypes.func,
  onRefreshList: PropTypes.func,
  style: PropTypes.any,
  uuid: PropTypes.string,
};

export default FormTracking;
