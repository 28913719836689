import React from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo-white.png';
import styles from './index.module.scss';

const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  position: 'fixed',
  borderRadius: 0,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const NavBar = ({ open, toggleDrawer }) => {
  return (
    <AppBar position="absolute" open={open} sx={{ paddingLeft: '4px' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <div className={styles.Bar}>
          <img src={logo} alt="logo" width="100px" />
          <Link to={'/logout'}>Cerrar sesión</Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  image: PropTypes.string,
};

export default NavBar;
