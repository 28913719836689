import { isEmail, isPHONE, isUUIDv4 } from '../../../utils/validations';

export const validations = values => {
  const errors = {};
  if (
    !values.search ||
    (!isEmail(values.search) &&
      !isUUIDv4(values.search) &&
      !isPHONE(values.search))
  ) {
    errors.search =
      'No parece un "e-mail" ni un "uuid" valido, si es un numero de telefono siga el patrón +52 XXX XXX XXXX';
  }
  return errors;
};
