/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function LinkIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2928 8.92891L17.7677 6.45403C18.2783 5.94292 18.8847 5.53742 19.5521 5.26071C20.2195 4.98399 20.9348 4.84148 21.6573 4.84131C22.3798 4.84114 23.0953 4.98332 23.7628 5.25973C24.4303 5.53613 25.0368 5.94135 25.5477 6.45222C26.0586 6.9631 26.4638 7.56962 26.7402 8.23714C27.0166 8.90466 27.1588 9.6201 27.1586 10.3426C27.1584 11.0651 27.0159 11.7804 26.7392 12.4478C26.4625 13.1152 26.057 13.7216 25.5459 14.2322L22.0104 17.7677C21.4996 18.2785 20.8933 18.6836 20.226 18.96C19.5587 19.2364 18.8435 19.3787 18.1213 19.3787C17.399 19.3787 16.6838 19.2364 16.0165 18.96C15.3492 18.6836 14.7429 18.2785 14.2322 17.7677"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7071 23.0711L14.2322 25.546C13.7216 26.0571 13.1152 26.4626 12.4478 26.7393C11.7804 27.016 11.0651 27.1585 10.3426 27.1587C9.6201 27.1589 8.90466 27.0167 8.23714 26.7403C7.56962 26.4639 6.9631 26.0586 6.45222 25.5478C5.94135 25.0369 5.53613 24.4304 5.25973 23.7629C4.98332 23.0953 4.84114 22.3799 4.84131 21.6574C4.84148 20.9349 4.98399 20.2195 5.26071 19.5522C5.53742 18.8848 5.94292 18.2784 6.45403 17.7678L9.98957 14.2323C10.5003 13.7215 11.1066 13.3164 11.7739 13.04C12.4412 12.7636 13.1564 12.6213 13.8787 12.6213C14.6009 12.6213 15.3161 12.7636 15.9834 13.04C16.6507 13.3164 17.257 13.7215 17.7677 14.2323"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

LinkIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

LinkIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(LinkIcon);
