import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import trainingComponent from '../../../assets/images/training-component.png';
import PlusCircleIcon from '../../../components/Icons/PlusCircleIcon';
import Select from '../../../components/Inputs/Select';

import { TrainingValidation } from './validations';
import TrainingResource from './TrainingResource';
import CardContainer from '../CardContainer';
import { categories } from '../constants';

import styles from './index.module.scss';
import { deleteCategoryFormula, putStepsFormulaById } from './request';

const TrainingSection = ({ style, content, update }) => {
  const [newItem, setNewItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      topic: '',
      description: '',
      url: '',
    },
    validate: TrainingValidation,
    onSubmit: values => handleSubmit(values),
  });

  const handleNewItem = () => {
    setNewItem(!newItem);
  };

  const handleSubmit = values => {
    const putPayload = {
      training: {
        ...values,
      },
    };
    setLoading(true);
    putStepsFormulaById(content.uuid, putPayload).then(res => {
      if (!res.notifications.requestMessage) {
        update();
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setNewItem(false);
    });
  };

  const deleteResource = async categoryId => {
    try {
      const res = await deleteCategoryFormula(categoryId);
      if (!res.notifications.requestMessage) {
        update();
      } else {
        alert('Hubo un error por favor inténtelo más tarde');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CardContainer
      style={style}
      title="Capacitaciones"
      icon={<PlusCircleIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={handleNewItem}
      modalChild={<img src={trainingComponent} width="100%" />}
    >
      {newItem && (
        <form onSubmit={form.handleSubmit}>
          <Select
            id="topic"
            value={form.values.topic}
            name="topic"
            label="Categoría"
            margin="normal"
            size="small"
            onChange={form.handleChange}
            error={form.touched.topic && form.errors.topic}
            helperText={form.touched.topic && form.errors.topic}
          >
            {Object.entries(categories).map(value => (
              <MenuItem key={value[0]} value={value[0]}>
                {value[1]}
              </MenuItem>
            ))}
          </Select>
          <TextField
            id="description"
            name="description"
            label="Descripción"
            value={form.values.description}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.description && form.errors.description}
            helperText={form.touched.description && form.errors.description}
          />
          <TextField
            id="url"
            name="url"
            label="Link"
            value={form.values.url}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.url && form.errors.url}
            helperText={form.touched.url && form.errors.url}
          />
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button variant="outlined" color="primary" onClick={handleNewItem}>
              Cancelar
            </Button>
          </div>
        </form>
      )}
      {content.training?.length > 0 &&
        content.training.map((element, index) => {
          return (
            <TrainingResource
              key={`${element.uuid}-${index}`}
              onDelete={deleteResource}
              resource={element}
              contentId={content.uuid}
              update={update}
            />
          );
        })}
    </CardContainer>
  );
};

TrainingSection.propTypes = {
  content: PropTypes.object,
  style: PropTypes.any,
  update: PropTypes.func,
};

export default React.memo(TrainingSection);
