import React, { useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';

import HDILogo from '../../../assets/images/HDI.png';
import axxaLogo from '../../../assets/images/axa-logo.png';
import atlasLogo from '../../../assets/images/segurosatlas.png';
import FloppyDiskIcon from '../../../components/Icons/FloppyDiskIcon';

import theme from '../../../theme';
import styles from './InsurantConfiguration.module.scss';

const logos = {
  hdi: HDILogo,
  axxa: axxaLogo,
  atlas: atlasLogo,
};

function InsurantEmpty({ insuranceName, quotationData, onComplete }) {
  const [update, setUpdate] = useState(false);

  const { paymentType } = quotationData;
  let subsequent = '11';

  switch (paymentType) {
    case 'SEMI-ANNUAL':
      subsequent = 1;
      break;
    case 'QUARTERLY':
      subsequent = 3;
      break;
    default:
      subsequent = '11';
      break;
  }

  const form = useFormik({
    initialValues: {
      subsequentReceipt: null,
      firstReceipt: null,
      total: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
      13: null,
      14: null,
      15: null,
    },
  });

  const handleChange = event => {
    form.handleChange(event);
    if (!update) {
      setUpdate(true);
    }
  };

  const handleAddQuotation = () => {
    const quotationStructure = {
      premium: {
        total: form.values.total,
      },
      firstReceipt: {
        premium: { total: form.values.firstReceipt },
      },
      subsequentReceipt: {
        premium: { total: form.values.subsequentReceipt },
      },
      coverages: [
        { id: 1, sumInsured: form.values[1] },
        { id: 2, sumInsured: form.values[2] },
        { id: 3, sumInsured: form.values[3] },
        { id: 4, sumInsured: form.values[4] },
        { id: 5, sumInsured: form.values[5] },
        { id: 6, sumInsured: form.values[6] },
        { id: 7, sumInsured: form.values[7] },
        { id: 8, sumInsured: form.values[8] },
        { id: 9, sumInsured: form.values[9] },
        { id: 10, sumInsured: form.values[10] },
        { id: 11, sumInsured: form.values[11] },
        { id: 12, sumInsured: form.values[12] },
        { id: 13, sumInsured: form.values[12] },
        { id: 14, sumInsured: form.values[14] },
        { id: 15, sumInsured: form.values[15] },
      ],
    };
    console.log(form.values);
    onComplete(quotationStructure, insuranceName);
    setUpdate(false);
  };

  return (
    <form
      onSubmit={form.handleSubmit}
      className={classNames(
        styles.container,
        update && styles.containerModified,
      )}
    >
      <div className={styles.update}>
        <Tooltip title="Guardar">
          <IconButton onClick={handleAddQuotation}>
            <FloppyDiskIcon
              color={update && theme.palette.warning[3]}
              fontWeight="2"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div className={styles.itemHeader}>
        <img src={logos[insuranceName]} className={styles.logo} />
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          Complemente con información de la cotización
        </Typography>
      </div>
      <div className={classNames(styles.item, styles.itemExtra)}>
        {paymentType !== 'ANNUAL' && (
          <TextField
            size="small"
            margin="none"
            name="subsequentReceipt"
            label={`${subsequent} pagos subsecuentes de:`}
            onChange={e => handleChange(e)}
            value={form.values.subsequentReceipt}
            InputProps={{
              endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      </div>
      <div className={styles.item}>
        {paymentType !== 'ANNUAL' && (
          <TextField
            size="small"
            margin="none"
            name="firstReceipt"
            onChange={e => handleChange(e)}
            value={form.values.firstReceipt}
            InputProps={{
              endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="total"
          onChange={e => handleChange(e)}
          value={form.values.total}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="1"
          onChange={e => handleChange(e)}
          value={form.values[1]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="2"
          onChange={e => handleChange(e)}
          value={form.values[2]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="3"
          onChange={e => handleChange(e)}
          value={form.values[3]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="4"
          label="Deducible V. COMERCIAL"
          onChange={e => handleChange(e)}
          value={form.values[4]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="5"
          label="Deducible V. COMERCIAL"
          onChange={e => handleChange(e)}
          value={form.values[5]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="6"
          onChange={e => handleChange(e)}
          value={form.values[6]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="7"
          onChange={e => handleChange(e)}
          value={form.values[7]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="8"
          onChange={e => handleChange(e)}
          value={form.values[8]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="9"
          onChange={e => handleChange(e)}
          value={form.values[9]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="10"
          onChange={e => handleChange(e)}
          value={form.values[10]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="11"
          onChange={e => handleChange(e)}
          value={form.values[11]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="12"
          onChange={e => handleChange(e)}
          value={form.values[12]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="13"
          onChange={e => handleChange(e)}
          value={form.values[13]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="14"
          onChange={e => handleChange(e)}
          value={form.values[14]}
        />
      </div>
      <div className={styles.item}>
        <TextField
          size="small"
          margin="none"
          name="15"
          onChange={e => handleChange(e)}
          value={form.values[15]}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
    </form>
  );
}

InsurantEmpty.propTypes = {
  insuranceName: PropTypes.string,
  onComplete: PropTypes.func,
  quotationData: PropTypes.object,
};

export default React.memo(InsurantEmpty);
