/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable no-throw-literal */

import { Auth } from 'aws-amplify';

const messages = {
  201: 'Creado exitosamente',
  204: 'Creado exitosamente sin respuesta',
  400: 'Petición erronea y/o datos incorrectos',
  401: 'Petición no autorizada.',
  403: 'Petición rechazada por el servidor.',
  404: 'Recurso no disponible y/o fuera de linea',
  500: 'Error en la ejecución del servicio',
};

export default async function request(url, options = {}) {
  let accessToken = null;
  let idToken = null;

  if (!options.withOutAuth) {
    await Auth.currentSession().then(res => {
      accessToken = res.getAccessToken();
      idToken = res.getIdToken();
    });
  }

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      ...(!options.withOutAuth && {
        Authorization: `Bearer ${accessToken.jwtToken}`,
        IDToken: idToken.jwtToken,
      }),
    },
  })
    .then(async response => {
      const status = response.status;
      const contentType = response.headers.get('content-type');

      if (status === 401) {
        const origin = window.location.origin;
        window.location.assign(`${origin}/logout`);
        return null;
      }

      if (status !== 200 && status !== 201 && status !== 204) {
        const { message } = await response.json();
        throw {
          data: null,
          notifications: {
            message,
            requestCode: status,
            requestMessage: messages[status] || 'No especificado',
            requestBody: options.body,
          },
        };
      }

      let data = null;
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        data = await response;
      }
      return {
        data: data.data || data,
        notifications: {
          pagination: data.pagination || null,
          configOptions: data.configOptions || null,
        },
      };
    })
    .catch(err => {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          options.method || 'GET',
          'requestTo:',
          url,
          'Error: ',
          JSON.stringify(err),
        );
      }
      return err;
    });
}
