/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classnames from 'classnames';
import {
  Typography,
  MenuItem,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';

import Select from '../../../components/Inputs/Select';
import Placeholder from '../../../components/Placeholder';
import ArrowClockwise from '../../../components/Icons/ArrowsClockwiseIcon';

import theme from '../../../theme';
import { getQuotation, getVersionsNoHomologate } from './request';
import { toMoney, stringToNumber } from '../../../utils/format';
import styles from './InsurantConfiguration.module.scss';
import AnaLogo from '../../../assets/images/ana-logo.png';
import ChubbLogo from '../../../assets/images/chubb-logo.png';
import MapfreLogo from '../../../assets/images/mapfre-logo.png';
import QualitasLogo from '../../../assets/images/qualitas-logo.png';

const logos = {
  qualitas: QualitasLogo,
  ana: AnaLogo,
  mapfre: MapfreLogo,
  chubb: ChubbLogo,
};

const itemStep = 5;

const EmptyRowsGroup = () => {
  return (
    <>
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
      <div className={styles.item} />
    </>
  );
};

function InsurantConfiguration({
  quotationData,
  details,
  insuranceName,
  onComplete,
}) {
  const [insuranceData, setInsuranceData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [versionsData, setVersionsData] = useState(null);
  const [versionError, setVersionsError] = useState(null);
  const [versionSelected, setVersionSelected] = useState(null);

  const form = useFormik({
    initialValues: {
      discount: 0,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
      11: null,
      12: null,
      13: null,
      14: null,
      15: null,
    },
    // validate: TrainingValidation,
    onSubmit: () => {
      console.log('SE LLAMO SUBMIT');
    },
  });

  const handleChange = event => {
    form.handleChange(event);
    if (!update) {
      setUpdate(true);
    }
  };

  const handleGerVersionId = versionId => {
    const external = typeof versionId === 'string' ? versionId : null;

    if (versionSelected && !external) {
      return versionSelected;
    }
    if (external) {
      setVersionSelected(external);
      return external;
    }
    return null;
  };

  const handleGetQuotation = versionId => {
    const externalId = handleGerVersionId(versionId);

    setLoading(true);
    setError(false);
    setUpdate(false);

    getQuotation({
      insuranceName,
      quotationData,
      coveragesConfigurable:
        configData?.coverages.filter(
          configItem => configItem.isConfigurable && configItem.id !== 0,
        ) || [],
      details,
      formData: form.values,
      update,
      externalId,
    }).then(({ data, notifications }) => {
      if (data) {
        setInsuranceData(data);
        setConfigData(notifications.configOptions);
        form.setFieldValue(
          'discount',
          data.discount || notifications.configOptions.maxAvailableDiscount,
        );
        notifications.configOptions.coverages.forEach(coverage => {
          if (coverage.type === 'BOOL') {
            form.setFieldValue(coverage.id, coverage.isSelectedByDefault);
          }
          if (
            coverage.type === 'FREE' ||
            coverage.type === 'SUM' ||
            coverage.type === 'DED'
          ) {
            form.setFieldValue(coverage.id, 0);
          }
        });
        setLoading(false);
        data.coverages.forEach(coverage => {
          if (coverage.deductiblePercentage === 0 && coverage.sumInsured) {
            form.setFieldValue(
              coverage.id,
              stringToNumber(coverage.sumInsured),
            );
            return null;
          }
          if (coverage.deductiblePercentage > 0 && coverage.sumInsured) {
            form.setFieldValue(coverage.id, coverage.deductiblePercentage);
            return null;
          }
          form.setFieldValue(coverage.id, coverage.sumInsured || 0);
          return null;
        });
      } else {
        setError(notifications);
        setLoading(false);
        if (notifications.requestCode === 422) {
          getVersionsNoHomologate({
            insurance: insuranceName,
            model: quotationData.model,
            brand: quotationData.brand,
            subBrand: quotationData.subBrand,
          }).then(({ data, notifications }) => {
            if (data) {
              setVersionsData(data);
            } else {
              setVersionsError(notifications);
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    onComplete(insuranceData, insuranceName);
  }, [form.values]);

  useEffect(() => {
    handleGetQuotation();
    setVersionsError(null);
    setVersionSelected(null);
    setVersionsData(null);
  }, [quotationData]);

  if ((!insuranceData || !configData || loading) && !error) {
    return (
      <div className={styles.container}>
        <div className={styles.itemHeader}>
          <img src={logos[insuranceName]} className={styles.logo} />
          <Typography variant="h6">Cotizando...</Typography>
          <Typography variant="caption">Por favor espere</Typography>
        </div>
        <div className={classnames(styles.item, styles.itemExtra)} />
        <div className={styles.placeholder}>
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
        </div>
      </div>
    );
  }

  if (!insuranceData && !configData && error && error.requestCode !== 422) {
    return (
      <div
        className={classnames(
          styles.container,
          update && styles.containerModified,
          error && styles.containerError,
        )}
      >
        <div className={styles.update}>
          <IconButton onClick={handleGetQuotation}>
            <ArrowClockwise
              color={
                (update && theme.palette.warning[3]) ||
                (error && theme.palette.error[5])
              }
              fontWeight="2"
            />
          </IconButton>
        </div>
        <div className={styles.itemHeader}>
          <img src={logos[insuranceName]} className={styles.logo} />
          <Typography variant="h6">Error {error.requestCode}</Typography>
          <Tooltip title={error.message}>
            <Typography variant="caption" color="error">
              {error.message.slice(0, 140)}
            </Typography>
          </Tooltip>
        </div>
        <div className={classnames(styles.item, styles.itemExtra)} />
        <EmptyRowsGroup />
      </div>
    );
  }

  if (error && error.requestCode === 422) {
    return (
      <div className={classnames(styles.container)}>
        {!versionError && (
          <>
            <div className={styles.itemHeader}>
              <img src={logos[insuranceName]} className={styles.logo} />
              <Typography variant="h6">Sin homologación</Typography>
              <Tooltip title={error.message}>
                <Typography variant="caption">
                  Seleccione una versión
                </Typography>
              </Tooltip>
            </div>
            <div className={classnames(styles.item, styles.itemExtra)}>
              <Select
                size="small"
                onChange={event => {
                  handleGetQuotation(event.target.value);
                }}
              >
                {versionsData?.insuranceVersions.map(item => {
                  return (
                    <MenuItem key={item.versionId} value={item.versionId}>
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </>
        )}
        {versionError && (
          <>
            <div className={styles.itemHeader}>
              <img src={logos[insuranceName]} className={styles.logo} />
              <Typography variant="h6">{versionError.requestCode}</Typography>
              <Tooltip title={error.message}>
                <Typography variant="caption" color="error" textAlign="center">
                  Detalle: {versionError.message}
                </Typography>
              </Tooltip>
            </div>
            <div className={styles.item} />
          </>
        )}
        <EmptyRowsGroup />
      </div>
    );
  }

  return (
    <form
      className={classnames(
        styles.container,
        update && styles.containerModified,
        error && styles.containerError,
      )}
    >
      {(update || error) && (
        <div className={styles.update}>
          <IconButton onClick={handleGetQuotation}>
            <ArrowClockwise
              color={
                (update && theme.palette.warning[3]) ||
                (error && theme.palette.error[5])
              }
              fontWeight="2"
            />
          </IconButton>
        </div>
      )}
      <div className={styles.itemHeader}>
        <img src={logos[insuranceData.insuranceName]} className={styles.logo} />
        {error && (
          <>
            <Typography variant="h6">Error {error.requestCode}</Typography>
            <Tooltip title={error.message}>
              <Typography variant="caption" color="error">
                {error.message.slice(0, 20)}...
              </Typography>
            </Tooltip>
          </>
        )}
        {!error && insuranceData.paymentType !== 'ANNUAL' && (
          <>
            <Typography variant="caption">
              {insuranceData.numberOfSubsequentReceipts} pagos de
            </Typography>
            <Typography variant="h6">
              {toMoney(
                insuranceData.subsequentReceipt.premium.total,
                insuranceData.currency,
              )}
            </Typography>
          </>
        )}
        {!error && insuranceData.paymentType === 'ANNUAL' && (
          <Typography variant="h6">
            {toMoney(insuranceData.premium.total, insuranceData.currency)}
          </Typography>
        )}
        {versionSelected && versionsData && (
          <div className={styles.versionSelectedInput}>
            <Select
              size="small"
              value={versionSelected}
              label="Versión"
              onChange={event => {
                handleGetQuotation(event.target.value);
              }}
            >
              {versionsData?.insuranceVersions.map(item => {
                return (
                  <MenuItem key={item.versionId} value={item.versionId}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
      </div>

      <div className={classnames(styles.item, styles.itemExtra)}>
        <Select
          size="small"
          name="discount"
          label={
            insuranceData.realDiscount ? 'Descuento solicitado' : 'Descuento'
          }
          value={form.values.discount}
          onChange={handleChange}
        >
          {[...Array(configData.maxAvailableDiscount / itemStep)].map(
            (x, i) => (
              <MenuItem key={x} value={(i + 1) * itemStep}>
                {(i + 1) * itemStep} %
              </MenuItem>
            ),
          )}
        </Select>
        {insuranceData.realDiscount && (
          <Typography variant="caption" color="error">
            Real aplicado {insuranceData.realDiscount}%
          </Typography>
        )}
      </div>

      <Typography className={styles.item}>
        {toMoney(
          insuranceData.firstReceipt.premium.total,
          insuranceData.currency,
        )}
      </Typography>

      <Typography className={styles.item}>
        {toMoney(insuranceData.premium.total, insuranceData.currency)}
      </Typography>

      {configData.coverages.map((coverage, index) => {
        if (coverage.id === 0) {
          return null;
        }
        return (
          <div className={styles.item} key={`${insuranceName}${index}`}>
            {coverage.type === 'SUM' && coverage.isConfigurable && (
              <Select
                size="small"
                name={coverage.id}
                value={form.values[coverage.id]}
                onChange={handleChange}
              >
                {coverage.availableSumInsured?.map(value => (
                  <MenuItem key={coverage.id} value={value}>
                    {toMoney(value, insuranceData.currency)}
                  </MenuItem>
                ))}
              </Select>
            )}

            {coverage.type === 'SUM' && !coverage.isConfigurable && (
              <Typography>
                {toMoney(
                  insuranceData.coverages.find(item => item.id === coverage.id)
                    ?.sumInsured,
                  insuranceData.currency,
                )}
              </Typography>
            )}

            {coverage.type === 'FREE' && (
              <TextField
                name={coverage.id}
                size="small"
                margin="normal"
                onChange={handleChange}
                value={form.values[coverage.id]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">MXN</InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}

            {coverage.type === 'BOOL' && coverage.isConfigurable && (
              <Checkbox
                name={coverage.id}
                checked={form.values[coverage.id]}
                onChange={event => {
                  handleChange({
                    target: {
                      name: event.target.name,
                      value: event.target.checked,
                    },
                  });
                }}
              />
            )}

            {coverage.type === 'BOOL' &&
              !coverage.isConfigurable &&
              toMoney(
                insuranceData.coverages.find(item => item.id === coverage.id)
                  ?.sumInsured,
                insuranceData.currency,
              )}

            {coverage.type === 'NULL' && <Typography>N/A</Typography>}

            {coverage.type === 'DED' && coverage.isConfigurable && (
              <Select
                size="small"
                label={`Deducible ${
                  insuranceData.coverages.find(item => item.id === coverage.id)
                    ?.sumInsured
                }`}
                name={coverage.id}
                value={form.values[coverage.id]}
                onChange={handleChange}
              >
                {coverage.availableDeductible.map(value => (
                  <MenuItem key={`${coverage.id}-${value}`} value={value}>
                    {value} %
                  </MenuItem>
                ))}
              </Select>
            )}

            {coverage.type === 'DED' && !coverage.isConfigurable && (
              <Typography>
                {`${form.values[coverage.id]} % ${
                  insuranceData.coverages.find(item => item.id === coverage.id)
                    ?.sumInsured
                }`}
              </Typography>
            )}
          </div>
        );
      })}
    </form>
  );
}

InsurantConfiguration.propTypes = {
  insuranceName: PropTypes.string,
  quotationData: PropTypes.object,
  details: PropTypes.object,
  onComplete: PropTypes.func,
};

export default React.memo(InsurantConfiguration);
