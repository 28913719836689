export const contactType = {
  1: 'Llamada',
  2: 'Whatsapp',
};
export const contactResult = [
  { code: 'CD', label: 'Contacto Directo', type: 1, result: true },
  { code: 'MG', label: 'Mensaje Grabadora', type: 1, result: false },
  { code: 'NE', label: 'Número equivocado', type: 1, result: false },
  { code: 'NS', label: 'Número equivocado', type: 1, result: false },
  { code: 'MF', label: 'Mensaje familiar', type: 1, result: false },
  { code: 'CU', label: 'Cuelga', type: 1, result: false },
  { code: 'NI', label: 'Número inexistente', type: 1, result: false },
  { code: 'CW', label: 'Contacto por whatsapp', type: 2, result: true },
];

export const statusLead = {
  PENDING: 'Pendiente',
  PENDIND_QUOTED: 'Pendiente cotizado',
  QUOTED: 'Cotizado',
  NOT_CONTACTED: 'No contactado',
  NOT_INTERESTED: 'No interesado',
  ISSUANCE_IN_PROGRESS: 'Pendiente de emisión',
  ISSUED: 'Emitido',
};

export const types = {
  CAR: 'Auto',
  HEALTH: 'Salud',
  HOME: 'Hogar',
  MOTORCYCLE: 'Moto',
  PETS: 'Mascotas',
  LIFE: 'Vida',
  RETIREMENT: 'Ahorro y Retiro',
  TRAVEL: 'Viajes'
};
