export const titleValidation = values => {
  const errors = {};
  if (values.description === '') {
    errors.description = 'No puede quedarse vacio';
  }
  return errors;
};

export const contactValidation = values => {
  const errors = {};
  if (values.summary === '') {
    errors.summary = 'No puede quedarse vacio';
  }
  if (values.email === '') {
    errors.email = 'No puede quedarse vacio';
  }
  if (values.phone === '') {
    errors.phone = 'No puede quedarse vacio';
  }
  return errors;
};

export const appInfoValidation = values => {
  const errors = {};
  if (values.summary === '') {
    errors.summary = 'No puede quedarse vacio';
  }
  if (values.title === '') {
    errors.title = 'No puede quedarse vacio';
  }
  return errors;
};

export const mediaValidation = values => {
  const errors = {};
  if (values.summary === '') {
    errors.summary = 'No puede quedarse vacio';
  }
  if (values.title === '') {
    errors.title = 'No puede quedarse vacio';
  }
  return errors;
};

export const TrainingValidation = values => {
  const errors = {};
  if (values.url === '') {
    errors.url = 'No puede quedarse vacio';
  }
  if (values.topic === '') {
    errors.topic = 'No puede quedarse vacio';
  }
  if (values.description === '') {
    errors.description = 'No puede quedarse vacio';
  }
  return errors;
};
