import { StyleSheet, Font } from '@react-pdf/renderer';
import theme from '../../theme';

import gelionBold from '../../assets/fonts/Gelion-Bold.ttf';
import gelion from '../../assets/fonts/Gelion-Regular.ttf';

Font.register({
  family: 'Gelion',
  fonts: [{ src: gelion }, { src: gelionBold, fontWeight: 600 }],
});

export const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 15,
    fontFamily: 'Gelion',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerImage: {
    height: 48,
  },
  logo: {
    height: 48,
  },
  quotation: {
    display: 'flex',
    marginTop: 15,
    flexDirection: 'row',
  },
  Footer: {
    display: 'flex',
    fontSize: 10,
    textAlign: 'center',
    borderTop: 1,
    borderColor: theme.palette.primary.main,
  },
  KinsuBottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  KinsuText: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginLeft: 15,
    marginRight: 15,
  },
  TextBlue: {
    color: theme.palette.primary.main,
  },
  logoBottom: {
    width: 70,
  },
  CloseArrow: {
    position: 'relative',
    top: 3,
    width: 8,
  },
});
