import PropTypes from 'prop-types';
import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';

import Options from './Options';
import Table from './Table';

import { styles } from './RenderQuotation.styles';
import { splitArrayIntoChunks } from '../../utils/splitArrayIntoChunks';

const headerAuto =
  'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/header-auto.png';
const logo =
  'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/logo.png';
const closeLogo =
  'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/flecha.png';

const RenderQuotation = ({ resumeData, coverage }) => {
  const coverageToUser = coverage.filter(
    element => element.id === resumeData.quotationData.coverage,
  );

  let quotationLength = 3;
  if (
    Object.keys(resumeData.resumeData).length > 6 ||
    Object.keys(resumeData.resumeData).length === 4
  ) {
    quotationLength = 4;
  }
  const pages = splitArrayIntoChunks(
    Object.entries(resumeData.resumeData),
    quotationLength,
  );

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {pages.map((page, index) => {
          return (
            <Page
              key={index}
              size={'legal'}
              style={styles.page}
              orientation="landscape"
            >
              <View style={styles.header}>
                <Image style={styles.headerImage} source={`${headerAuto}`} />
                <Image style={styles.logo} source={`${logo}`} />
              </View>

              <View style={styles.quotation}>
                <Options
                  values={{
                    ...resumeData.quotationData,
                    ...resumeData.details,
                    readCoverage: coverageToUser[0].description,
                  }}
                />
                <Table
                  values={page}
                  payment={resumeData.quotationData.paymentType}
                />
              </View>

              <View style={styles.KinsuBottom}>
                <Image style={styles.logoBottom} source={`${logo}`} />
                <Text style={styles.KinsuText}>cuidarnos nos acerca.</Text>
                <Image style={styles.CloseArrow} source={`${closeLogo}`} />
              </View>

              <View style={styles.Footer}>
                <Text>
                  Si tienes dudas sobre cómo cotizar productos en nuestra
                  plataforma contáctanos por mail{' '}
                  <Text style={styles.TextBlue}>hola@kinsu.mx</Text> o por
                  WhatsApp al{' '}
                  <Text style={styles.TextBlue}>+52 1 55 2814 2935</Text>
                </Text>
              </View>
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
};

RenderQuotation.propTypes = {
  coverage: PropTypes.array,
  resumeData: PropTypes.object,
};

export default RenderQuotation;
