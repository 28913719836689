import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import trainingComponent from '../../../assets/images/training-component.png';
import PlusCircleIcon from '../../../components/Icons/PlusCircleIcon';
import { Context } from '../../../context/GeneralContext';
import Select from '../../../components/Inputs/Select';

import { TrainingValidation } from './validations';
import TrainingResource from './TrainingResource';
import CardContainer from '../CardContainer';
import { putFormulaArray } from './request';
import { categories } from '../constants';

import styles from './index.module.scss';

const TrainingSection = ({ style }) => {
  const { generalData, setCategoryFormulaWeb } = useContext(Context);
  const [newItem, setNewItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formula } = generalData;
  const { training } = formula;

  const form = useFormik({
    initialValues: {
      topic: '',
      description: '',
      url: '',
    },
    validate: TrainingValidation,
    onSubmit: values => addNewResource(values),
  });

  const handleNewItem = () => {
    setNewItem(!newItem);
  };

  const handleRequest = array => {
    putFormulaArray(training.uuid, training.category, array).then(res => {
      if (!res.notifications.requestMessage) {
        setCategoryFormulaWeb(training.category, res.data.uuid.Attributes);
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
    });
  };

  const addNewResource = values => {
    setLoading(true);
    handleRequest([...training.content, values]);
    form.resetForm();
    handleNewItem();
  };

  const deleteResource = index => {
    const newArray = [
      ...training.content.slice(index + 1),
      ...training.content.slice(0, index),
    ];
    handleRequest(newArray);
  };

  const handleEditResource = (values, index) => {
    setLoading(true);
    const newArray = [
      ...training.content.slice(0, index),
      (training.content[index] = values),
      ...training.content.slice(index + 1),
    ];
    handleRequest(newArray);
  };

  return (
    <CardContainer
      style={style}
      title={training.description}
      icon={<PlusCircleIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={handleNewItem}
      modalChild={<img src={trainingComponent} width="100%" />}
    >
      {newItem && (
        <form onSubmit={form.handleSubmit}>
          <Select
            id="topic"
            value={form.values.topic}
            name="topic"
            label="Categoría"
            margin="normal"
            size="small"
            onChange={form.handleChange}
            error={form.touched.topic && form.errors.topic}
            helperText={form.touched.topic && form.errors.topic}
          >
            {Object.entries(categories).map(value => (
              <MenuItem key={value[0]} value={value[0]}>
                {value[1]}
              </MenuItem>
            ))}
          </Select>
          <TextField
            id="description"
            name="description"
            label="Descripción"
            value={form.values.description}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.description && form.errors.description}
            helperText={form.touched.description && form.errors.description}
          />
          <TextField
            id="url"
            name="url"
            label="Link"
            value={form.values.url}
            onChange={form.handleChange}
            margin="normal"
            size="small"
            error={form.touched.url && form.errors.url}
            helperText={form.touched.url && form.errors.url}
          />
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button variant="outlined" color="primary" onClick={handleNewItem}>
              Cancelar
            </Button>
          </div>
        </form>
      )}
      {training.content?.length > 0 &&
        training.content.map((element, index) => {
          return (
            <TrainingResource
              key={`${element.topic}-${index}`}
              index={index}
              content={element}
              onSubmit={handleEditResource}
              onDelete={deleteResource}
              loading={loading}
            />
          );
        })}
    </CardContainer>
  );
};

TrainingSection.propTypes = {
  style: PropTypes.any,
};

export default React.memo(TrainingSection);
