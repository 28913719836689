import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import titleComponent from '../../../assets/images/title-component.png';
import NotePencilIcon from '../../../components/Icons/NotePencilIcon';
import { Context } from '../../../context/GeneralContext';
import { titleValidation } from './validations';
import CardContainer from '../CardContainer';
import { putFormula } from './request';

import styles from './index.module.scss';

const TitleForm = ({ style }) => {
  const { generalData, setCategoryFormulaWeb } = useContext(Context);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { formula } = generalData;
  const { header } = formula;

  const form = useFormik({
    initialValues: {
      description: header.content.description,
    },
    validate: titleValidation,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = values => {
    setLoading(true);
    putFormula(header.uuid, header.category, values).then(res => {
      if (!res.notifications.requestMessage) {
        setCategoryFormulaWeb(header.category, res.data.uuid.Attributes);
      } else {
        alert('Hubo un error por favor intentelo más tarde');
        window.location.reload();
      }
      setLoading(false);
      setShow(false);
      setDisabled(true);
    });
  };

  const showEditButtons = () => {
    setDisabled(false);
    setShow(true);
  };
  const closeEditButtons = () => {
    form.resetForm();
    setShow(false);
    setDisabled(true);
  };

  return (
    <CardContainer
      style={style}
      title={header.description}
      icon={<NotePencilIcon color="#4575ba" fontSize="28" fontWeight="2" />}
      action={showEditButtons}
      modalChild={<img src={titleComponent} width="100%" />}
    >
      <form onSubmit={form.handleSubmit}>
        <TextField
          id="description"
          name="description"
          label="Descripción"
          value={form.values.description}
          onChange={form.handleChange}
          multiline
          maxRows={5}
          margin="normal"
          size="small"
          disabled={disabled}
          error={form.touched.description && form.errors.description}
          helperText={form.touched.description && form.errors.description}
        />
        {show && (
          <div className={styles.ActionButtons}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Guardar cambios
            </LoadingButton>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeEditButtons}
            >
              Cancelar
            </Button>
          </div>
        )}
      </form>
    </CardContainer>
  );
};

TitleForm.propTypes = {
  style: PropTypes.any,
};

export default TitleForm;
