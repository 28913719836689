import React from 'react';
import PropTypes from 'prop-types';

function KeyIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className} data-testid="InfoIcon-Test">
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.646 15.3541C10.8751 13.4313 10.7918 11.3013 11.4101 9.32418C12.0284 7.34704 13.3103 5.64401 15.0393 4.50295C16.7683 3.3619 18.8382 2.85281 20.8992 3.06173C22.9602 3.27066 24.8858 4.18479 26.3507 5.6496C27.8155 7.11442 28.7296 9.04007 28.9385 11.1011C29.1474 13.1621 28.6383 15.232 27.4973 16.961C26.3562 18.6899 24.6532 19.9719 22.676 20.5902C20.6989 21.2084 18.5689 21.1251 16.6461 20.3542L16.6462 20.354L15.0002 22H12.0002V25H9.00024V28H4.00024V23L11.6462 15.354L11.646 15.3541Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5 10.75C23.1904 10.75 23.75 10.1904 23.75 9.5C23.75 8.80964 23.1904 8.25 22.5 8.25C21.8096 8.25 21.25 8.80964 21.25 9.5C21.25 10.1904 21.8096 10.75 22.5 10.75Z"
          fill={color}
        />
      </svg>
    </span>
  );
}

KeyIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

KeyIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '18', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(KeyIcon);
