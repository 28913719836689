/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function Eraser({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4438 12.4436L19.9291 20.9289"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.0001 26.9791H9.00876L4.37271 22.3431C4.187 22.1574 4.03968 21.9369 3.93917 21.6942C3.83866 21.4516 3.78693 21.1915 3.78693 20.9289C3.78693 20.6662 3.83866 20.4062 3.93917 20.1635C4.03968 19.9209 4.187 19.7004 4.37271 19.5147L18.5148 5.37253C18.8899 4.99746 19.3986 4.78674 19.9291 4.78674C20.4595 4.78674 20.9682 4.99746 21.3433 5.37253L27.0001 11.0294C27.1859 11.2151 27.3332 11.4356 27.4337 11.6782C27.5342 11.9209 27.5859 12.181 27.5859 12.4436C27.5859 12.7062 27.5342 12.9663 27.4337 13.209C27.3332 13.4516 27.1859 13.6721 27.0001 13.8578L13.8788 26.9791"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

Eraser.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

Eraser.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};

export default React.memo(Eraser);
