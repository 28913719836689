import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from 'draft-js';

import theme from '../../../../theme';
import Header from '../../../../components/Header';
import Select from '../../../../components/Inputs/Select';
import GroupSelector from '../../../../components/GroupSelector';
import { statusOptions, subCategoriesOptions } from '../../constants';

import {
  AddContentIncentives,
  GetCategoryGroups,
  updateContentIncentives,
} from './request';
import { validations } from './validation';
import { toolbar } from './toolbar';

import styles from './index.module.scss';

const IncentiveEditor = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    if (state) {
      const editorState = convertFromHTML(state.content.content);
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(editorState),
      );
    } else {
      return EditorState.createEmpty();
    }
  });
  const [groups, setGroups] = useState([]);

  const form = useFormik({
    initialValues: {
      title: state ? state.content.title : '',
      shortTitle: state ? state.content.shortTitle : '',
      description: state ? state.content.description : '',
      max: state ? state.content.max : '',
      effectiveDate: state ? state.content.effectiveDate : 'Vigencia: ',
      subCategory: state ? state.content.subCategory : 'PROMOTIONS_TO_QUOTED',
      status: state ? state.content.status : 'STAGING',
      groups: state ? state.content.groups : [],
    },
    validate: validations,
    onSubmit: values => {
      setLoading(true);
      handleSubmitForm(values);
    },
  });

  const HandleEditorChange = value => {
    setEditorState(value);
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToRaw(editorState.getCurrentContent());
    const stringHTML = draftToHtml(currentContentAsHTML);
    return stringHTML;
  };

  const handleSubmitForm = async values => {
    const html = convertContentToHTML();
    if (state?.content) {
      await updateContentIncentives(values, html, state.content.uuid);
    } else {
      await AddContentIncentives(values, html);
    }
    setLoading(false);
    navigate(-1);
  };

  useEffect(() => {
    GetCategoryGroups().then(res => setGroups(res.data.body.groups));
  }, []);

  return (
    <div className={styles.InventivesContainer}>
      <Header
        title={state?.content ? 'Editar incentivo' : 'Crear incentivo'}
        onBack
      />
      <form onSubmit={form.handleSubmit} className={styles.IncentivesForm}>
        <div className={styles.IncentivesInputsContainer}>
          <div>
            <TextField
              fullWidth
              name="title"
              label="Título"
              margin="normal"
              size="small"
              value={form.values.title}
              onChange={form.handleChange}
              error={form.touched.title && Boolean(form.errors.title)}
              helperText={form.touched.title && form.errors.title}
            />
            <TextField
              fullWidth
              name="shortTitle"
              label="Título resumido"
              margin="normal"
              size="small"
              value={form.values.shortTitle}
              onChange={form.handleChange}
              error={form.touched.shortTitle && Boolean(form.errors.shortTitle)}
              helperText={form.touched.shortTitle && form.errors.shortTitle}
            />
            <div className={styles.Flex}>
              <TextField
                name="description"
                label="Descripción general"
                margin="normal"
                size="small"
                value={form.values.description}
                onChange={form.handleChange}
                error={
                  form.touched.description && Boolean(form.errors.description)
                }
                helperText={form.touched.description && form.errors.description}
                sx={{ flex: 8 }}
              />
              <TextField
                name="max"
                label="Promoción máxima"
                placeholder="Valor que se muestra en la formula (incentivos)."
                margin="normal"
                size="small"
                value={form.values.max}
                onChange={form.handleChange}
                error={form.touched.max && Boolean(form.errors.max)}
                helperText={form.touched.max && form.errors.max}
                sx={{ flex: 4 }}
              />
            </div>
            <div className={styles.Flex}>
              <TextField
                name="effectiveDate"
                label="Fecha de expiración"
                margin="normal"
                size="small"
                value={form.values.effectiveDate}
                onChange={form.handleChange}
                error={
                  form.touched.effectiveDate &&
                  Boolean(form.errors.effectiveDate)
                }
                helperText={
                  form.touched.effectiveDate && form.errors.effectiveDate
                }
              />
              <Select
                value={form.values.subCategory}
                name="subCategory"
                label="Subcategoría"
                margin="normal"
                size="small"
                onChange={form.handleChange}
                error={form.touched.subCategory && form.errors.subCategory}
                helperText={form.touched.subCategory && form.errors.subCategory}
              >
                {Object.entries(subCategoriesOptions).map(value => {
                  return (
                    <MenuItem key={value[0]} value={value[0]}>
                      {value[1]}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={form.values.status}
                name="status"
                label="Estatus"
                margin="normal"
                size="small"
                onChange={form.handleChange}
                error={form.touched.status && form.errors.status}
                helperText={form.touched.status && form.errors.status}
              >
                {Object.entries(statusOptions).map(value => {
                  return (
                    <MenuItem key={value[0]} value={value[0]}>
                      {value[1]}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <GroupSelector
            label="Estatus del Kinsurer"
            bgColor={theme.palette.neutral[0]}
            name="groups"
            groups={groups}
            onChange={form.handleChange}
            error={form.touched.groups && form.errors.groups}
            helperText={form.touched.groups && form.errors.groups}
            checked={state?.content.groups || []}
          />
        </div>
        <div className={styles.EditorContainer}>
          <InputLabel>Contenido</InputLabel>
          <Editor
            editorState={editorState}
            onEditorStateChange={e => HandleEditorChange(e)}
            toolbar={toolbar}
          />
        </div>
        <div className={styles.Actions}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
          >
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default React.memo(IncentiveEditor);
