import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import 'moment/locale/es';

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import CheckCircleIcon from '../../../components/Icons/CheckCircleIcon';
import XCircleIcon from '../../../components/Icons/XCircleIcon';
import { contactResult, contactType } from './constants';

import styles from './ContactTracking.module.scss';

export const ContactTrackingHeader = ({ info }) => {
  moment.locale('es');
  const date = moment(new Date(info.createdDate)).format(
    'dddd, DD-MM-YYYY h:mm a',
  );
  return (
    <div className={styles.ContactTrackingHeader}>
      <div className={styles.ContactTrackingTitle}>
        <Typography variant="h6" component="span">
          {capitalizeFirstLetter(date)}
        </Typography>
        <Typography variant="body1" component="span">
          {info.agentFullName}
        </Typography>
      </div>
      {info.isInterested ? (
        <Typography variant="subtitle1" component="span" color="success.main">
          Está interesado
          <CheckCircleIcon
            fontSize="24"
            color="#2e7d32"
            className={styles.Icons}
          />
        </Typography>
      ) : (
        <Typography variant="subtitle1" component="span" color="warning.main">
          Intento fallido
          <XCircleIcon fontSize="24" color="#ed6c02" className={styles.Icons} />
        </Typography>
      )}
    </div>
  );
};

ContactTrackingHeader.propTypes = {
  info: PropTypes.object,
};

const ContactTracking = ({ info }) => {
  const result = contactResult.filter(
    element => element.code === info.contactResult,
  );
  return (
    <div className={styles.ContactTrackingContainer}>
      <div>
        <span>
          <Typography variant="subtitle2" component="span">
            Tipo de Contacto:{' '}
          </Typography>
          <Typography variant="body1" component="span">
            {contactType[info.contactType]}
          </Typography>
        </span>
        <span>
          <Typography variant="subtitle2" component="span">
            Resultado:{' '}
          </Typography>
          <Typography variant="body1" component="span">
            {result[0].label}
          </Typography>
        </span>
      </div>
      <div>
        <span>
          <Typography variant="subtitle2" component="span">
            Comentarios:{' '}
          </Typography>
          <Typography variant="body1" component="span">
            {info.comments}
          </Typography>
        </span>
      </div>
    </div>
  );
};

ContactTracking.propTypes = {
  info: PropTypes.object,
};

export default ContactTracking;
