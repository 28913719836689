import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Pagination,
  InputAdornment,
  Typography,
} from '@mui/material';

import Loading from '../../../components/Loading';
import MagnifyingGlass from '../../../components/Icons/MagnifyingGlassIcon';

import LeadsList from './LeadsList';
import styles from './index.module.scss';
import { validations } from './validations';
import { getListLeads } from './request';

const LeadList = () => {
  const [loading, setLoading] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [nextLead, setNextLead] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const tableRows = 25;

  const form = useFormik({
    initialValues: {
      search: '',
    },
    validate: validations,
  });

  const handleGetPages = data => {
    let pages = data.length / tableRows;
    if (!Number.isInteger(pages)) {
      pages = Math.floor(pages) + 1;
    }
    return pages;
  };

  const handleSort = (a, b) => {
    if (new Date(a.createdDate) > new Date(b.createdDate)) {
      return -1;
    }
    if (new Date(a.createdDate) < new Date(b.createdDate)) {
      return 1;
    }
    return 0;
  };

  const handleUpdateData = () => {
    setLoading(true);
    setCurrentPage(1);
    getListLeads(form.values.search).then(({ data, notifications }) => {
      if (data) {
        const dataOrderer = data.sort(handleSort);
        setLeadsData(dataOrderer);
        setPageNumber(handleGetPages(dataOrderer));
        setNextLead(notifications?.pagination?.nextItem?.uuid);
      }
      setLoading(false);
    });
  };

  const handleUpdateDataNext = () => {
    getListLeads(form.values.search, nextLead).then(
      ({ data, notifications }) => {
        if (data) {
          const dataOrderer = data.sort(handleSort);
          setLeadsData([...leadsData, ...dataOrderer]);
          setPageNumber(pageNumber + handleGetPages(dataOrderer));
          setNextLead(notifications?.pagination?.nextItem?.uuid);
        }
      },
    );
  };

  const keyPress = e => {
    if (e.code === 'Enter') {
      form.setFieldTouched(e.target.name);
      handleUpdateData();
    }
  };

  const handleChangePage = value => {
    setCurrentPage(value);
    if (value === pageNumber) {
      handleUpdateDataNext();
    }
  };

  useEffect(() => {
    handleUpdateData();
  }, []);

  return (
    <div className={styles.HomeContainer}>
      <div className={styles.Header}>
        <Typography variant="h4" color="primary">
          Leads
        </Typography>
        <TextField
          id="search"
          name="search"
          label="Buscar"
          size="small"
          value={form.values.search}
          className={styles.SearchBar}
          onChange={form.handleChange}
          onKeyDown={e => keyPress(e)}
          onBlur={form.handleBlur}
          error={form.touched.search && form.errors.search}
          helperText={form.touched.search && form.errors.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MagnifyingGlass fontSize="16" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={styles.Table}>
        {loading && <Loading flex white />}
        {leadsData && !loading && (
          <LeadsList
            rows={leadsData.slice(
              (currentPage - 1) * tableRows,
              tableRows * currentPage,
            )}
          />
        )}
      </div>
      <Pagination
        count={pageNumber}
        value={currentPage}
        onChange={(e, value) => handleChangePage(value)}
        className={styles.Pagination}
        color="primary"
      />
    </div>
  );
};

export default React.memo(LeadList);
