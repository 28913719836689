/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import EyeClosedIcon from '../../components/Icons/EyeClosed';
import EyeIcon from '../../components/Icons/Eye';
import { validations } from './validations';
import styles from './Form.module.scss';

function Form({ onSubmit, error, loading }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validate={validations}
      onSubmit={onSubmit}
    >
      {props => {
        const { errors, touched, values, handleSubmit, handleChange } = props;
        return (
          <form onSubmit={handleSubmit}>
            {error && <Alert severity="error">{error.message}</Alert>}
            <Typography
              className={styles.LoginFormTitle}
              variant="h4"
              component="p"
            >
              Accede a Jarvis
            </Typography>
            <TextField
              name="email"
              type="email"
              margin="normal"
              label="Email"
              variant="outlined"
              onChange={handleChange}
              value={values.email}
              error={errors.email && touched.email}
              helperText={errors.email && touched.email && 'Email invalido'}
              sx={{ width: '100%' }}
            />
            <FormControl
              variant="outlined"
              margin="normal"
              sx={{ width: '100%' }}
              className={
                errors.password && touched.password && styles.SignUpFormPassword
              }
            >
              <InputLabel>Contraseña</InputLabel>
              <OutlinedInput
                name="password"
                value={values.password}
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange}
                error={errors.password && touched.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="password_adornment"
                      aria-label="toggle password visibility"
                      onClick={togglePassword}
                      edge="end"
                    >
                      {showPassword ? <EyeIcon /> : <EyeClosedIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  'data-testid': 'loginPassword',
                }}
              />
              {errors.password && touched.password && (
                <FormHelperText>Contraseña invalida</FormHelperText>
              )}
            </FormControl>
            <div className={styles.LoginFormActions}>
              <LoadingButton
                id="k-login-login"
                variant="contained"
                color="secondary"
                type="submit"
                data-testid="loginButton"
                disabled={loading}
                loading={loading}
              >
                INGRESAR
              </LoadingButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Form;
