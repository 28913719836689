export function validations(values) {
  const errors = {};
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Email no cumple con los requerimientos';
  }
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,40}$/g.test(
      values.password,
    )
  ) {
    errors.password = 'Email no cumple con los requerimientos';
  }
  return errors;
}
