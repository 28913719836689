import React from 'react';
import PropTypes from 'prop-types';

function ImageSquareIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 20L21.7071 14.7071C21.5196 14.5195 21.2652 14.4142 21 14.4142C20.7348 14.4142 20.4804 14.5195 20.2929 14.7071L14.7071 20.2929C14.5196 20.4804 14.2652 20.5858 14 20.5858C13.7348 20.5858 13.4804 20.4804 13.2929 20.2929L10.7071 17.7071C10.5196 17.5195 10.2652 17.4142 10 17.4142C9.73478 17.4142 9.48043 17.5195 9.29289 17.7071L5 22"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 12.75C13.1904 12.75 13.75 12.1904 13.75 11.5C13.75 10.8096 13.1904 10.25 12.5 10.25C11.8096 10.25 11.25 10.8096 11.25 11.5C11.25 12.1904 11.8096 12.75 12.5 12.75Z"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

ImageSquareIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

ImageSquareIcon.propTypes = {
  className: PropTypes.any,
  color: PropTypes.string,
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
};

export default React.memo(ImageSquareIcon);
