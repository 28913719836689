import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Context } from '../context/SessionContext';
import { sessionKey } from '../constants';

import Login from './Login';
import Logout from './Logout';
import NotFound from './NotFound';
import FramePage from './FramePage';
import PrintCarQuotation from './PrintCarQuotation';
import Authorization from '../components/Authorization';

function App() {
  const { setUserData } = useContext(Context);

  const getAccess = async () => {
    const user = JSON.parse(sessionStorage.getItem(sessionKey));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <div data-testid="test-app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route
            exact
            path="/printCarQuotation"
            element={<PrintCarQuotation />}
          />
          <Route element={<Authorization access="session" />}>
            <Route path="/dashboard/*" element={<FramePage />} />
          </Route>
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
