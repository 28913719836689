/* eslint-disable react/prop-types */
import React from 'react';
import styles from './index.module.scss';
import { Typography } from '@mui/material';
import image from '../../assets/images/Gamer.png';
// import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
  // let navigate = useNavigate();

  // const handleDirection = () => {
  //   navigate('/');
  // };

  return (
    <div className={styles.ComingSoonContainer}>
      <Typography component="div" variant="h4" className={styles.Title}>
        ¡Proximamente!
      </Typography>
      <Typography component="div" variant="h6" className={styles.Subtitle}>
        Sección en construcción
      </Typography>
      <div className={styles.ImageContainer}>
        <div
          className={styles.Image}
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
      <Typography component="div" variant="body1" className={styles.Text}>
        <div>Estamos trabajando en la nueva caracteristica,</div>
        <div>por favor regrese más tarde</div>
      </Typography>
      {/* <div className={styles.GoHome}>
            <Button
              data-testid="goBackAction"
              variant="outlined"
              onClick={handleDirection}
            >
              Regresar al inicio
            </Button>
          </div> */}
    </div>
  );
};

export default React.memo(ComingSoon);
