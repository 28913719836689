export const subCategoriesOptions = {
  PROMOTIONS_TO_QUOTED: 'Por cotizar',
  PROMOTIONS_TO_ISSUANCE: 'Por emitir',
  PROMOTIONS_TO_REFERRING: 'Por referir',
  PROMOTIONS_TO_PARTNERSHP: 'Asociaciones',
};

export const statusOptions = {
  PUBLISH: 'Publicado',
  STAGING: 'En revisión',
};
