import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import LightbulbIcon from '../../components/Icons/LightbulbIcon';
import IntersectIcon from '../../components/Icons/IntersectIcon';
import ChartBarIcon from '../../components/Icons/ChartBarIcon';
import BookOpenIcon from '../../components/Icons/BookOpenIcon';
import BankIcon from '../../components/Icons/BankIcon';
import UserIcon from '../../components/Icons/UserIcon';
import FlagIcon from '../../components/Icons/FlagIcon';
import BellIcon from '../../components/Icons/BellIcon';

import { isAuthorized } from '../../components/Authorization';

const ListItems = ({ disabled }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [auth, setAuth] = useState(null);

  const getAuth = async () => {
    if (!auth) {
      const res = await isAuthorized();
      setAuth(res);
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  const getCurrent = () => {
    const path = pathname.split('/');
    switch (path[2]) {
      case 'kinsurers':
        return 1;
      case 'leads':
        return 2;
      case 'dynamicContent':
        return 3;
      case 'notifications':
        return 4;
      case 'multimedia':
        return 5;
      case 'reports':
        return 6;
      case 'car-catalog-homologate':
        return 7;
      default:
        return 0;
    }
  };
  const current = getCurrent();
  return (
    <>
      <Tooltip title="Inicio" placement="right" disableHoverListener={disabled}>
        <ListItemButton
          onClick={() => navigate('/dashboard')}
          selected={current === 0}
        >
          <ListItemIcon>
            <BankIcon fontSize="28" color="#49505a" />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemButton>
      </Tooltip>
      <Divider sx={{ my: 1 }} />
      {auth?.kinsurers && (
        <Tooltip
          title="Kinsurers"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/kinsurers')}
            selected={current === 1}
          >
            <ListItemIcon>
              <UserIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Kinsurers" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.leads && (
        <Tooltip
          title="Leads"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/leads')}
            selected={current === 2}
          >
            <ListItemIcon>
              <FlagIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.dynamicContent && (
        <Tooltip
          title="Contenidos"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/dynamicContent/incentives')}
            selected={current === 3}
          >
            <ListItemIcon>
              <BookOpenIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Contenidos" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.notifications && (
        <Tooltip
          title="Notificaciones"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/notifications/push')}
            selected={current === 4}
          >
            <ListItemIcon>
              <BellIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Notificaciones" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.multimedia && (
        <Tooltip
          title="Multimedia"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/multimedia/home')}
            selected={current === 5}
          >
            <ListItemIcon>
              <LightbulbIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Multimedia" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.reports && (
        <Tooltip
          title="Reportes"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/reports')}
            selected={current === 6}
          >
            <ListItemIcon>
              <ChartBarIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
          </ListItemButton>
        </Tooltip>
      )}
      {auth?.homologate && (
        <Tooltip
          title="Homologación de Autos"
          placement="right"
          disableHoverListener={disabled}
        >
          <ListItemButton
            onClick={() => navigate('/dashboard/car-catalog-homologate')}
            selected={current === 7}
          >
            <ListItemIcon>
              <IntersectIcon fontSize="28" color="#49505a" />
            </ListItemIcon>
            <ListItemText primary="Homologación" />
          </ListItemButton>
        </Tooltip>
      )}
    </>
  );
};

ListItems.propTypes = {
  disabled: PropTypes.bool,
};

export default React.memo(ListItems);
