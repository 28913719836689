import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Header from '../../components/Header';

import Push from './Push';
import Email from './Email';

import styles from './index.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = () => {
  const { tabSelected } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/notifications/${newValue}`);
  };

  return (
    <div className={styles.NotificationsContainer}>
      <Header title="Notificaciones"></Header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            value="push"
            label="Push"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(0)}
          />
          <Tab
            label="Email"
            value="email"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabSelected} index="push">
        <Push />
      </TabPanel>
      <TabPanel value={tabSelected} index="email">
        <Email />
      </TabPanel>
    </div>
  );
};

export default React.memo(BasicTabs);
