import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '../../components/Icons/CheckCircleIcon';

import styles from './index.module.scss';

const Confirmation = ({ message, open, onClose }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [open]);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
      open={open}
    >
      <div className={styles.BackdropContent}>
        <CheckCircleIcon fontSize="48" color="white" />
        <Typography variant="h4" component="p" gutterBottom>
          {message || 'Guardado Correctamente'}
        </Typography>
      </div>
    </Backdrop>
  );
};

Confirmation.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default React.memo(Confirmation);
