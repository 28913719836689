export const contactType = {
  1: 'Llamada',
  2: 'Whatsapp',
};

export const contactResult = [
  { code: 'MG', label: 'Mensaje Grabadora', type: 1 },
  { code: 'NE', label: 'Número equivocado', type: 1 },
  { code: 'NS', label: 'Número suspendido', type: 1 },
  { code: 'MF', label: 'Mensaje con familiar', type: 1 },
  { code: 'CU', label: 'Cuelga', type: 1 },
  { code: 'NI', label: 'Número inexistente', type: 1 },
  { code: 'DV', label: 'Deja en visto', type: 2 },
  { code: 'MR', label: 'No le llega el mensaje', type: 2 },
  { code: 'NW', label: 'No tiene whatsapp', type: 2 },
  { code: 'ME', label: 'Se envia mensaje', type: 2 },
];

export const reasons = [
  { value: 1, label: 'Se da OnBoarding', bool: true },
  { value: 2, label: 'Pide regresar llamada', bool: true },
  { value: 3, label: 'Ya tiene empleo', bool: false },
  { value: 4, label: 'Kinsu no es lo que esperaba', bool: false },
  { value: 5, label: 'Busca un salario base', bool: false },
  { value: 6, label: 'No tiene red de contactos', bool: false },
  { value: 7, label: 'No tiene tiempo', bool: false },
  { value: 8, label: 'No esta familiarizado con temas digitales', bool: false },
  { value: 9, label: 'Se agenda llamada de On Boarding', bool: true },
  { value: 10, label: 'Otro' },
];

export const statusKinsurer = {
  REGISTERED: 'Registrado',
  ACTIVE: 'Activo',
  ENGAGE: 'Engage',
};

export const occupations = [
  {
    description: 'Comerciante',
    id: '1',
  },
  {
    description: 'Empleado',
    id: '2',
  },
  {
    description: 'Empresario',
    id: '3',
  },
  {
    description: 'Hogar',
    id: '7',
  },
  {
    description: 'Permisionario',
    id: '4',
  },
  {
    description: 'Transportista',
    id: '6',
  },
  {
    description: 'Otro',
    id: '5',
    order: 1,
  },
];

export const maritalStatus = [
  {
    description: 'Soltera/o',
    id: '1',
  },
  {
    description: 'Casada/o',
    id: '2',
  },
  {
    description: 'Unión libre',
    id: '3',
  },
  {
    description: 'Viuda/o',
    id: '4',
  },
  {
    description: 'Divorciada/o',
    id: '5',
  }
];
