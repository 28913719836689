export const validations = values => {
  const errors = {};

  if (!values.customPath || values.customPath === '') {
    errors.customPath = 'Este campo es requerido.';
  }
  if (values.customPath && !/^[a-zA-Z0-9-]+$/.test(values.customPath)) {
    errors.customPath =
      'La url no puede contener espacios en blanco. Use "-" como signo de separación.';
  }
  if (values.customPath && values.customPath.includes(' ')) {
    errors.customPath =
      'La url no puede contener espacios en blanco. Use "-" como signo de separación.';
  }
  if (!values.title || values.title === '') {
    errors.title = 'Este campo es requerido.';
  }
  return errors;
};
