import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Header from '../../components/Header';

import HomeApp from './HomeApp';
import Formula from './Formula';
import Material from './Material';
import FormulaWeb from './FormulaWeb';

import styles from './index.module.scss';
import Typography from '@mui/material/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = () => {
  const { tabSelected } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/multimedia/${newValue}`);
  };

  return (
    <div className={styles.FormulaContainer}>
      <Header title="Secciones Multimedia"></Header>
      <div className={styles.Subtitles}>
        <Typography variant="h6" color="primary" sx={{ flex: 3 }}>
          App
        </Typography>
        <Typography variant="h6" color="primary" sx={{ flex: 9 }}>
          Guía Kinsu
        </Typography>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            value="home"
            label="Home"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(0)}
          />
          <Tab
            label="Web"
            value="formula-web"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(0)}
          />
          <Tab
            label="App"
            value="formula"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(0)}
          />
          <Tab
            label="Materiales"
            value="material"
            sx={{ flex: 1, maxWidth: 'none', fontSize: 18 }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabSelected} index="home">
        <HomeApp />
      </TabPanel>
      <TabPanel value={tabSelected} index="formula">
        <Formula />
      </TabPanel>
      <TabPanel value={tabSelected} index="formula-web">
        <FormulaWeb />
      </TabPanel>
      <TabPanel value={tabSelected} index="material">
        <Material />
      </TabPanel>
    </div>
  );
};

export default React.memo(BasicTabs);
