import React, { useEffect, useState } from 'react';

import Loading from '../../components/Loading';

import RenderQuotation from './RenderQuotation';

import styles from './index.module.scss';
import { getCoverages } from './request';

const PrintQuotation = () => {
  const [state, setState] = useState(null);
  const [coverage, setCoverage] = useState(null);

  useEffect(() => {
    setState(JSON.parse(localStorage.getItem('lastQuotation')));
    getCoverages().then(res => setCoverage(res.data));

    return () => {
      localStorage.removeItem('lastQuotation');
    };
  }, []);

  if (!coverage) {
    return (
      <div className={styles.PrintQuotationContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.PrintQuotationContainer}>
      {state && <RenderQuotation resumeData={state} coverage={coverage} />}
    </div>
  );
};

export default React.memo(PrintQuotation);
