import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';

import theme from '../../../theme';
import Confirmation from '../../../components/Confirmation';
import GroupSelector from '../../../components/GroupSelector';

import { validations } from './validation';
import { GetCategoryGroups, postEmail } from './request';

import styles from './index.module.scss';

const Email = () => {
  const [loading, setLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [groups, setGroups] = useState([]);

  const form = useFormik({
    initialValues: {
      groups: [],
      sengridId: '',
    },
    validate: validations,
    onSubmit: values => {
      setLoading(true);
      handleSubmitForm(values);
    },
  });

  const handleOpenBackDrop = () => {
    setOpenBackDrop(!openBackDrop);
  };

  const handleSubmitForm = async values => {
    try {
      const res = await postEmail(values);
      if (!res.notifications.requestMessage) {
        form.resetForm();
        handleOpenBackDrop();
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    GetCategoryGroups().then(res => setGroups(res.data.body.groups));
  }, []);

  return (
    <>
      <form onSubmit={form.handleSubmit} className={styles.EmailContainer}>
        <div className={styles.Form}>
          <GroupSelector
            label="Estatus del Kinsurer"
            bgColor={theme.palette.neutral[0]}
            name="groups"
            groups={groups}
            onChange={form.handleChange}
            error={form.touched.groups && form.errors.groups}
            helperText={form.touched.groups && form.errors.groups}
            checked={[]}
            style={{ flex: 2 }}
          />
          <TextField
            name="sengridId"
            label="Sengrid ID"
            size="small"
            value={form.values.sengridId}
            onChange={form.handleChange}
            error={form.touched.sengridId && Boolean(form.errors.sengridId)}
            helperText={form.touched.sengridId && form.errors.sengridId}
            fullWidth
            sx={{ flex: 10 }}
          />
        </div>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
          className={styles.Action}
        >
          Enviar
        </LoadingButton>
      </form>
      <Confirmation
        message="Email enviado"
        open={openBackDrop}
        onClose={handleOpenBackDrop}
      />
    </>
  );
};

export default React.memo(Email);
