import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Context } from '../../context/SessionContext';
import { sections, sessionKey } from '../../constants';
import Form from './Form';

import KinsuLogo from '../../assets/images/logo-white-big.png';
import LoginMan from '../../assets/images/login.png';

import styles from './index.module.scss';

const Login = () => {
  const navigate = useNavigate();
  const { setUserData } = useContext(Context);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifySessionAndRedirect = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then(async ({ signInUserSession, attributes, username }) => {
        const groups =
          signInUserSession?.accessToken?.payload['cognito:groups'];
        if (groups.includes(sections.session[0])) {
          sessionStorage.setItem(
            sessionKey,
            JSON.stringify({
              attributes,
              username,
            }),
          );
          navigate('/dashboard');
        } else {
          await Auth.signOut();
          alert(
            'Este es un servicio privado de Kinsu, si pertenece a Kinsu solicite ayuda con su superior.',
          );
          setLoading(false);
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const handleOnSubmit = async values => {
    setLoading(true);
    await Auth.signIn({
      username: values.email,
      password: values.password,
    })
      .then(({ attributes, username }) => {
        setUserData({
          attributes,
          username,
        });
        verifySessionAndRedirect();
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <div className={styles.LoginContainer}>
      <div className={styles.TitleContainer}>
        <div className={styles.TitleContent}>
          <img src={LoginMan} alt="login-man" className={styles.ImageRef} />
          <img src={KinsuLogo} alt="login-man" className={styles.Logo} />
          <Typography
            variant="h3"
            color="secondary"
            gutterBottom
            sx={{ fontWeight: 500 }}
          >
            Bienvenido al equipo.
          </Typography>
        </div>
      </div>
      <div className={styles.FormContainer}>
        <Form onSubmit={handleOnSubmit} error={error} loading={loading} />
      </div>
    </div>
  );
};

export default Login;
