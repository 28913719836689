import wins from '../../../assets/images/screens/wins.jpg';
import home from '../../../assets/images/screens/home.jpg';
import help from '../../../assets/images/screens/help.jpg';
import agent from '../../../assets/images/screens/agent.jpg';
import docs from '../../../assets/images/screens/documents.jpg';
import formula from '../../../assets/images/screens/formula.jpg';
import getWins from '../../../assets/images/screens/getWins.jpg';
import profile from '../../../assets/images/screens/profile.jpg';
import quotations from '../../../assets/images/screens/Quotations.jpg';
import incentives from '../../../assets/images/screens/incentives.jpg';
import editProfile from '../../../assets/images/screens/editProfile.jpg';
import newQuotation from '../../../assets/images/screens/NewQuotation.jpg';
import notifications from '../../../assets/images/screens/notifications.jpg';
import potentialWins from '../../../assets/images/screens/potentialWins.jpg';
import materialKinsu from '../../../assets/images/screens/materialKinsu.jpg';
import refererIncentives from '../../../assets/images/screens/refererIncentives.jpg';
import incentivesQuotation from '../../../assets/images/screens/incentivesQuotation.jpg';
import promotionsIncentives from '../../../assets/images/screens/promotionsIncentives.jpg';
import focusTime from '../../../assets/images/screens/tiempodeenfocarse.png';
import nearlyDone from '../../../assets/images/screens/yaquedapoco.png';

export const appSections = [
  {
    screen: 'home',
    name: 'Inicio',
    img: home,
    children: [
      { screen: 'helpCenter', name: 'Centro de ayuda', img: help },
      { screen: 'notifications', name: 'Notificaciones', img: notifications },
      {
        screen: 'incentives',
        name: 'Incentivos',
        img: incentives,
        children: [
          {
            screen: 'incentivesQuotation',
            name: 'Incentivos por cotizar',
            img: incentivesQuotation,
          },
          {
            screen: 'referralIncentives',
            name: 'Incentivos por referir',
            img: refererIncentives,
          },
          {
            screen: 'currentPromotions',
            name: 'Promociones para clientes',
            img: promotionsIncentives,
          },
        ],
      },
      { screen: 'agent', name: 'Agente', img: agent },
    ],
  },
  {
    screen: 'project3060',
    name: 'Proyecto 30/60',
    img: home,
    children: [
      { screen: 'focusTimeNotification', name: 'Enfocate', img: focusTime },
      { screen: 'nearlyDoneNotification', name: 'Ya falta poco', img: nearlyDone },
    ],
  },
  {
    screen: 'formula',
    name: 'La Fórmula',
    img: formula,
    children: [
      { screen: 'materialKinsu', name: 'Material Kinsu', img: materialKinsu },
    ],
  },
  {
    screen: 'quotations',
    name: 'Cotizaciones',
    img: quotations,
    children: [
      { screen: 'newQuotation', name: 'Nueva cotización', img: newQuotation },
    ],
  },
  {
    screen: 'wins',
    name: 'Ganancias',
    img: wins,
    children: [
      { screen: 'earningsDetail', name: 'Ganancias por cobrar', img: getWins },
      {
        screen: 'potentialEarnings',
        name: 'Ganancias potenciales',
        img: potentialWins,
      },
    ],
  },
  {
    screen: 'profile',
    name: 'Perfil',
    img: profile,
    children: [
      { screen: 'profileEdit', name: 'Editar perfil', img: editProfile },
      { screen: 'profileDocuments', name: 'Documentos', img: docs },
    ],
  },
];

export const initialScreen = {
  screen: 'notifications',
  name: 'Notificaciones',
  img: notifications,
};
