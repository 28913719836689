import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getKinsurerDocuments = uuid => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/documents`);
};

export const updateKinsurerDocuments = (uuid, values) => {
  const data = {
    idDocument: values.idDocument,
    name: values.name,
    newStatus: values.newStatus,
    reasonForRejection: values.reasonForRejection,
  };
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/documents`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
