import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LinkIcon from '../../components/Icons/LinkIcon';
import ShareQrCard from '../../components/ShareQrCard';
import QrCodeIcon from '../../components/Icons/QrCodeIcon';

import styles from './index.module.scss';

function SharedInput({
  url,
  code,
  msgText = '',
}) {
  const [open, setOpen] = useState(false);
  const [openQr, setOpenQr] = useState(false);

  const handleClose = () => setOpen(!open);
  const handleOpenQr = () => setOpenQr(true);
  const handleCloseQr = () => setOpenQr(false);

  const handleCopyLink = text => () => {
    setOpen(!open);
    navigator.clipboard.writeText(text);
  };

  const urlQrCode = {
    link: url,
    msg: msgText,
    code
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        severity="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">Link copiado correctamente</Alert>
      </Snackbar>
      <div className={styles.SharedInput}>
        <div>
          <Typography variant="subtitle2" component="p">
            {url}
          </Typography>
          <IconButton
            onClick={handleCopyLink(url)}
            className={styles.IconLinkFirst}
          >
            <LinkIcon color="white" />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={handleCopyLink(url)}
            className={styles.IconLinkSecond}
          >
            <LinkIcon color="#4575ba" />
          </IconButton>

          <IconButton onClick={handleOpenQr}>
            <QrCodeIcon color="#4575ba" />
          </IconButton>
        </div>
      </div>
      <Modal open={openQr} onClose={handleCloseQr}>
        <ShareQrCard code={urlQrCode} size={220} style={styles.QrModal} />
      </Modal>
    </>
  );
}

SharedInput.propTypes = {
  url: PropTypes.string.isRequired,
  code: PropTypes.string,
  msgText: PropTypes.string
};

export default React.memo(SharedInput);
