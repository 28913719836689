import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const postPushNotifications = values => {
  return request(`${urlBase}/core/push-notifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: values.type,
      recipients: values.recipients,
      title: values.title,
      body: values.message,
      section: values.screen,
    }),
  });
};

export const GetCategoryGroups = (category = 'status') => {
  return request(`${urlBase}/notifications/segmentation/${category}`);
};
