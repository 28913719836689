import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getLeadDetails = uuid => {
  return request(`${urlBase}/jarvis/leads/${uuid}`);
};

export const getLeadActivationTracking = uuid => {
  return request(`${urlBase}/jarvis/leads/${uuid}/tracking`);
};

export const postLeadActivationTracking = (values, uuid) => {
  return request(`${urlBase}/jarvis/leads/${uuid}/tracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: values.userId,
      contactType: values.contactType,
      contactResult: values.contactResult,
      isInterested: values.isInterested,
      reason: values.reason,
      isSuccessfulActivation: values.isSuccessfulActivation,
      comments: values.comments,
      createdDate: values.createdDate,
    }),
  });
};
