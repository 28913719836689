export const validations = values => {
  const errors = {};
  if (!values.title || values.title === '') {
    errors.title = 'Este campo es requerido.';
  }
  if (!values.shortTitle || values.shortTitle === '') {
    errors.shortTitle = 'Este campo es requerido.';
  }
  if (!values.description || values.description === '') {
    errors.description = 'Este campo es requerido.';
  }
  if (!values.max || values.max === '' || !values.max.match(/^([0-9])*$/)) {
    errors.max = 'Este campo es requerido y/o debe ser un número.';
  }
  if (
    !values.effectiveDate ||
    values.effectiveDate === '' ||
    values.effectiveDate === 'Vigencia: '
  ) {
    errors.effectiveDate = 'Completa este campo.';
  }
  if (values.groups.length === 0) {
    errors.groups = 'Selecciona al menos uno';
  }
  return errors;
};
