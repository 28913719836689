import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Authorization from '../../components/Authorization';
import UploadDocuments from './UploadDocuments';
import KinsurerDetails from './KinsurerDetails';
import UpdateKinsurer from './UpdateKinsurer';
import CheckDocuments from './CheckDocuments';
import KinsurerList from './KinsurerList';

const index = () => {
  return (
    <Routes>
      <Route element={<Authorization access="kinsurers" white />}>
        <Route path="/" element={<KinsurerList />} />
        <Route path="/:uuid" element={<KinsurerDetails />} />
        <Route path="/:uuid/update" element={<UpdateKinsurer />} />
        <Route path="/:uuid/documents" element={<CheckDocuments />} />
        <Route path="/:uuid/documents/upload" element={<UploadDocuments />} />
      </Route>
    </Routes>
  );
};

export default React.memo(index);
