import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function SelectCustom({
  controlProps,
  label,
  children,
  error,
  margin,
  helperText,
  value,
  ...other
}) {
  return (
    <FormControl
      {...controlProps}
      error={error}
      data-testid="SelectCustomInput"
      margin={margin}
    >
      <InputLabel
        id="model-select-label"
        style={value ? { top: '0px' } : { top: '-4px' }}
      >
        {label}
      </InputLabel>
      {children && (
        <Select
          labelId="model-select-label"
          label={label}
          value={value}
          {...other}
          error={error}
        >
          {children}
        </Select>
      )}
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectCustom.propTypes = {
  controlProps: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.object,
  margin: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.any,
};

export default React.memo(SelectCustom);
