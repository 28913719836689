import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { styles } from './Table.styles';
import theme from '../../theme';

const brands = {
  ana: 'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/ana%403x.png',
  axxa: 'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/axxa%403x.png',
  atlas:
    'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/segurosatlas-azul.png',
  hdi: 'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/hdi%403x.png',
  mapfre:
    'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/mapfre%403x.png',
  qualitas:
    'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/qualitas%403x.png',
  chubb:
    'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/Chubbazul.png',
};

const check =
  'https://kinsu-resources-quote.s3.us-east-2.amazonaws.com/PdfTemplate/check%403x.png';

const Table = ({ values, payment }) => {
  return (
    <View style={styles.TableQuotation}>
      <View style={styles.Column}>
        <View style={styles.PropertiesHeader}>
          <Image style={styles.ColumnCheck} source={`${check}`} />
          <Text style={styles.ColumnHeaderText}>Características</Text>
          <Text style={styles.ColumnHeaderText}>incluídas</Text>
        </View>
        <Text style={styles.PropertyLabel}>Daños Materiales</Text>
        <Text style={styles.PropertyLabel}>Robo Total</Text>
        <Text style={styles.PropertyLabel}>Gastos Médicos</Text>
        <Text style={styles.PropertyLabel}>Responsabilidad Civil</Text>
        <Text style={styles.PropertyLabel}>Asistencia Completa</Text>
        <Text style={styles.PropertyLabel}>Defensa Jurídica</Text>
        <Text style={styles.PropertyLabel}>Accidentes al conductor</Text>
        <Text style={styles.PropertyLabel}>
          RC catastrófica por muerte accidental
        </Text>
        <Text style={styles.PropertyLabel}>Robo Parcial</Text>
        <Text style={styles.PropertyLabel}>Auto Sustituto</Text>
        <Text style={styles.PropertyLabel}>No deducible por PT por DM</Text>
        <Text style={styles.PropertyLabel}>No deducible por PP por DM</Text>
        <Text style={styles.PropertyLabel}>Llantas</Text>
      </View>
      {values &&
        values.map((insurance, index) => {
          if (insurance[1]) {
            const coverages = {
              5: { sumInsured: 'N/A', order: 1 },
              4: { sumInsured: 'N/A', order: 2 },
              6: { sumInsured: 'N/A', order: 3 },
              9: { sumInsured: 'N/A', order: 4 },
              3: { sumInsured: 'N/A', order: 5 },
              7: { sumInsured: 'N/A', order: 6 },
              15: { sumInsured: 'N/A', order: 7 },
              2: { sumInsured: 'N/A', order: 8 },
              10: { sumInsured: 'N/A', order: 9 },
              11: { sumInsured: 'N/A', order: 10 },
              12: { sumInsured: 'N/A', order: 11 },
              13: { sumInsured: 'N/A', order: 12 },
              14: { sumInsured: 'N/A', order: 13 },
            };
            insurance[1].coverages.forEach(element => {
              if (
                element.sumInsured &&
                element.id !== 0 &&
                element.id !== '0' &&
                element.id <= 15
              ) {
                if (coverages[element.id]) {
                  coverages[element.id].sumInsured = element.sumInsured;
                }
              }
            });
            return (
              <View
                key={insurance[0]}
                style={{
                  ...styles.ColumnData,
                  ...(index % 2 !== 0 && {
                    backgroundColor: theme.palette.neutral[2],
                  }),
                  ...(Object.entries(values).length - 1 === index && {
                    borderRight: 0,
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15,
                  }),
                }}
              >
                <View style={styles.InsuranceHeader}>
                  <Image
                    style={styles.InsuranceLogo[insurance[0]]}
                    source={`${brands[insurance[0]]}`}
                  />
                  {payment === 'ANNUAL' ? (
                    <View>
                      <Text style={styles.ColumnHeaderText}>
                        Único pago:{' '}
                        <Text style={{ color: theme.palette.neutral[7] }}>
                          {insurance[1].premium.total}
                        </Text>
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text style={styles.ColumnHeaderText}>
                        1er pago:{' '}
                        <Text style={{ color: theme.palette.neutral[7] }}>
                          {insurance[1].firstReceipt.premium.total}
                        </Text>
                      </Text>
                      <Text style={styles.ColumnHeaderText}>
                        Subsecuentes:{' '}
                        <Text style={{ color: theme.palette.neutral[7] }}>
                          {insurance[1].subsequentReceipt.premium.total}
                        </Text>
                      </Text>
                    </View>
                  )}
                </View>
                {Object.entries(coverages).sort((a, b) => a[1].order - b[1].order).map(key => {
                  return (
                    <Text key={key[0]} style={styles.Property}>
                      {key[1].sumInsured}
                    </Text>
                  );
                })}
              </View>
            );
          }
          return 0;
        })}
    </View>
  );
};

Table.propTypes = {
  payment: PropTypes.string,
  values: PropTypes.array,
};

export default Table;
