import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

export const exportAsImage = async (el, imageFileName) => {
  const canvas = await html2canvas(el);
  const image = canvas.toDataURL('image/png', 1.0);
  downloadjs(image, imageFileName + '.png', 'image/png');
};

export const DownloadBase64AsImage = async (base64, imageFileName) => {
  downloadjs(base64, imageFileName + '.jpg', 'image/jpeg');
};
