import { StyleSheet } from '@react-pdf/renderer';
import theme from '../../theme';

export const styles = StyleSheet.create({
  TableQuotation: {
    border: 2,
    borderRadius: 15,
    borderColor: theme.palette.primary.main,
    marginLeft: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  Column: {
    paddingLeft: 10,
    width: 133,
    display: 'flex',
    flexDirection: 'column',
    borderRight: 2,
    borderColor: theme.palette.primary.main,
    paddingRight: 5,
  },
  PropertiesHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 75,
  },
  ColumnCheck: {
    width: 25,
  },
  ColumnHeaderText: {
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  Property: {
    fontSize: 9,
    textAlign: 'center',
    paddingTop: 7,
    paddingBottom: 7,
  },
  PropertyLabel: {
    fontSize: 9,
    textAlign: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  InsuranceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
  },
  ColumnData: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRight: 2,
    borderColor: theme.palette.primary.main,
    paddingRight: 5,
    justifyContent: 'space-between',
  },
  InsuranceLogo: {
    ana: {
      marginTop: 10,
      width: 45,
    },
    axxa: {
      marginTop: 10,
      width: 35,
    },
    hdi: {
      marginTop: 10,
      width: 45,
    },
    mapfre: {
      marginTop: 20,
      width: 90,
    },
    qualitas: {
      marginTop: 16,
      width: 75,
    },
    chubb: {
      marginTop: 20,
      width: 80,
    },
    atlas: {
      marginTop: 8,
      width: 75,
    },
  },
});
