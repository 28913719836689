import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const getStoriesCategories = () => {
  return request(`${urlBase}/jarvis/stories/categories`);
};

export const getStoryByCategory = categoryId => {
  return request(`${urlBase}/jarvis/story/${categoryId}`);
};

export const postStory = (values, payload) => {
  return request(`${urlBase}/jarvis/story`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      category: values.category,
      miniatureKey: payload.miniatureKey,
      media: payload.media,
    }),
  });
};

export const updateStoryByCategory = (payload, categoryId) => {
  const body = {
    media: payload.media,
    miniature: payload.miniature,
    category: payload.category,
  };
  if (payload.miniatureKey) {
    body.miniatureKey = payload.miniatureKey;
    delete body.miniature;
  }
  return request(`${urlBase}/jarvis/story/${categoryId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};
