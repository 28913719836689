import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import moment from 'moment';

import EnvelopeOpenIcon from '../../../components/Icons/EnvelopeOpenIcon';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import UserIcon from '../../../components/Icons/UserIcon';
import ProductConfiguration from './ProductConfiguration';
import { statusLead, types } from './constants';

import styles from './PersonalInfo.module.scss';
import classnames from 'classnames';

const PersonalInfo = ({ data }) => {
  return (
    <>
      <div className={styles.PersonalInfo}>
        <Typography variant="h6" sx={{ fontWeight: 400 }}>
          {data.fullName}
        </Typography>
        <div className={styles.PrincipalInfo}>
          <div className={styles.Column}>
            <Typography variant="subtitle1">
              <EnvelopeOpenIcon fontSize="14" className={styles.AlignIcon} />
              {data.email}
            </Typography>
            <Typography variant="subtitle1">
              <PhoneIcon fontSize="14" className={styles.AlignIcon} />
              {data.phoneNumber}
            </Typography>
          </div>
          <div className={classnames(styles.Column, styles.MW)}>
            <span>
              <Typography variant="subtitle2" component="span">
                Estado:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {statusLead[data.status]}
              </Typography>
            </span>
            <span>
              <Typography variant="subtitle2" component="span">
                Fecha de registro:{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {moment(new Date(data.createdDate)).format('DD-MM-YYYY')}
              </Typography>
            </span>
          </div>
        </div>
      </div>
      {data.productType && (
        <div className={styles.PersonalInfo}>
          <div className={styles.Column}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }}>
              {types[data.productType]}
            </Typography>
            {data.subProductType && (
              <span>
                <Typography variant="subtitle2" component="span">
                  Subproducto:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                  {data.subProductType}
                </Typography>
              </span>
            )}
            {data.productConfiguration && (
              <ProductConfiguration
                type={data.productType}
                data={data.productConfiguration}
              />
            )}
          </div>
        </div>
      )}
      {data.isReferencedByKinsurer && (
        <div className={styles.PersonalInfo}>
          <div className={styles.Column}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 400 }}>
              Kinsurer asociado
            </Typography>
            <Typography variant="body1">
              <UserIcon fontSize="14" className={styles.AlignIcon} />
              {data.referencedKinsurer?.fullName}
            </Typography>
            <Typography variant="body1">
              <EnvelopeOpenIcon fontSize="14" className={styles.AlignIcon} />
              {data.referencedKinsurer?.email}
            </Typography>
            <Typography variant="body1">
              <PhoneIcon fontSize="14" className={styles.AlignIcon} />
              {data.referencedKinsurer?.phoneNumber}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

PersonalInfo.propTypes = {
  data: PropTypes.object,
};

export default PersonalInfo;
