import { urlBase } from '../../../constants';
import request from '../../../utils/request';

export const putKinsurerDetails = (values, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
};

export const putResetPassword = (values, uuid) => {
  return request(`${urlBase}/jarvis/kinsurers/${uuid}/resetPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: values.password,
    }),
  });
};

export function getAddressByPostalCode(postalCode) {
  return request(
    `${urlBase}/insurance/qualitas/catalogues/postalCode/${postalCode}`,
  );
}
