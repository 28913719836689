import React from 'react';
import PropTypes from 'prop-types';

function SearchProspectsIcon({ fontSize, fontWeight, color, className }) {
  return (
    <span className={className}>
      <svg
        width={fontSize}
        height={fontSize}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.26077 15.0891C5.26077 15.0891 3.99416 16.6859 3.98213 18.0167H2.14578V23.0735H4.66927C4.66927 23.0735 6.17465 26.1077 7.35652 26.1077V29.8864H10.8494V27.5978C10.8494 27.5978 12.1928 28.2256 14.182 28.2256C15.4095 28.218 16.6269 28.0058 17.7832 27.5978V29.7276H21.3333V25.8945C21.3333 25.8945 24.7192 23.6037 24.7192 19.6668C24.7192 15.7299 21.4501 11.455 14.4466 11.0479C13.1221 10.9702 11.6408 11.0853 9.23582 12.0561C9.23582 12.0561 6.33498 8.47529 2.14062 12.0833L5.26077 15.0891Z"
          stroke={color}
          strokeWidth={fontWeight}
        />
        <path
          d="M11.3132 15.0319C11.3132 15.0319 12.3164 14.3276 14.2816 14.3276C16.2468 14.3276 17.1252 15.0319 17.1252 15.0319"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
        />
        <path
          d="M7.35132 18.5717C7.7267 18.5717 8.031 18.2704 8.031 17.8987C8.031 17.5269 7.7267 17.2256 7.35132 17.2256C6.97594 17.2256 6.67163 17.5269 6.67163 17.8987C6.67163 18.2704 6.97594 18.5717 7.35132 18.5717Z"
          fill="#4575BA"
        />
        <path
          d="M14.2295 9.46314C16.2791 9.46314 17.9406 7.81782 17.9406 5.78821C17.9406 3.7586 16.2791 2.11328 14.2295 2.11328C12.1799 2.11328 10.5184 3.7586 10.5184 5.78821C10.5184 7.81782 12.1799 9.46314 14.2295 9.46314Z"
          stroke={color}
          strokeWidth={fontWeight}
        />
        <path
          opacity="0.2"
          d="M14.2193 9.46314C16.2688 9.46314 17.9303 7.81782 17.9303 5.78821C17.9303 3.7586 16.2688 2.11328 14.2193 2.11328C12.1697 2.11328 10.5082 3.7586 10.5082 5.78821C10.5082 7.81782 12.1697 9.46314 14.2193 9.46314Z"
          fill="#4575BA"
        />
        <path
          d="M24.5623 18.1709C24.5623 18.1709 26.8378 17.82 27.6876 16.2362C28.5373 14.6525 28.7263 10.8183 26.3184 10.8653C23.9106 10.9124 24.0549 14.4711 26.4146 14.3276C28.7744 14.1841 29.8125 12.9667 29.8595 10.8647"
          stroke={color}
          strokeWidth={fontWeight}
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
}

SearchProspectsIcon.defaultProps = {
  fontSize: '32',
  fontWeight: '1.5',
  color: 'black',
};

SearchProspectsIcon.propTypes = {
  fontSize: PropTypes.oneOf(['14', '16', '24', '28', '32', '40', '48']),
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.any,
};

export default React.memo(SearchProspectsIcon);
