import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import styles from './index.module.scss';

function Placeholder() {
  return (
    <div className={styles.Placeholder} data-testid="placeholder-data-skeleton">
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
  );
}

export default React.memo(Placeholder);
